import {
  fetchObjectSummaryLoading,
  fetchObjectSummarySuccess,
  fetchObjectSummaryError,
} from "../../slices/discoveryoverview/ObjectSummarySlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getObjectSummary =
  (schema: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchObjectSummaryLoading());
    try {
      const response = await AxiosInstance.get(
        `api/pulsesdm/Discovery_overview/get/objectSummary/${schema}`
      );
      const data = response?.data;
      dispatch(fetchObjectSummarySuccess(data));
    } catch (error: any) {
      dispatch(fetchObjectSummaryError(error.message));
    }
  };
