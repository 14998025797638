import {
  Box,
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import DataTable from "./DataTable";
import "../../../styles/DiscoveryOverview.css";
import SchemaChart from "./SchemaChart";
import BarChart from "../../../Shared/Components/Charts/Bar/BarChart";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store/Store";
import { useState, useEffect } from "react";
import { getSchemaBySize } from "../../../redux/thunks/discoveryoverview/SchemaBySizeThunk";
import { getSchemaQueryCount } from "../../../redux/thunks/discoveryoverview/SchemaQueryCountThunk";
import { getObjectSummary } from "../../../redux/thunks/discoveryoverview/ObjectSummaryThunk";
import { getObjectDependency } from "../../../redux/thunks/discoveryoverview/ObjectDependencyThunk";
import { getSchemaDependency } from "../../../redux/thunks/discoveryoverview/SchemaDependencyThunk";
import { ObjectSummaryType } from "../../../redux/slices/discoveryoverview/ObjectSummarySlice";
import DecompTree from "../../../Shared/Components/Charts/DecompTree/DecompTree";
import { ObjectDependencyType } from "../../../redux/slices/discoveryoverview/ObjectDependencySlice";
import { checkEmptyObject } from "../../../Shared/Utils/HelperFunc";
import { Link } from "react-router-dom";
import { NetworkGraph } from "../../../Shared/Components/Charts/Network/NetworkGraph";
import { clearSelectedCloudData } from "../../../redux/slices/sdmslices/SelectedCloudSlice";
const options = {
  responsive: true,
  plugins: {
    legend: {
      display: false,
    },
  },
  scales: {
    y: {
      ticks: {
        stepSize: 5,
        color: "#7500C0",
      },
      grid: {
        display: true,
      },
      min: 0,
      beginAtZero: false,
    },
    x: {
      ticks: {
        color: "#B3B2B5",
      },
      grid: {
        display: false,
      },
    },
  },
};

const style = {
  width: "50%",
  "& .MuiInputLabel-root": { color: "#4F4B53" }, //styles the label
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: "#B3B2B5" },
  },
  "& .MuiOutlinedInput-root:hover": {
    "& > fieldset": {
      borderColor: "#A100FF",
    },
  },
};

export interface SchemaChartType {
  title: string;
  legendTypeOne: string;
  legendTypeTwo: string;
  chartData: number[];
}

function DiscoveryOverview() {
  const [objectDependencyOpen, setObjectdependencyOpen] = useState(false);
  const [schemaDependencyOpen, setschemadependencyOpen] = useState(false);
  const [selectedDataSource, setSelectedDataSource] = useState("");
  const [selectedSchema, setSelectedSchema] = useState("TPCH");
  const [labelsdata, setLabelsData] = useState<string[] | []>([]);
  const [dataSetBarData, setDataSetBarData] = useState<number[] | []>([]);
  const [objectSummaryChartData, setObjectSummaryChartData] = useState<
    number[] | []
  >([]);
  const [chartObjDependencyData, setChartObjDependencyData] =
    useState<ObjectDependencyType>();
  const [usedQueryData, setUsedQueryData] = useState<number[] | []>([]);

  const DelimiterOptions = labelsdata;
  const schemasByUsage = {
    title: `Total Number of schemas by usage (${selectedDataSource})`,
    legendTypeOne: "Used",
    legendTypeTwo: "Unused",
    // chartData: [10, 20],
    chartData: usedQueryData,
  };

  const schemasDependencies = {
    title: `Object dependencies for ${selectedSchema} schema (#Objects)`,
    legendTypeOne: "Index",
    legendTypeTwo: "Table",
    chartData: objectSummaryChartData,
  };

  const barData = {
    labels: labelsdata,
    datasets: [
      {
        datalabels: {
          labels: {
            title: null,
          },
        },
        barThickness: 25,
        borderRadius: 3,
        data: dataSetBarData,
        backgroundColor: ["rgba(159, 80, 209, 1)"],
      },
    ],
  };

  const { data: selectedData } = useSelector(
    (state: RootState) => state.Common.SelectedDataSourceReducer
  );
  const selectedDataName =
    selectedData.length !== 0 ? selectedData[0].name : "";
  useEffect(() => {
    setSelectedDataSource(selectedDataName);
  }, [selectedData]);

  const dispatch: any = useDispatch();
  const {
    data: schemaBySizeData,
    loading,
    error,
  } = useSelector(
    (state: RootState) => state.DiscoveryOverview.SchemaBySizeReducer
  );

  const objectSummaryReducerData = useSelector(
    (state: RootState) => state.DiscoveryOverview.ObjectSummarySlice.data
  );

  const objectDependencyReducerData = useSelector(
    (state: RootState) => state.DiscoveryOverview.ObjectDependencySlice.data
  );
  const schemaDependencyReducerData: any = useSelector(
    (state: RootState) => state.DiscoveryOverview.SchemaDependencySlice.data
  );

  const schemaCountData = useSelector(
    (state: RootState) => state.DiscoveryOverview.SchemaQueryCountSlice.data
  );

  useEffect(() => {
    dispatch(getSchemaBySize());
    dispatch(getSchemaQueryCount());
  }, [dispatch]);

  useEffect(() => {
    if (schemaBySizeData && schemaBySizeData.length > 0) {
      const keys = Object.keys(schemaBySizeData[0]);
      const labelData = schemaBySizeData.map((item: any) => item[keys[0]]);
      const tempBarData = schemaBySizeData.map((item: any) => item[keys[1]]);
      setLabelsData(labelData);
      setDataSetBarData(tempBarData);
    }
  }, [schemaBySizeData]);

  useEffect(() => {
    let usedQueries = 0;
    let unusedQueries = 0;
    if (schemaCountData) {
      schemaCountData.forEach((item) => {
        if (item.total_queries > 3000) usedQueries += 1;
        else unusedQueries += 1;
      });
    }
    setUsedQueryData([usedQueries, unusedQueries]);
  }, [schemaCountData]);

  useEffect(() => {
    if (selectedSchema && selectedSchema !== "") {
      dispatch(getObjectSummary(selectedSchema));
      dispatch(getObjectDependency(selectedSchema));
      dispatch(getSchemaDependency(selectedSchema));
    }
  }, [dispatch, selectedSchema]);

  useEffect(() => {
    const indexCount = objectSummaryReducerData
      ?.filter((item: ObjectSummaryType) => item?.OBJECT_TYPE === "INDEX")
      ?.map((item) => item?.object_count)[0];

    const tableCount = objectSummaryReducerData
      ?.filter((item: ObjectSummaryType) => item?.OBJECT_TYPE === "TABLE")
      ?.map((item) => item?.object_count)[0];

    setObjectSummaryChartData([tableCount, indexCount]);
  }, [objectSummaryReducerData]);

  const handleDependencyButton = (dependency: string) => {
    if (dependency === "schema") {
      setschemadependencyOpen(true);
    }
    if (dependency === "object") {
      setObjectdependencyOpen(true);
    }
  };

  useEffect(() => {
    let temp;
    const isObjectDependencyEmpty = checkEmptyObject(
      objectDependencyReducerData
    );
    if (!isObjectDependencyEmpty) {
      for (const key in objectDependencyReducerData) {
        temp = objectDependencyReducerData[key];
      }
      setChartObjDependencyData(temp);
    }
  }, [selectedSchema, objectDependencyReducerData]);

  const handleDependencyClose = () => {
    setObjectdependencyOpen(false);
    setschemadependencyOpen(false);
  };

  const objectDependency = {
    nodes: schemaDependencyReducerData.Nodes,
    edges: schemaDependencyReducerData.edges,
  };
  const handleClick = () => {
    dispatch(clearSelectedCloudData());
  };
  return (
    <Box className="discoveryOverviewContainer">
      <div className="gridContainer">
        <div className="gridStyle gridItemOne">
          <Box>
            <SchemaChart data={schemasByUsage} />
          </Box>
          <DataTable />
        </div>
        <div className="gridStyle gridItemTwo">
          <div className="gridStyle">
            <Box>
              <label>Select a scheme to visualize</label>
              <TextField
                InputLabelProps={{ shrink: false }}
                label=""
                select
                sx={style}
                value={selectedSchema}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  setSelectedSchema(e.target.value)
                }
              >
                {DelimiterOptions.map((option) => {
                  return (
                    <MenuItem key={option} value={option}>
                      {option}
                    </MenuItem>
                  );
                })}
              </TextField>
            </Box>
            <Box>
              <Typography className="discoveryOverviewBarChartHeaderText">
                Size distribution for used schemas (in GBB)
              </Typography>
              <Box className="barChartContainer">
                <BarChart chartData={barData} options={options} />
              </Box>
              <Box className="discoveryOverviewBarChartBtn">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    handleDependencyButton("schema");
                  }}
                >
                  View schema dependency
                </Button>
              </Box>
            </Box>
          </div>
          <div className="gridStyle gridRightTwo">
            <Box>
              <SchemaChart data={schemasDependencies} />
              <Box className="discoveryOverviewViewAnalysisBtn">
                <Button
                  variant="outlined"
                  size="small"
                  onClick={() => {
                    handleDependencyButton("object");
                  }}
                >
                  View analysis object dependency
                </Button>
              </Box>
            </Box>
          </div>
        </div>
      </div>
      <Button
        variant="text"
        size="medium"
        component={Link}
        to={"/home/migrate/smartdatawarehousemover/migrationhistory"}
      >
        <img src="/assets/images/icon_visibility.svg" alt="" />
        View migration history
      </Button>
      <Button
        className="discoveryOverviewStartMigrationButton"
        size="small"
        variant="contained"
        component={Link}
        to={"/home/migrate/smartdatawarehousemover/target"}
        onClick={handleClick}
      >
        Start Migration &nbsp;{" "}
        <img src="/assets/images/right-arrow.png" alt="" />
      </Button>
      {/* object dependency */}
      <Dialog open={objectDependencyOpen} onClose={handleDependencyClose}>
        <DialogTitle id="responsive-dialog-title">
          {"Object Dependency Analysis Results"}
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: "hidden",
          }}
        >
          {chartObjDependencyData && (
            <DecompTree treedata={chartObjDependencyData} />
          )}
          <Button
            sx={{ float: "right" }}
            variant="contained"
            size="large"
            onClick={handleDependencyClose}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
      {/* schema dependency */}
      <Dialog open={schemaDependencyOpen} onClose={handleDependencyClose}>
        <DialogTitle id="responsive-dialog-title">
          {"Schema Dependency Analysis Results"}
        </DialogTitle>
        <DialogContent
          sx={{
            overflow: "hidden",
          }}
        >
          {chartObjDependencyData && (
            // <DecompTree treedata={chartObjDependencyData} />
            <NetworkGraph data={objectDependency} width={800} height={500} />
          )}
          <Button
            sx={{ float: "right" }}
            variant="contained"
            size="large"
            onClick={handleDependencyClose}
          >
            Close
          </Button>
        </DialogContent>
      </Dialog>
    </Box>
  );
}

export default DiscoveryOverview;
