import {
  fetchDbAllDetailsLoading,
  fetchDbAllDetailsSuccess,
  fetchDbAllDetailsError,
  deleteDbDetailLoading,
  deleteDbDetailSuccess,
  deleteDbDetailError,
  addConfigurationLoading,
  addConfigurationSuccess,
  addConfigurationError,
  editConfigurationLoading,
  editConfigurationSuccess,
  editConfigurationError,
} from "../slices/DbAllDetailsSlice";
import { AppThunkDispatch } from "../store/Store";
import AxiosInstance from "../../core/AxiosInterceptor";
import Decrypt from "../../core/Decrypt";

interface IEncryptedDbAllDetails {
  [key: number | string]: string;
}

export const getDbAllDetails =
  (dbId: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchDbAllDetailsLoading());
    try {
      const response = await AxiosInstance.get(
        `api/pulsesdm/Configuration/all_secret/${dbId}`
      );
      const data = response?.data;
      const decyptedValues = data?.map((item: IEncryptedDbAllDetails) => {
        for (const key in item) {
          item[key] = Decrypt(item[key]);
        }
        return item;
      });
      dispatch(fetchDbAllDetailsSuccess(decyptedValues));
    } catch (error: any) {
      dispatch(fetchDbAllDetailsError(error.message));
    }
  };

export const deleteDbDetail =
  (dbId: string, secretId: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(deleteDbDetailLoading());
    try {
      const response = await AxiosInstance.delete(
        `api/pulsesdm/Configuration/remove/${dbId}/${secretId}`
      );
      if (response.status === 200) dispatch(deleteDbDetailSuccess(secretId));
    } catch (error: any) {
      dispatch(deleteDbDetailError(error.message));
    }
  };

export const addDbConfiguration =
  (dbId: string, bodyPayload: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(addConfigurationLoading());
    try {
      const response = await AxiosInstance.post(
        `api/pulsesdm/Configuration/add/${dbId}`,
        JSON.stringify({
          configure_secret: bodyPayload,
        })
      );
      if (response.status === 200) {
        dispatch(addConfigurationSuccess(dbId));
        dispatch(getDbAllDetails(dbId));
      }
    } catch (error: any) {
      dispatch(addConfigurationError(error.message));
    }
  };

export const editDbConfiguration =
  (dbId: string, secret_Id: string, bodyPayload: string) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch(editConfigurationLoading());
    try {
      const response = await AxiosInstance.put(
        `api/pulsesdm/Configuration/update/${dbId}/${secret_Id}`,
        JSON.stringify({
          configure_secret: bodyPayload,
        })
      );
      if (response.status === 200) {
        dispatch(editConfigurationSuccess(dbId));
        dispatch(getDbAllDetails(dbId));
      }
    } catch (error: any) {
      dispatch(editConfigurationError(error.message));
    }
  };
