import {
  Box,
  LinearProgress,
  linearProgressClasses,
  Link,
  styled,
  Typography,
} from "@mui/material";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import React, { useEffect, useState } from "react";
import ValidationTable from "./ValidationTable";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store/Store";
import { getValidationTableList } from "../../redux/thunks/sdv/ValidationTableThunk";
import AxiosInstance from "../../core/AxiosInterceptor";

import "../../styles/Validation.css";
import "../../styles/MetaDataExecution.css";

const Validation = () => {
  const [time, setTime] = React.useState(0);
  const [progressContent, setprogressContent] = useState([
    "Pending",
    "Pending",
  ]);
  const [progress, setProgress] = useState(true);
  const { data: validationData } = useSelector(
    (state: RootState) => state.Sdv.ValidationTableSliceReducer
  );

  const dispatch: any = useDispatch();

  const titleData: string[] = [
    "Compute size/object combination",
    "Job status ",
    "Validation status",
    "Total time taken",
  ];

  const downloadReport = () => {
    try {
      AxiosInstance({
        url: "api/sdv/s3/download/validation_report",
        method: "POST",
        responseType: "blob",
      }).then((response: { data: BlobPart }) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "SDV_Report.zip");
        document.body.appendChild(link);
        link.click();
      });
    } catch (error: any) {}
  };

  let clearTime: any;
  clearTime = progress && setTimeout(() => setTime(time + 10), 1000);
  time === 100 && clearTimeout(clearTime);

  useEffect(() => {
    if (time >= 0) {
      setprogressContent(["0% complete"]);
    }
    if (time >= 25) {
      setprogressContent(["25% complete"]);
    }
    if (time >= 50) {
      setprogressContent(["50% complete"]);
    }
    if (time >= 75) {
      setprogressContent(["75% complete"]);
    }
    if (time >= 100) {
      setprogressContent(["done"]);
    }
  }, [time]);

  useEffect(() => {
    dispatch(getValidationTableList());
  }, [dispatch]);

  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#18AA18" : "#308fe8",
    },
  }));

  return (
    <>
      <div onLoad={() => setProgress(true)}>
        {progress && (
          <>
            <Box className="validationProgressTittle">
              <span className="metaDataExecLabelStyle metadataExecutionProgressBarLabel1">
                Validation progress
              </span>
            </Box>
            <BorderLinearProgress
              className="metadataExecutionLinearProgress"
              variant="determinate"
              value={time}
            />
          </>
        )}

        {progress && (
          <Box className="metaDataExecutionProgressDescContainer">
            <span className="metaDataExecutionProgressValidatingIconStyle metaDataExecutionProgressValidatingIcon1">
              {progressContent[0] === "0% complete" ? (
                <AutorenewIcon />
              ) : progressContent[0] === "25% complete" ? (
                <AutorenewIcon />
              ) : progressContent[0] === "50% complete" ? (
                <AutorenewIcon />
              ) : progressContent[0] === "75% complete" ? (
                <AutorenewIcon />
              ) : (
                <img src="/assets/images/icon_done.svg" alt="doneicon" />
              )}
              <p className="metaDataExecutionProgressValidatingLabelStyle">
                {progressContent[0]}
              </p>
            </span>
          </Box>
        )}
      </div>

      {time === 100 && (
        <>
          <div className="validationTableContainer">
            <ValidationTable titleData={titleData} rowData={validationData} />
          </div>
          <Typography className="ValidationContent">
            Validation report was generated
          </Typography>
          <Link
            className="
      ValidationDownload"
            onClick={downloadReport}
          >
            <img
              className="downloadImgBtn"
              src="/assets/images/icon_download.svg"
              alt="download file"
              title="download file"
            />
            Download report
          </Link>
        </>
      )}
    </>
  );
};

export default Validation;
