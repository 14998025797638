import {
  fetchSchemaListLoading,
  fetchSchemaListSuccess,
  fetchSchemaListError,
} from "../../slices/sdmslices/MigrationSchemaListSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getMigrationSchemaList =
  (appName?: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchSchemaListLoading());
    try {
      if (appName) {
        const response = await AxiosInstance.get(
          `api/pulsesdm/Migration/list_schema?wave_name=${appName}`
        );
        const data = response?.data;
        dispatch(fetchSchemaListSuccess(data));
      } else {
        const response = await AxiosInstance.get(
          `api/pulsesdm/Migration/list_schema`
        );
        const data = response?.data;
        dispatch(fetchSchemaListSuccess(data));
      }
    } catch (error: any) {
      dispatch(fetchSchemaListError(error.message));
    }
  };
