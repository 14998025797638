import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import { Box, Card, Checkbox, Link } from "@mui/material";
import { useState } from "react";
import Popups from "../AppPopups";
import "../../../styles/AppExecutionList.css";

const boxContainer = {
  display: "flex",
  flexWrap: " wrap",
  fontSize: "1.2rem",
  fontWeight: "600",
  borderRadius: "0.3rem",
  background: "#ffffff",
  margin: "0rem",
  marginLeft: "1.5rem",
  marginBottom: "2rem",
  padding: "1.5rem",
  boxShadow: "0px 4px 8px rgba(0, 0, 0, 0.161)",
};
interface stepTypes {
  title: string;
  description: string[];
}
export default function AppExecutionList(props: {
  steps: stepTypes[];
  toggleExecuteScript: Function;
}) {
  const steps = props.steps;
  const [open, setOpen] = useState(false);
  const [checked, setChecked] = useState([false, false, false, false]);
  const [indexView, setIndexView] = useState("");
  const ModalCloseFunc = (modalClose: any) => {
    setOpen(modalClose);
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newChecked = checked;
    newChecked[Number(event.target.name)] = event.target.checked;
    setChecked([...newChecked]);

    updateCheckBoxStatus();
  };
  const updateCheckBoxStatus = () => {
    if (checked.length === 4 && checked.includes(true)) {
      props.toggleExecuteScript(true);
    } else {
      props.toggleExecuteScript(false);
    }
  };

  const data: any = [
    {
      heading: [
        "Metastore Analysis",
        "Code Analysis",
        "Log Analysis",
        "File Storage Analysis",
      ],
      desc: "The following are settings related to this parameter:",
      dataLink1: `SELECT OWNER, OBJECT_NAME, SUBOBJECT_NAME, OBJECT_TYPE, OBJECT_ID, CREATED, LAST_DDL_TIME, STATUS FROM OBJECTS ORDER BY OWNER, OBJECT_NAME;`,
      dataLink2: `SELECT OWNER,TABLE_NAME, COLUMN_NAME, DATA_TYPE, DATA_LENGTH, DATA_PRECISION, NULLABLE, NUM_NULLS FROM TAB_COLUMNS ORDER BY OWNER, TABLE_NAME;`,
      datalink3: `SELECT SJ.OWNER ,SJ.JOB_NAME, SJ.RUN_COUNT, MIN(SJD.CPU_USED) AS MIN_CPU_USED, MAX(SJD.CPU_USED) AS MAX_CPU_USED, AVG(SJD.CPU_USED) AS AVG_CPU_USED,MIN(SJD.RUN_DURATION) AS MIN_RUN_DURATION ,MAX(SJD.RUN_DURATION) AS MAX_RUN_DURATION ,AVG(SJD.RUN_DURATION) AS AVG_RUN_DURATION FROM SCHEDULER_JOBS SJ INNER JOIN SCHEDULER_JOB_RUN_DETAILS SJD ON SJ.JOB_NAME = SJD.JOB_NAME WHERE SJ.system='FALSE' and SJD.ACTUAL_START_DATE >= sysdate-30 GROUP BY SJ.OWNER,SJ.JOB_NAME,SJ.RUN_COUNT;`,
      btns: [
        {
          btnTitle: "ok",
          btnType: "contained",
          btnfunc: () => {
            setOpen(false);
          },
        },
      ],
    },
  ];

  const handleClick = (index: any) => {
    setIndexView(index);
    setOpen(true);
  };
  return (
    <Box
      sx={{
        paddingTop: "1.5rem",
        display: "grid",
        // gap: "5px",
        gridTemplateColumns: {
          sm: "repeat(1, auto)",
          xs: "repeat(1, auto)",
          md: "repeat(2, auto)",
        },
      }}
    >
      {steps.map((step: any, index: any) => (
        <Card className="AppExecCard" key={index}>
          <List>
            <p className="AppExecTitleContainer">
              <Checkbox
                className="AppExecCheckbox"
                checked={checked[index]}
                name={index.toString()}
                onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                  handleChange(e)
                }
              />
              {step.title}
            </p>

            {step.description.map((descriptionStep: any, index: any) => (
              <div key={index}>
                <p className="AppExecDescStep">{descriptionStep}</p>
                {index !== step.description.length - 1 ? (
                  <Divider className="AppExecDivider" />
                ) : null}
              </div>
            ))}
            <Link
              component="button"
              variant="inherit"
              underline="none"
              onClick={() => {
                handleClick(props.steps[index].title);
              }}
              className="AppExecLink"
            >
              <img
                src="/assets/images/icon_visibility.svg"
                alt="eyeicon"
                title="eyeicon"
                className="AppExecImage"
              />
              View sample script
            </Link>
          </List>
        </Card>
      ))}

      <Popups
        fullData={data}
        index={indexView}
        btnOpen={open}
        btnClose={!open}
        modalClose={ModalCloseFunc}
      />
    </Box>
  );
}
