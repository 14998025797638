import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CodeDiscoveryDbListState {
  data: string[];
  loading: boolean;
  error: string | null;
}

const initialState: CodeDiscoveryDbListState = {
  data: [],
  loading: false,
  error: null,
};

const SourceCodeListSlice = createSlice({
  name: "sourcecodelistslice",
  initialState,
  reducers: {
    fetchSourceCodeListLoading: (state) => {
      state.loading = true;
    },
    fetchSourceCodeListSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchSourceCodeListFailure: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
  },
});

export const {
  fetchSourceCodeListLoading,
  fetchSourceCodeListSuccess,
  fetchSourceCodeListFailure,
} = SourceCodeListSlice.actions;

export default SourceCodeListSlice.reducer;
