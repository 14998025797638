import { Box, Button, Typography } from "@mui/material";
import SdvTarget from "./SdvTarget";
import SdvDataConfig from "./SdvDataConfig";
import { Link as RouteLink } from "react-router-dom";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";
import AzureModal from "../../Shared/Components/CloudFormModals/AzureModal";
import AwsModal from "../../Shared/Components/CloudFormModals/AwsModal";
import GcpModal from "../../Shared/Components/CloudFormModals/GcpModal";
import { addSdvConfig } from "../../redux/thunks/sdv/ConfigurationDetailsThunk";
import { clearConfigurationDetails } from "../../redux/slices/sdvslices/ConfigurationDetailsSlice";
import "../../styles/SdvTarget.css";
const Target = () => {
  const [sourceCodeName, setSourceCodeName] = useState("Azure Blob");
  const [isSourceCodeModalOpen, setIsSourceCodeModalOpen] = useState(false);
  const [modalType, setModalType] = useState("");
  const [isEditable, setIsEditable] = useState(false);
  const [configSelection, setConfigSelection] = useState("");
  const handleSourceCodeModalClose = () => {
    setIsSourceCodeModalOpen(false);
  };
  const dispatch: any = useDispatch();
  const { data: selectedTargetStorage } = useSelector(
    (state: RootState) => state.Sdv.SelectedTargetSystemReducer
  );
  useEffect(() => {
    if (selectedTargetStorage && selectedTargetStorage.length > 0) {
      setSourceCodeName(selectedTargetStorage[0].name);
    }
  }, [selectedTargetStorage]);

  const selectedCloudPlateformID = useSelector(
    (state: RootState) => state.Sdv.SelectedTargetCloudPlatformReducer
  );
  const handleFormSaveClick = (payload: string) => {
    if (selectedTargetStorage && selectedTargetStorage.length > 0)
      dispatch(addSdvConfig(selectedTargetStorage[0].id, payload));
  };

  useEffect(() => {
    dispatch(clearConfigurationDetails());
    selectedCloudPlateformID?.data[0]?.name !== undefined &&
      setModalType(selectedCloudPlateformID?.data[0]?.name.toLocaleLowerCase());
  }, [selectedCloudPlateformID]);
  const setConfigSelectionData = (config: string) => {
    setConfigSelection(config);
  };
  return (
    <Box className="sdvTargetContainer">
      <Typography className="TargetContainer" variant="subtitle2">
        Target
      </Typography>
      <Typography className="TargetTextDesc" variant="subtitle2">
        Cloud platform selected
      </Typography>
      <Box>
        <SdvTarget />
      </Box>
      {selectedTargetStorage.length !== 0 && (
        <Typography className="TargetTextDesc" variant="subtitle2">
          Select the configuration for the target system
        </Typography>
      )}
      {selectedTargetStorage.length !== 0 && (
        <Box>
          <SdvDataConfig
            typeOfModal={modalType}
            targetDbId={selectedTargetStorage[0]?.id}
            pathName="/home/validate/smartdatavalidator/configuration"
            target="target"
            selectedConfig={setConfigSelectionData}
          />
          <Button
            variant="text"
            size="small"
            onClick={() => {
              setIsSourceCodeModalOpen(true);
              setIsEditable(false);
            }}
            disabled={selectedTargetStorage.length === 0}
          >
            <Typography sx={{ fontSize: "1.2rem" }}>
              + Add new configuration
            </Typography>
          </Button>
        </Box>
      )}
      <div className="sdvTargetBtnContainer">
        <Button
          component={RouteLink}
          to={"/home/validate/smartdatavalidator/configuration"}
          variant="contained"
          size="medium"
          disabled={!configSelection}
        >
          Save and Continue
        </Button>
      </div>
      {selectedTargetStorage.length > 0 &&
        selectedCloudPlateformID?.data[0]?.id === "cp_13" && (
          <AzureModal
            open={isSourceCodeModalOpen}
            onClose={handleSourceCodeModalClose}
            title="Target Configuration"
            isEdit={isEditable}
            handleSave={handleFormSaveClick}
          />
        )}
      {selectedTargetStorage.length > 0 &&
        selectedCloudPlateformID?.data[0]?.id === "cp_11" && (
          <AwsModal
            open={isSourceCodeModalOpen}
            onClose={handleSourceCodeModalClose}
            title="Target Configuration"
            isEdit={isEditable}
            handleSave={handleFormSaveClick}
          />
        )}
      {selectedTargetStorage.length > 0 &&
        selectedCloudPlateformID?.data[0]?.id === "cp_12" && (
          <GcpModal
            open={isSourceCodeModalOpen}
            onClose={handleSourceCodeModalClose}
            title="Target Configuration"
            isEdit={isEditable}
            handleSave={handleFormSaveClick}
          />
        )}
    </Box>
  );
};
export default Target;
