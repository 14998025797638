import React from "react";
import { Box, Typography } from "@mui/material";
import "../styles/PageNotFound.css";

/**
 * Implementation of Page Not Found
 * @returns 404 page of the app
 */
export const PageNotFound = () => {
  return (
    <Box className="pageNotFoundContainer">
      <Typography variant="h4" color="primary">
        Page Not Found !!
      </Typography>
      <Typography variant="h5">
        The page you are looking for does not exist or may have been moved or
        deleted.
      </Typography>
    </Box>
  );
};
