import * as React from "react";
import { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  linearProgressClasses,
  Link,
  List,
  Stack,
  styled,
} from "@mui/material";
import { Link as RouteLink } from "react-router-dom";
import AutorenewIcon from "@mui/icons-material/Autorenew";
import AccessTimeIcon from "@mui/icons-material/AccessTime";
import AppExecutionList from "../../Shared/Components/ExecutionList/AppExecutionList";
import Popups from "../../Shared/Components/AppPopups";
import AxiosInstance from "../../core/AxiosInterceptor";
import FormData from "form-data";
import "../../styles/MetaDataExecution.css";

const icon = {
  paddingBottom: "0.5rem",
  paddingRight: "0.2rem",
};

const MetaDataExecution = () => {
  const [open, setOpen] = useState(false);
  const [toggleExecute, setToggleExecute] = useState(false);
  const [showDependencies, setShowDependencies] = useState(false);
  const [uploadId, setuploadId] = useState();
  const [success, setsuccess] = useState({ id: 0, fileName: "" });
  const [time, setTime] = React.useState(0);
  const [progressContent, setprogressContent] = useState([
    "pending",
    "pending",
    "pending",
  ]);
  const [progress, setProgress] = useState(false);

  let clearTime: any;
  clearTime = progress && setTimeout(() => setTime(time + 10), 1000);
  time === 100 && clearTimeout(clearTime);

  const ModalCloseFunc = (modalClose: any) => {
    setOpen(modalClose);
  };
  const successData = [
    {
      title: "Successful execution",
      src: "/assets/images/icon_check.svg",
      desc: "Metadata extraction completed successfully. Choose to View Metadata extracts or Continue to next step.",
      btns: [
        {
          btnTitle: "View Metadata Extracts",
          btnType: "outlined",
          btnfunc: () => {
            setOpen(false);
          },
          btnSvg: "/assets/images/icon_visibility.svg",
        },
        {
          btnTitle: "Continue",
          btnType: "contained",
          btnfunc: () => {
            setOpen(false);
            setShowDependencies(true);
          },
        },
      ],
    },
  ];
  const changeEvent = (value: boolean) => {
    setToggleExecute(value);
  };
  const handleClick = () => {
    setOpen(true);
  };

  const steps = [
    {
      title: "Metastore analysis ",
      description: [
        "Inventory and volumetrics",
        "User & privilege details",
        "Object dependency details",
        "Attribute & data type info",
      ],
    },
    {
      title: "Code analysis",
      description: [
        "Identify consumption patterns",
        "Application & object dependency",
      ],
    },
    {
      title: "Log analysis ",
      description: [
        "Table usage & performance details from history",
        "Table classification (Active / Inactive)",
      ],
    },
    {
      title: "File storage analysis ",
      description: [
        "Table usage & performance details from history",
        "Size & ownership details",
      ],
    },
  ];

  const data = [
    {
      tittle: "Database to business unit mapping",
      descriptionLink: [
        {
          link: "Download template",
          iconSrc: "/assets/images/icon_download.svg",
        },
        {
          link: "Upload file",
          id: 0,
          iconSrc: "/assets/images/icon_upload.svg",
        },
      ],
    },
    {
      tittle: "User to business unit mapping ",
      descriptionLink: [
        {
          link: "Download template",
          iconSrc: "/assets/images/icon_download.svg",
        },
        {
          link: "Upload file",
          id: 1,
          iconSrc: "/assets/images/icon_upload.svg",
        },
      ],
    },
  ];
  const downloadTemplate = () => {
    try {
      AxiosInstance({
        url: "api/pulsesdm/S3/blob/data-migration-assets/DB_BU Mapping_Template.xlsx",
        method: "POST",
        responseType: "blob",
        params: { location: "discover/pulse-for-data/templates/" },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "DB_BU Mapping_Template.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    } catch (error: any) {}
  };
  const uploadImage = async (files: any, id: any) => {
    setuploadId(id);
    var formData = new FormData();
    formData.append("Filename", files);
    try {
      const response = await AxiosInstance.post(
        `api/pulsesdm/S3/upload/data-migration-assets/${files.name}?location=discover/pulse-for-data/functional-dependencies/`,
        formData
      );
      setsuccess({ id: response.status, fileName: files.name });
    } catch (error: any) {}
  };
  const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 10,
    borderRadius: 5,
    [`&.${linearProgressClasses.colorPrimary}`]: {
      backgroundColor:
        theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
    },
    [`& .${linearProgressClasses.bar}`]: {
      borderRadius: 5,
      backgroundColor: theme.palette.mode === "light" ? "#18AA18" : "#308fe8",
    },
  }));

  useEffect(() => {
    if (time >= 0) {
      setprogressContent(["validating", "pending", "pending"]);
    }
    if (time >= 40) {
      setprogressContent(["done", "validating", "pending"]);
    }
    if (time >= 70) {
      setprogressContent(["done", "done", "validating"]);
    }
    if (time >= 100) {
      setprogressContent(["done", "done", "done"]);
    }
  }, [time]);

  return (
    <>
      <AppExecutionList steps={steps} toggleExecuteScript={changeEvent} />

      <Button
        className="metaDataExecutionExecuteScriptsButton"
        size="large"
        variant="contained"
        onClick={handleClick}
        disabled={!toggleExecute}
      >
        Execute scripts
      </Button>
      {showDependencies && (
        <Box>
          <Box>
            <div className="metaDataExecutionUploadData">
              Upload of functional dependencies
            </div>
            <div className="metaDataExecutionUploadDataDesc">
              For each Database and User present in the environment provide the
              corresponding BU / department / Application name.
              <br /> You may refer the sample template for the same.
            </div>
          </Box>
          <Box
            sx={{
              display: "grid",
              gap: "10px",
              gridTemplateColumns: {
                sm: "repeat(1, auto)",
                xs: "repeat(1, auto)",
                md: "repeat(2, 31%)",
              },
              marginTop: "1.5rem",
            }}
          >
            {data.map((listData: any, index: any) => (
              <Box
                key={index}
                sx={{
                  maxWidth: {
                    sm: "100%",
                    xs: "100%",
                    md: "432px",
                  },
                  background: "white",
                  border: "1px solid #837F89",
                  marginTop: "1.5rem",
                  marginLeft: "1rem",
                }}
              >
                <List>
                  <div>
                    <div className="metadataExecutionListDataTitle">
                      {listData.tittle}
                    </div>
                    <div className="metadataExecutionListDataLinkContainer">
                      {listData.descriptionLink.map(
                        (descriptionData: any, index: any) => (
                          <div key={index}>
                            {descriptionData.link !== "Upload file" ? (
                              <Link
                                className="metadataExecutionListDataDownloadLink"
                                onClick={downloadTemplate}
                                key={index}
                              >
                                <img
                                  className="metadataExecutionListDataDownloadIcon"
                                  src={`${descriptionData.iconSrc}`}
                                  alt=""
                                />
                                <Box className="metadataExecutionListDataLinkStyle">
                                  {descriptionData.link}
                                </Box>
                              </Link>
                            ) : (
                              <Stack direction="row" alignItems="center">
                                <Link
                                  className="metadataExecutionListDataLinkStyle"
                                  component="label"
                                >
                                  <IconButton
                                    className="metadataExecutionListDataImage"
                                    sx={icon}
                                    component="label"
                                  >
                                    <input
                                      hidden
                                      accept="image/*"
                                      multiple
                                      onChange={(event: any) =>
                                        uploadImage(
                                          event.target.files[0],
                                          descriptionData.id
                                        )
                                      }
                                      type="file"
                                    />
                                    <img
                                      src={`${descriptionData.iconSrc}`}
                                      alt=""
                                    />
                                  </IconButton>
                                  <div>{descriptionData.link}</div>
                                </Link>
                              </Stack>
                            )}
                          </div>
                        )
                      )}
                    </div>
                  </div>
                </List>
                {success.id === 200 && uploadId === index ? (
                  <div className="metadataExecutionProgressSucess">
                    <img
                      src="/assets/images/icon_done.svg"
                      alt="doneicon"
                      title="doneicon"
                      style={{ verticalAlign: "middle" }}
                    />
                    {success.fileName} uploaded.
                  </div>
                ) : (
                  ""
                )}
              </Box>
            ))}
          </Box>
          <Button
            className="metadataExecutionContinueBtn"
            size="large"
            variant="contained"
            onClick={() => setProgress(true)}
          >
            Continue
          </Button>
        </Box>
      )}
      <Popups
        fullData={successData}
        btnOpen={open}
        btnClose={!open}
        modalClose={ModalCloseFunc}
      />
      <br />
      {progress && (
        <Box className="metadataExecutionProgressBarContainer">
          <span className="metaDataExecLabelStyle metadataExecutionProgressBarLabel1">
            Metadata validation & cleansing
          </span>
          <span className="metaDataExecLabelStyle metadataExecutionProgressBarLabel2">
            Metadata consolidation
          </span>
          <span className="metaDataExecLabelStyle">Dashboard generation</span>
        </Box>
      )}
      {progress && (
        <BorderLinearProgress
          className="metadataExecutionLinearProgress"
          variant="determinate"
          value={time}
        />
      )}
      {progress && (
        <Box className="metaDataExecutionProgressDescContainer">
          <span className="metaDataExecutionProgressValidatingIconStyle metaDataExecutionProgressValidatingIcon1">
            {progressContent[0] === "validating" ? (
              <AutorenewIcon />
            ) : (
              <img src="/assets/images/icon_done.svg" alt="doneicon" />
            )}
            <p className="metaDataExecutionProgressValidatingLabelStyle">
              {progressContent[0]}
            </p>
          </span>
          <span className="metaDataExecutionProgressValidatingIconStyle metaDataExecutionProgressValidatingIcon2">
            {progressContent[1] === "pending" ? (
              <AccessTimeIcon color="disabled" />
            ) : progressContent[1] === "validating" ? (
              <AutorenewIcon />
            ) : (
              <img src="/assets/images/icon_done.svg" alt="doneicon" />
            )}
            <p className="metaDataExecutionProgressValidatingLabelStyle">
              {progressContent[1]}
            </p>
          </span>
          <span className="metaDataExecutionProgressValidatingIconStyle">
            {progressContent[2] === "pending" ? (
              <AccessTimeIcon color="disabled" />
            ) : progressContent[2] === "validating" ? (
              <AutorenewIcon />
            ) : (
              <img src="/assets/images/icon_done.svg" alt="doneicon" />
            )}
            <p className="metaDataExecutionProgressValidatingLabelStyle">
              {progressContent[2]}
            </p>
          </span>
        </Box>
      )}
      {progress && (
        <Box className="metaDataExecutionViewDashboardBtn">
          {time === 100 ? (
            <Button
              size="large"
              variant="contained"
              component={RouteLink}
              to={"/home/discover/datadiscovery/discoverysummary"}
            >
              View discovery
              <img
                className="metaDataExecutionProgressImage"
                src="/assets/images/right-arrow.png"
                alt="Add"
              />
            </Button>
          ) : (
            <Button size="large" variant="contained" disabled>
              View discovery
              <img
                className="metaDataExecutionProgressImage"
                src="/assets/images/right-arrow.png"
                alt="Add"
              />
            </Button>
          )}
        </Box>
      )}
    </>
  );
};
export default MetaDataExecution;
