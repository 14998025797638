import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface ValidationTable {
  compute_size: string;
  job_status: string;
  validation_status: string;
  time_taken: string;
}

export interface ValidationTableListState {
  data: ValidationTable[];
  loading: boolean;
  error: string | null;
}

const initialState: ValidationTableListState = {
  data: [],
  loading: false,
  error: null,
};

const ValidationTableSlice = createSlice({
  name: "ValidationTablelistslice",
  initialState,
  reducers: {
    fetchValidationTableListLoading: (state) => {
      state.loading = true;
    },
    fetchValidationTableListSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchValidationTableListError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    removeValidationTable: (state) => {
      state.data = [];
    },
  },
});
export const {
  fetchValidationTableListLoading,
  fetchValidationTableListSuccess,
  fetchValidationTableListError,
  removeValidationTable,
} = ValidationTableSlice.actions;

export default ValidationTableSlice.reducer;
