import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { TargetCloudListType } from "./TargetCloudListSlice";

export interface SelectedTargetCloudPlatformState {
  data: TargetCloudListType[];
}

const initialState: SelectedTargetCloudPlatformState = {
  data: [],
};

const SelectedTargetCloudPlatformSlice = createSlice({
  name: "selectedtargetcloudplatformslice",
  initialState,
  reducers: {
    addTargetCloudPlatform: (state, action: PayloadAction<any>) => {
      const selectedData = action.payload.filter(
        (item: TargetCloudListType) => item?.isSelected
      );
      state.data = selectedData;
    },
    removeTargetCloudPlatform: (state) => {
      state.data = [];
    },
  },
});

export const { addTargetCloudPlatform, removeTargetCloudPlatform } =
  SelectedTargetCloudPlatformSlice.actions;
export default SelectedTargetCloudPlatformSlice.reducer;
