import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CodeDiscoveryDbListState {
  data: [];
  loading: boolean;
  error: string[] | null;
}

const initialState: CodeDiscoveryDbListState = {
  data: [],
  loading: false,
  error: null,
};

const ViewCodeSnippetSlice = createSlice({
  name: "viewcodesnippetdata",
  initialState,
  reducers: {
    viewSnippetLoading: (state) => {
      state.loading = true;
    },
    viewSnippetData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = [];
    },
    viewSnippetError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = ["code snippet not found"];
      state.data = [];
    },
  },
});

export const { viewSnippetLoading, viewSnippetData, viewSnippetError } =
  ViewCodeSnippetSlice.actions;
export default ViewCodeSnippetSlice.reducer;
