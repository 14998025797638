import Cards from "./Cards";
import "../../styles/DiscoverHome.css";
import { RootState } from "../../redux/store/Store";
import { useSelector } from "react-redux";

interface CardData {
  title: string;
  desc: string;
  href: string;
  btnTitle: string;
  svgUrl: string;
}

interface HeadingData {
  headingData: string;
  desc: string;
  cardData: CardData[];
}

interface Data {
  data: HeadingData;
}

const SubHome = (data: Data) => {
  const selectedSource = useSelector(
    (state: RootState) => state.Common.SelectedDataSourceReducer
  );

  function filteredData(data: CardData[]) {
    if (selectedSource.data.length > 0) {
      data[0].href = "/home/convert/codeconversion/converttarget";
      return data;
    } else {
      data[0].href = "/home/discover/codediscovery/sourcecode";
      return data;
    }
  }

  return (
    <div>
      <div className="HomeTitleContainer">
        <div className="HomeTitle">{data.data.headingData}</div>
      </div>
      <div className="HomeBodyContainer">
        <div className="HomeContentDescription">{data.data.desc}</div>
        <div className="HomeCardContainer">
          <Cards
            cardData={
              data.data.headingData === "Convert"
                ? filteredData(data.data.cardData)
                : data.data.cardData
            }
          />
        </div>
      </div>
    </div>
  );
};

export default SubHome;
