import { DataGrid } from "@mui/x-data-grid";

import "../../styles/ConfigDatagrid.css";
import { useCallback, useState } from "react";
interface PropType {
  rows: object[];
  columns: any[];
  getValue?: (value: any) => void;
  checkEdit: (value: boolean) => void;
  updateData: (value: any) => void;
}
function CcDatagrid(props: PropType) {
  let [selectedData, setSelectedData] = useState<any>();

  const handleNewObjectChange = useCallback((value: any) => {
    setSelectedData(value);
  }, []);

  const cellClickHandler = (params: any, event: any) => {
    props.checkEdit(true);
    event.preventDefault();
    if (params.field === "instanceType") {
      handleNewObjectChange(params.row.instanceType);
    }
  };
  const onRowClick = (params: any) => {
    props.updateData(params);
  };
  return (
    <div className="ccDatagridContainer">
      <DataGrid
        sx={{
          "& .MuiDataGrid-footerContainer": {
            height: "1rem",
          },
          "& .MuiDataGrid-columnHeader:nth-of-child(2n)": {
            color: "red",
          },
          "& .MuiDataGrid-cell--editable": {
            "&:hover": {
              border: "2px solid #7500c0",
              color: "black",
            },
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#FFF",
            color: "#000",
            fontSize: 16,
            fontWeight: 600,
          },
          '& div[data-rowIndex][role="row"]:nth-of-type(2n)': {
            background: "#EBEEF0",
            fontSize: 16,
            "& div": {},
          },
          "& .Mui-selected": {
            color: "black !important",
            fontSize: "1rem",
            fontWeight: "600",
            border: "1px solid grey !important",
          },
          "& .Mui-checked": {},
        }}
        rows={props.rows}
        columns={props.columns}
        hideFooter
        onRowClick={onRowClick}
        onCellClick={cellClickHandler}
      />
    </div>
  );
}

export default CcDatagrid;
