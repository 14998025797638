import {
  fetchSchemaBySizeLoading,
  fetchSchemaBySizeSuccess,
  fetchSchemaBySizeError,
} from "../../slices/discoveryoverview/SchemaBySizeSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getSchemaBySize = () => async (dispatch: AppThunkDispatch) => {
  dispatch(fetchSchemaBySizeLoading());
  try {
    const response = await AxiosInstance.get(
      "api/pulsesdm/Discovery_overview/get/schemaBySize"
    );
    const data = response?.data;
    dispatch(fetchSchemaBySizeSuccess(data));
  } catch (error: any) {
    dispatch(fetchSchemaBySizeError(error.message));
  }
};
