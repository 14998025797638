import { useEffect, useState } from "react";
import { AppDataCards } from "../../Shared/Components/AppDataCards";
import { Box, Button } from "@mui/material";
import { Dispatch } from "@reduxjs/toolkit";
import { addDataSource } from "../../redux/slices/sdvslices/SelectSourceSystemDataSlice";
import { RootState } from "../../redux/store/Store";
import { useDispatch, useSelector } from "react-redux";
import { getSourceSystemList } from "../../redux/thunks/sdv/SourceSystemListThunk";
import AppLoader from "../../Shared/Components/AppLoader";
import { SdvCloudListType } from "../../redux/slices/sdvslices/SdvCloudListSlice";
import { addSource } from "../../redux/slices/sdvslices/SelectedSdvSourceCloudslice";
import { getCloudList } from "../../redux/thunks/sdv/SdvCloudListThunk";
import "../../styles/FileSystem.css";
import DataSourceConfig from "./DataSourceConfig";

interface SdvSourceprops {
  isSourceAvailable?: boolean;
  switchTab: (x: number) => void;
}

const SdvSource = (props: SdvSourceprops) => {
  const dispatch: Dispatch<any> = useDispatch();
  const [configSelection, setConfigSelection] = useState("");
  const [updatedSourceCloudList, setUpdatedSourceCloudList] = useState<
    SdvCloudListType[] | []
  >([]);
  const [isDataSourceSelected, setIsDataSourceSelected] = useState(false);
  const selectedSystemSourceType = useSelector(
    (state: RootState) =>
      state.Sdv.SelectedSourceSystemTypeReducer.data[0]?.value
  );
  const { data: cloudPlatformDataList, loading: cloudLoading } = useSelector(
    (state: RootState) => state.Sdv.SdvCloudListReducer
  );
  const selectedSourceCloudId = useSelector(
    (state: RootState) => state.Sdv.SelectedSdvSourceReducer?.data[0]?.id
  );
  const setConfigSelectionData = (config: string) => {
    setConfigSelection(config);
  };
  useEffect(() => {
    if (selectedSourceCloudId) {
      const manipulatedList = cloudPlatformDataList?.map((item) => {
        if (item.id === selectedSourceCloudId)
          return { ...item, isSelected: true };
        else {
          return { ...item, isSelected: false };
        }
      });
      setUpdatedSourceCloudList(manipulatedList);
    } else {
      const manipulatedList = cloudPlatformDataList?.map((item) => {
        return { ...item, isSelected: false };
      });
      setUpdatedSourceCloudList(manipulatedList);
    }
  }, [cloudPlatformDataList, selectedSourceCloudId]);

  useEffect(() => {
    if (selectedSystemSourceType === "on_cloud")
      dispatch(getCloudList(selectedSystemSourceType));
  }, [dispatch, selectedSystemSourceType]);

  useEffect(() => {
    if (selectedSourceCloudId && selectedSystemSourceType === "on_cloud")
      dispatch(
        getSourceSystemList(selectedSystemSourceType, selectedSourceCloudId)
      );
    else if (selectedSystemSourceType === "on_prem") {
      dispatch(getSourceSystemList(selectedSystemSourceType));
    }
  }, [dispatch, selectedSourceCloudId, selectedSystemSourceType]);

  const updateSelectedCloud = (ID: string) => {
    const selected = updatedSourceCloudList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedSourceCloudList(selected);
    dispatch(addSource(selected));
  };

  const [updatedSourceSystemTypeList, setUpdatedSourceSystemTypeList] =
    useState<SdvCloudListType[] | []>([]);
  const { data: sourceSystemList, loading } = useSelector(
    (state: RootState) => state.Sdv.SourceSystemReducer
  );

  const selectedSourceSystemCloudId = useSelector(
    (state: RootState) => state.Sdv.SelectedSourceSystemDataReducer?.data[0]?.id
  );
  useEffect(() => {
    if (selectedSourceSystemCloudId) {
      const manipulatedList = sourceSystemList?.map((item) => {
        if (item.id === selectedSourceSystemCloudId)
          return { ...item, isSelected: true };
        else {
          return { ...item, isSelected: false };
        }
      });
      setUpdatedSourceSystemTypeList(manipulatedList);
    } else {
      const manipulatedList = sourceSystemList?.map((item: any) => {
        return { ...item, isSelected: false };
      });
      setUpdatedSourceSystemTypeList(manipulatedList);
    }
  }, [sourceSystemList, selectedSourceSystemCloudId]);

  const updateSelectedSourceSystemType = (ID: string) => {
    const selected = updatedSourceSystemTypeList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedSourceSystemTypeList(selected);
    dispatch(addDataSource(selected));
    setIsDataSourceSelected(selected.some((item) => item.isSelected));
  };
  const fnTabChange = () => {
    props.switchTab(1);
  };
  return (
    <div className="sdvContainer">
      <h2 className="sdvTitle sdvContentPadding">
        {props.isSourceAvailable ? "Source" : "Data source"}
      </h2>
      {props.isSourceAvailable && (
        <div>
          <p className="sdvTextDesc sdvContentPadding">
            Select your source cloud platform
          </p>
          <Box className="sdvAppdataCards">
            {cloudLoading ? (
              <AppLoader showBackDrop={false} />
            ) : (
              updatedSourceCloudList.map((data: any, index: any) => {
                return (
                  <AppDataCards
                    key={index}
                    dataItem={data}
                    handleClick={updateSelectedCloud}
                  />
                );
              })
            )}
          </Box>
        </div>
      )}
      {selectedSourceCloudId && (
        <p className="sdvTextDesc sdvContentPadding">
          Select your{" "}
          {props.isSourceAvailable ? "source system" : "database source"}
        </p>
      )}

      <Box className="sdvAppdataCards">
        {loading && <AppLoader showBackDrop={true} />}
        {updatedSourceSystemTypeList &&
          updatedSourceSystemTypeList.map((data: any, index: any) => {
            return (
              <AppDataCards
                key={index}
                dataItem={data}
                handleClick={updateSelectedSourceSystemType}
              />
            );
          })}
      </Box>
      {selectedSourceSystemCloudId && isDataSourceSelected && (
        <p className="sdvTextDesc sdvContentPadding">
          Select the configuration to connect to the database
        </p>
      )}

      {selectedSourceSystemCloudId && (
        <Box>
          <DataSourceConfig
            target={props.isSourceAvailable ? "cloud" : "database"}
            selectedConfig={setConfigSelectionData}
          />
        </Box>
      )}
      <div className="sdvSourceContinueBtn">
        <Button
          variant="contained"
          size="medium"
          onClick={fnTabChange}
          disabled={!configSelection}
        >
          Save and Proceed
        </Button>
      </div>
    </div>
  );
};
export default SdvSource;
