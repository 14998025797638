import Breadcrumbs from "@mui/material/Breadcrumbs";
import Link from "@mui/material/Link";
import { Link as RouterLink } from "react-router-dom";
import "../../styles/AppBreadCrumb.css";

export interface listDataBreadCrum {
  title: string;
  url: string;
}

interface breadCrumProps {
  listBreadCrum: listDataBreadCrum[];
}

export default function AppBreadCrumb({ listBreadCrum }: breadCrumProps) {
  const length = listBreadCrum.length;
  return (
    <Breadcrumbs separator="›" aria-label="breadcrumb">
      {listBreadCrum.map((step: listDataBreadCrum, index: number) =>
        step.url ? (
          <Link
            component={RouterLink}
            to={step.url !== null ? step.url : "/"}
            key={index}
            className="AppBreadCrumbStyleObject"
            underline="none"
          >
            <span>{step.title}</span>
          </Link>
        ) : (
          <span key={index} className="breadCrumbFontWeight">
            {step.title}
          </span>
        )
      )}
    </Breadcrumbs>
  );
}
