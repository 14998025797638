import { Button, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Link as RouteLink } from "react-router-dom";
import CcDatagrid from "./CcDatagrid";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";
import { getComputeTypeList } from "../../redux/thunks/sdv/ComputeTypeListThunk";
import { getInstanceTypeList } from "../../redux/thunks/sdv/InstanceTypeListThunk";
import { getInstanceDetails } from "../../redux/thunks/sdv/InstanceDetailsThunk";
import "../../styles/ConfigDatagrid.css";
import "../../styles/ComputeConfiguration.css";
import {
  GridColDef,
  GridRenderCellParams,
  GridRowId,
  useGridApiContext,
} from "@mui/x-data-grid";
const style = {
  minWidth: "17.688rem",
  "& .MuiInputLabel-root": { color: "#4F4B53" }, //styles the label
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: "#B3B2B5" },
  },
  "& .MuiOutlinedInput-root:hover": {
    "& > fieldset": {
      borderColor: "#A100FF",
    },
  },
};

const ComputeConfiguration = () => {
  const [dataGridView, setDataGridView] = useState(false);
  const [InstanceList, setInstanceList] = useState<any>([""]);
  const [computeTypeOptions, setComputeTypeOptions] = useState<string[] | []>(
    []
  );
  const [gridEditRows, setGridEditRows] = useState<any>([]);
  const [selectedCompute, setSelectedCompute] = useState("");
  const [isGridEdited, setIsGridEdited] = useState(false);
  const [currentRowId, setcurrentRowId] = useState<any>(0);
  const [selectedValue, setSelectedValue] = useState("");
  const dispatch: any = useDispatch();
  const { data: ComputeTypeList } = useSelector(
    (state: RootState) => state.Sdv.ComputeTypeListReducer
  );
  const { data: instanceTypeDetails } = useSelector(
    (state: RootState) => state.Sdv.InstanceDetailsReducer
  );
  const TargetCloudId = useSelector(
    (state: RootState) => state.Sdv.SelectedCloudPlatformReducer?.data[0]?.id
  );
  const { data: instanceTypeList } = useSelector(
    (state: RootState) => state.Sdv.InstanceTypeListReducer
  );
  const renderSelectEditInputCell: GridColDef["renderCell"] = (params) => {
    return <SelectEditInputCell {...params} />;
  };
  function SelectEditInputCell(props: GridRenderCellParams) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });

      setGridEditRows(
        gridEditRows.map((row: { id: GridRowId }) => {
          let updatedRow = { ...row, instanceType: event.target.value };
          if (id === row?.id) {
            return updatedRow;
          } else {
            return row;
          }
        })
      );
      setSelectedValue(event.target.value);
    };
    return (
      <TextField
        select
        InputLabelProps={{ shrink: false }}
        value={value}
        sx={{ width: "350px" }}
        onChange={handleChange}
      >
        {InstanceList !== undefined &&
          InstanceList.map((item: string, index: number) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))}
      </TextField>
    );
  }

  const columns = [
    {
      field: "bucket",
      headerName: "Bucket",
      width: 200,
      renderCell: (params: any) => {
        return (
          <div
            style={{
              width: 145,
              height: 40,
              display: "flex",
              alignItems: "center",
              fontWeight: 600,
              fontSize: "14px",
            }}
          >
            {params.value as string}
          </div>
        );
      },
    },
    {
      field: "instanceType",
      headerName: "Instance_Type",
      width: 200,
      editable: true,
      valueOptions: [],
      type: "singleSelect",
      renderCell: (param: any) => {
        return param.row.instanceType === "select any" ? (
          <Typography>select any</Typography>
        ) : (
          <SelectEditInputCell {...param} />
        );
      },
      renderEditCell: renderSelectEditInputCell,
    },
    {
      field: "instanceCount",
      headerName: "Instance_Count",
      width: 200,
      editable: true,
      renderCell: (params: any) => {
        gridEditRows[params.row.id].instanceCount = params.row.instanceCount;
      },
    },
    {
      field: "minSize",
      headerName: "Min_Size",
      width: 200,
      renderCell: (params: any) => {
        gridEditRows[params.row.id].minSize = params.row.minSize;
      },
      editable: true,
    },
    {
      field: "maxSize",
      headerName: "Max_Size",
      width: 200,
      renderCell: (params: any) => {
        gridEditRows[params.row.id].maxSize = params.row.maxSize;
      },
      editable: true,
      filterable: false,
      required: true,
    },
  ];

  const rows = [
    {
      id: 0,
      bucket: "Small",
      instanceType: gridEditRows[0]?.instanceType
        ? gridEditRows[0]?.instanceType
        : "select any",
      instanceCount: 1,
      minSize: 1,
      maxSize: 100,
    },
    {
      id: 1,
      bucket: "Medium",
      instanceType: gridEditRows[1]?.instanceType
        ? gridEditRows[1]?.instanceType
        : "select any",
      instanceCount: 1,
      minSize: 1,
      maxSize: 100,
    },
    {
      id: 2,
      bucket: "Large",
      instanceType: gridEditRows[2]?.instanceType
        ? gridEditRows[2]?.instanceType
        : "select any",
      instanceCount: 1,
      minSize: 1,
      maxSize: 100,
    },
  ];

  useEffect(() => {
    //instance_type_details
    selectedValue && dispatch(getInstanceDetails(TargetCloudId, selectedValue));
  }, [selectedValue]);

  useEffect(() => {
    setComputeTypeOptions([...ComputeTypeList]);
    setGridEditRows(rows);
  }, [ComputeTypeList]);

  useEffect(() => {
    //list_compute_type, e.g., Databricks , EMR
    dispatch(getComputeTypeList(TargetCloudId));
  }, [TargetCloudId]);

  useEffect(() => {
    if (instanceTypeList?.InstanceTypeOfferings?.InstanceType !== undefined) {
      setInstanceList(instanceTypeList?.InstanceTypeOfferings?.InstanceType);
    }
  }, [instanceTypeList, currentRowId, gridEditRows]);

  const checkEdited = (value: boolean) => {
    setIsGridEdited(value);
  };

  const updateData = (params: any) => {
    setcurrentRowId(params.id);
  };
  const handleClickEvent = () => {
    setDataGridView(true);
    dispatch(getInstanceTypeList(TargetCloudId));
  };
  return (
    <div className="ccContainer">
      <h2 className="ccTitle">Compute configuration</h2>
      <p className="ccDesc">
        In this step <b>Bucket size</b> could be only for visibility, in case
        you need to edit please contact our administrator.
        <b> Compute default settings</b> table could be edited. Also you can
        Execute in order to get bucketing validation.
      </p>
      <div className="ccBoxContainer">
        <h3 className="ccBoxContainerTitle">Bucket size in GB</h3>
        <span className="ccpurpleHeading">Small:</span>
        <span className="ccBktSzeValue"> &lt;0.5</span>
        <span className="ccpurpleHeading">Medium:</span>
        <span className="ccBktSzeValue"> 0.5 &gt; and &lt; 1</span>
        <span className="ccpurpleHeading">Large:</span>
        <span className="ccBktSzeValue"> &gt; 1</span>
      </div>
      <div className="ccComputeType">
        <label>Select Compute Type</label>
        <TextField
          InputLabelProps={{ shrink: false }}
          placeholder="Select compute Type"
          select
          sx={style}
          label=""
          value={selectedCompute}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setSelectedCompute(e.target.value)
          }
        >
          {computeTypeOptions?.map((option) => {
            return (
              <MenuItem key={option} value={option}>
                {option}
              </MenuItem>
            );
          })}
        </TextField>
      </div>
      <div className="ccExecBtnContainer">
        <Button
          className="ccExecBtn"
          variant="contained"
          size="small"
          onClick={handleClickEvent}
          disabled={!selectedCompute}
        >
          Apply
        </Button>
      </div>
      {dataGridView && (
        <div>
          <h2 className="ccTitle">Compute Default setting</h2>
          <p className="ccDesc">
            The following table shows the results after execution, if required,
            you can edit the fields and save to continue.{" "}
          </p>
          <CcDatagrid
            columns={columns}
            rows={rows}
            checkEdit={checkEdited}
            updateData={updateData}
          />
          {isGridEdited && (
            <div
              className="datagridInfoContainer"
              style={{ marginTop: "2rem" }}
            >
              <div className="ccDatagridWarning">
                <InfoOutlinedIcon />
                &nbsp; Changes were found in the table information, click save
                to confirm.
              </div>
              <div className="ccDatagridWarning" style={{ marginTop: "1rem" }}>
                <InfoOutlinedIcon />
                &nbsp; Instance Type "{selectedValue}" has the following value :
              </div>
              {gridEditRows[currentRowId].instanceType !== "select any" && (
                <div
                  className="ccDatagridWarning"
                  style={{ marginLeft: "2rem" }}
                >
                  vCpus :{" "}
                  {instanceTypeDetails.vCpus
                    ? instanceTypeDetails.vCpus
                    : instanceTypeDetails.vCpus}
                  {", "}
                  storange :{" "}
                  {instanceTypeDetails.storage
                    ? instanceTypeDetails.storage
                    : instanceTypeDetails.storage}
                  {", "}
                  memory :{" "}
                  {instanceTypeDetails.memory
                    ? instanceTypeDetails.memory
                    : instanceTypeDetails.storage}
                </div>
              )}
            </div>
          )}
          <div className="datagridBtnContainer">
            <Button
              sx={{ marginTop: "2rem" }}
              variant="contained"
              size="small"
              component={RouteLink}
              to={"/home/validate/smartdatavalidator/validation"}
            >
              Save and Continue
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ComputeConfiguration;
