import {
  fetchSchemaDependencyLoading,
  fetchSchemaDependencySuccess,
  fetchSchemaDependencyError,
} from "../../slices/discoveryoverview/SchemaDependencySlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getSchemaDependency =
  (schema: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchSchemaDependencyLoading());
    try {
      const response = await AxiosInstance.get(
        `api/pulsesdm/Discovery_overview/get/schemaDependency/${schema}`
      );
      const data = response?.data;
      dispatch(fetchSchemaDependencySuccess(data));
    } catch (error: any) {
      dispatch(fetchSchemaDependencyError(error.message));
    }
  };
