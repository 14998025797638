import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CloudPlatformDataType } from "./CloudPlatFormSlice";
import { TargetDatabaseType } from "./TargetDatabaseSlice";

export interface SelectedTargetDbState {
  data: TargetDatabaseType[];
}

const initialState: SelectedTargetDbState = {
  data: [],
};

const SelectedTargetDbSlice = createSlice({
  name: "selectedtargetdbslice",
  initialState,
  reducers: {
    addTargetDb: (state, action: PayloadAction<any>) => {
      const selectedData = action.payload.filter(
        (item: TargetDatabaseType) => item?.isSelected
      );
      state.data = selectedData;
    },
  },
});

export const { addTargetDb } = SelectedTargetDbSlice.actions;
export default SelectedTargetDbSlice.reducer;
