import SchemaBySizeReducer from "../slices/discoveryoverview/SchemaBySizeSlice";
import SchemaQueryCountSlice from "../slices/discoveryoverview/SchemaQueryCountSlice";
import ObjectSummarySlice from "../slices/discoveryoverview/ObjectSummarySlice";
import SchemaDependencySlice from "../slices/discoveryoverview/SchemaDependencySlice";
import ObjectDependencySlice from "../slices/discoveryoverview/ObjectDependencySlice";

const DiscoverOverviewReducers = {
  SchemaBySizeReducer,
  SchemaQueryCountSlice,
  ObjectSummarySlice,
  SchemaDependencySlice,
  ObjectDependencySlice,
};

export default DiscoverOverviewReducers;
