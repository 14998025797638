import {  tokenLoading, tokenSuccess, tokenError } from "../slices/AuthSlice";
import { AppThunkDispatch } from "../store/Store";
import AxiosInstance from "../../core/AxiosInterceptor"; 
import Encrypt from "../../core/Encrypt";

/* export const authenticateUser =
  (userInfo: Object) => async (dispatch: AppThunkDispatch) => {
    dispatch(authLoading());
    try {
      const response = await AxiosInstance.post(
        "api/pulsesdm/Login/post",
        JSON.stringify({
          login_details: Encrypt(userInfo),
        })
      );
      const status = response?.data?.status;
      /* added this to handle error and success scenario as in both cases the status is 200 
      if (status.toLowerCase() === "success") {
        dispatch(authSuccess(userInfo));
      } else dispatch(authError(response?.data?.message));
    } catch (error: any) {
      dispatch(authError(error.message));
    }
  }; */
export const getToken =() => async (dispatch: AppThunkDispatch) => {
    dispatch(tokenLoading());
    try {
      const response = await AxiosInstance.get("api/pulsesdm/Token/get_token/");
      if (response?.data) {
        dispatch(tokenSuccess(response?.data?.token))
      }
    } catch (error: any) {
      dispatch(tokenError(error.message));
    }
  };