import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import SdvFileSystem from "./SdvFileSystem";
import SdvDataConfig from "./SdvDataConfig";
import "../../styles/FileSystem.css";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";
import AzureModal from "../../Shared/Components/CloudFormModals/AzureModal";
import AwsModal from "../../Shared/Components/CloudFormModals/AwsModal";
import GcpModal from "../../Shared/Components/CloudFormModals/GcpModal";
import { addSdvConfig } from "../../redux/thunks/sdv/ConfigurationDetailsThunk";
import { clearConfigurationDetails } from "../../redux/slices/sdvslices/ConfigurationDetailsSlice";

interface PropType {
  switchTab: (index: number) => void;
  value: number;
}
const FileSystem = ({ switchTab, value }: PropType) => {
  const fnTabChange = () => {
    switchTab(value);
  };
  const dispatch: any = useDispatch();
  const [sourceCodeName, setSourceCodeName] = useState("Azure Blob");
  const [modalType, setModalType] = useState("");
  const [configSelection, setConfigSelection] = useState("");
  const [isSourceCodeModalOpen, setIsSourceCodeModalOpen] = useState(false);
  const handleSourceCodeModalClose = () => {
    setIsSourceCodeModalOpen(false);
  };
  const [isEditable, setIsEditable] = useState(false);
  const { data: selectedDataStorage } = useSelector(
    (state: RootState) => state.Sdv.SelectedFileSystemReducer
  );
  const selectedCloudPlateformID = useSelector(
    (state: RootState) => state.Sdv.SelectedCloudPlatformReducer
  );

  useEffect(() => {
    if (selectedDataStorage && selectedDataStorage.length > 0) {
      setSourceCodeName(selectedDataStorage[0].name);
    }
  }, [selectedDataStorage]);

  const handleFormSaveClick = (payload: string) => {
    if (selectedDataStorage && selectedDataStorage.length > 0)
      dispatch(addSdvConfig(selectedDataStorage[0].id, payload));
  };
  useEffect(() => {
    dispatch(clearConfigurationDetails());
    selectedCloudPlateformID?.data[0]?.name !== undefined &&
      setModalType(selectedCloudPlateformID?.data[0]?.name.toLocaleLowerCase());
  }, [selectedCloudPlateformID]);
  const setConfigSelectionData = (config: string) => {
    setConfigSelection(config);
  };
  return (
    <div className="sdvContainer">
      <h2 className="sdvTitle sdvContentPadding">File system</h2>
      <p className="sdvTextDesc sdvContentPadding">
        Select the cloud platform of your file system
      </p>
      <Box>
        <SdvFileSystem />
      </Box>
      {selectedDataStorage.length !== 0 && (
        <p className="sdvTextDesc sdvContentPadding">
          Select the configuration to connect to the file
        </p>
      )}
      {selectedDataStorage.length !== 0 && (
        <Box>
          <SdvDataConfig
            targetDbId={selectedDataStorage[0]?.id}
            typeOfModal={modalType}
            pathName="/home/validate/smartdatavalidator/configuration"
            selectedConfig={setConfigSelectionData}
          />
          <Button
            variant="text"
            size="small"
            sx={{ textTransform: "none" }}
            className="sdvAddDataConfigContainer"
            onClick={() => {
              setIsSourceCodeModalOpen(true);
              setIsEditable(false);
            }}
            disabled={selectedDataStorage.length === 0}
          >
            <Typography sx={{ fontSize: "1.2rem" }}>
              + Add new configuration
            </Typography>
          </Button>
        </Box>
      )}
      <div className="sdvSourceContinueBtn">
        <Button
          variant="contained"
          size="medium"
          onClick={fnTabChange}
          disabled={!configSelection}
        >
          Save and Proceed
        </Button>
      </div>

      {selectedDataStorage.length > 0 &&
        selectedCloudPlateformID?.data[0]?.id === "cp_13" && (
          <AzureModal
            open={isSourceCodeModalOpen}
            onClose={handleSourceCodeModalClose}
            title="File System Configuration"
            isEdit={isEditable}
            handleSave={handleFormSaveClick}
          />
        )}
      {selectedDataStorage.length > 0 &&
        selectedCloudPlateformID?.data[0]?.id === "cp_11" && (
          <AwsModal
            open={isSourceCodeModalOpen}
            onClose={handleSourceCodeModalClose}
            title="File System Configuration"
            isEdit={isEditable}
            handleSave={handleFormSaveClick}
          />
        )}
      {selectedDataStorage.length > 0 &&
        selectedCloudPlateformID?.data[0]?.id === "cp_12" && (
          <GcpModal
            open={isSourceCodeModalOpen}
            onClose={handleSourceCodeModalClose}
            title="File System Configuration"
            isEdit={isEditable}
            handleSave={handleFormSaveClick}
          />
        )}
    </div>
  );
};
export default FileSystem;
