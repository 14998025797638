import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SourceSystemData {
  id: string;
  name: string;
  isSelected?: boolean;
}
export interface SelectedSourceSystemData {
  data: SourceSystemData[];
}

const initialState: SelectedSourceSystemData = {
  data: [],
};

const selectedDataSourceSystemSlice = createSlice({
  name: "selectedSourceSystemData",
  initialState,
  reducers: {
    addDataSource: (state, action: PayloadAction<any>) => {
      const selectedData = action.payload.filter(
        (item: SourceSystemData) => item?.isSelected
      );
      state.data = selectedData;
    },
    removeDataSource: (state) => {
      state.data = [];
    },
  },
});

export const { addDataSource, removeDataSource } =
  selectedDataSourceSystemSlice.actions;
export default selectedDataSourceSystemSlice.reducer;
