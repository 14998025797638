import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SchemaQueryState {
  schema: string;
  total_queries: number;
}
export interface SchemaQueryCountState {
  data: undefined | SchemaQueryState[];
  loading: boolean;
  error: string | null;
}

const initialState: SchemaQueryCountState = {
  data: undefined,
  loading: false,
  error: null,
};

const SchemaQueryCountSlice = createSlice({
  name: "schemaquerycountslice",
  initialState,
  reducers: {
    fetchSchemaQueryCountLoading: (state) => {
      state.loading = true;
    },
    fetchSchemaQueryCountSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchSchemaQueryCountError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
  },
});

export const {
  fetchSchemaQueryCountLoading,
  fetchSchemaQueryCountSuccess,
  fetchSchemaQueryCountError,
} = SchemaQueryCountSlice.actions;

export default SchemaQueryCountSlice.reducer;
