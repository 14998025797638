import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ObjectSummaryType {
  OBJECT_TYPE: String;
  object_count: number;
}

export interface ObjectSummaryState {
  data: ObjectSummaryType[];
  loading: boolean;
  error: string | null;
}

const initialState: ObjectSummaryState = {
  data: [],
  loading: false,
  error: null,
};

const ObjectSummarySlice = createSlice({
  name: "objectsummaryslice",
  initialState,
  reducers: {
    fetchObjectSummaryLoading: (state) => {
      state.loading = true;
    },
    fetchObjectSummarySuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchObjectSummaryError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
  },
});

export const {
  fetchObjectSummaryLoading,
  fetchObjectSummarySuccess,
  fetchObjectSummaryError,
} = ObjectSummarySlice.actions;

export default ObjectSummarySlice.reducer;
