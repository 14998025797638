import * as React from "react";
import { Button, Divider, Link } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";

import AxiosInstance from "../../core/AxiosInterceptor";
import Popups from "../../Shared/Components/AppPopups";
import "../../styles/CodeResults.css";
const CodeResults = () => {
  const [open, setOpen] = useState(false);
  const [openTransform, setOpenTransform] = useState(false);
  let navigate = useNavigate();

  const data: any = [
    {
      title: " Do you want to proceed with code conversion?",
      src: "/assets/images/icon_warning.svg",
      desc: "",
      btns: [
        {
          btnTitle: "No",
          btnType: "outlined",
          btnfunc: () => {
            setOpen(false);
          },
        },
        {
          btnTitle: "Yes",
          btnType: "contained",
          btnfunc: () => {
            setOpen(false);
            setOpenTransform(true);
            setTimeout(() => {
              navigate("/home/convert/");
              setOpenTransform(false);
            }, 3000);
          },
        },
      ],
    },
  ];

  const downloadTemplate = () => {
    try {
      AxiosInstance({
        url: "api/pulsesdm/S3/blob/data-migration-assets/report.xlsx",
        method: "POST",
        responseType: "blob",
        params: {
          location: "discover/code-discovery/Oracle/Discovery_package/Report/",
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    } catch (error: any) {}
  };
  const modalDataTransform: any = [
    {
      title: "Redirecting to Code conversion",
      src: "/assets/images/redirection.svg",
      desc: "You have finished to run your code now are being redirecting to Code conversion process ",
      btns: [],
    },
  ];
  const ModalCloseFunc = () => {
    setOpen(false);
  };
  return (
    <>
      <p className="codeResultsDescription">
        Once you have executed your source codes are ready to use, in case do
        you need to download xls file will be automatically saved in your
        Download folder. If you need to check the dashboard they will be
        deployed in another tab.
      </p>
      <div className="codeResultsLinks">
        <Link
          component="button"
          variant="inherit"
          underline="none"
          className="codeResultsDownloadXlsFileBtn"
          onClick={downloadTemplate}
        >
          <img
            className="downloadImgBtn"
            src="/assets/images/icon_download.svg"
            alt="download file"
            title="download file"
          />
          Download XLS file
        </Link>
        <Divider
          orientation="vertical"
          flexItem
          className="codeResultsDivider"
        />
        <Link
          component="button"
          variant="inherit"
          className="codeResultsDownloadXlsFileBtn"
        >
          <img
            className="downloadImgBtn"
            src="/assets/images/arrow-right.svg"
            alt="right"
            title="Open dashboard"
          />
          Open dashboard
        </Link>{" "}
      </div>
      <div className="btnContainer">
        <Button
          className="codeResultsProceedWithCodeConversionbtn"
          size="medium"
          variant="contained"
          onClick={() => {
            setOpen(true);
          }}
        >
          Proceed with code conversion
          <img src="/assets/images/right-arrow.png" alt="Add" />
        </Button>
      </div>

      <Popups
        fullData={data}
        btnOpen={open}
        btnClose={!open}
        modalClose={ModalCloseFunc}
      />
      <Popups
        fullData={modalDataTransform}
        btnOpen={openTransform}
        btnClose={!openTransform}
        modalClose={ModalCloseFunc}
      />
    </>
  );
};
export default CodeResults;
