import React, { useEffect, useState } from "react";
import { Link, Box, RadioGroup } from "@mui/material";
import AppConfiguration from "../../Shared/Components/AppConfiguration";
import DialogForm from "../../Shared/Components/DialogForm";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";
import {
  addSdvConfig,
  deleteSdvConfig,
  editSdvConfig,
  getSdvConfigDetails,
} from "../../redux/thunks/sdv/ConfigurationDetailsThunk";
import AppLoader from "../../Shared/Components/AppLoader";

import "../../styles/DataConfig.css";

interface DataConfig {
  showDataSource?: boolean;
  handleClick?: () => void;
  handleClickTarget?: () => void;
  target?: string;
  pathName?: string;
  selectedConfig?: any;
}
const DataSourceConfig = ({
  handleClick,
  handleClickTarget,
  selectedConfig,
  target,
  pathName,
}: DataConfig) => {
  const dispatch: any = useDispatch();
  const [toggleForm, setToggleForm] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selection, setSelection] = useState("");

  const selectedFileSystemId = useSelector(
    (state: RootState) => state.Sdv.SelectedSourceSystemDataReducer?.data[0]?.id
  );

  const { data: configDetailsList, loading: configDetailsLoading } =
    useSelector((state: RootState) => state.Sdv.ConfigurationDetailsSlice);

  useEffect(() => {
    if (selectedFileSystemId) {
      dispatch(getSdvConfigDetails(selectedFileSystemId));
    }
  }, [dispatch, selectedFileSystemId]);

  const handleRadioSelection = (e: any) => {
    setSelection(e.target.value);
    selectedConfig(e.target.value);
  };
  const handleAppConfigDeleteClick = (db_id: string, secret_Id: string) => {
    dispatch(deleteSdvConfig(db_id, secret_Id));
  };
  const handleAddConfigClick = () => {
    setToggleForm(!toggleForm);
    setIsEditable(false);
  };

  const handleFormSaveClick = (payload: string) => {
    dispatch(addSdvConfig(selectedFileSystemId, payload));
  };

  const handleEditSaveButtonClick = (
    dbID: string,
    secretID: string,
    payload: string
  ) => {
    dispatch(editSdvConfig(dbID, secretID, payload));
  };

  return (
    <div>
      <Box className="dataConfigContainer">
        <Box>
          <RadioGroup
            name="RadioGroup"
            value={selection}
            onChange={handleRadioSelection}
          >
            {configDetailsLoading ? (
              <AppLoader showBackDrop={false} />
            ) : (
              configDetailsList &&
              configDetailsList.length > 0 &&
              configDetailsList.map((item, index) => (
                <AppConfiguration
                  dbConfigDetails={item}
                  key={index}
                  indexKey={index}
                  radioProps={{ value: index, label: index }}
                  dbId={selectedFileSystemId}
                  handleDeleteClick={handleAppConfigDeleteClick}
                  handleEdit={handleEditSaveButtonClick}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  title={
                    target === "target"
                      ? "Target Configuration"
                      : target === "cloud"
                      ? "Source Configuration"
                      : "Data Source Configuration"
                  }
                  isSdv={true}
                />
              ))
            )}
          </RadioGroup>
        </Box>
        <Box className="dataConfigAddNewConfigBtn">
          <Link
            component="button"
            variant="inherit"
            underline="none"
            onClick={handleAddConfigClick}
            className="dataConfigAddNewConfigBtnLink"
          >
            <img
              className="dataConfigAddNewConfigBtnImg"
              src="/assets/images/add.png"
              alt="Add"
            />
            Add new configuration
          </Link>
        </Box>

        {toggleForm ? (
          <DialogForm
            title={
              target === "target"
                ? "Target Configuration"
                : // : "File System configuration"
                target === "database"
                ? " Data Source Configuration"
                : target === "cloud"
                ? " Source Configuration"
                : "File System Configuration"
            }
            setToggleForm={setToggleForm}
            isEdit={false}
            handleSave={handleFormSaveClick}
            isSdv={true}
          />
        ) : null}
      </Box>
    </div>
  );
};
export default DataSourceConfig;
