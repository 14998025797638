import { Box } from "@mui/material";
import { Navigate, Route, Routes } from "react-router-dom";

import { AppHeader } from "../Shared/Components/AppHeader";
import { AppFooter } from "../Shared/Components/AppFooter";
import Home from "../pages/Home";
import DataSourcePage from "../pages/DataSourcePage";
import DiscoveryOverview from "../pages/sdm/discoveryOverview/DiscoveryOverview";
import MigrationType from "../pages/sdm/MigrationType";
import MetaDataExecution from "../pages/pulse/MetaDataExecution";
import Results from "../pages/sdm/Results/Results";
import Migration from "../pages/sdm/migration/Migration";
import Target from "../pages/sdm/Target";
import SdmDataSource from "../pages/sdm/SdmDataSource";
import SubWrapper from "./SubWrapper";
import DiscoverySummary from "../pages/pulse/DiscoverySummary";
import MigrationHistory from "../pages/sdm/migrationHistory/MigrationHistory";
import MigrationWavePage from "../pages/pulse/MigrationWave/MigrationWavePage";
import ConvertResults from "../pages/codeConvert/ConvertResults";
import ConvertTarget from "../pages/codeConvert/ConvertTarget";
import ConvertConversion from "../pages/codeConvert/ConvertConversion/ConvertConversion";
import Execution from "../pages/codeDiscovery/Execution";
import SourceCode from "../pages/codeDiscovery/SourceCode";
import CodeResults from "../pages/codeDiscovery/CodeResults";
import discoverHome from "../Shared/Utils/data/discoverHome.json";
import migrateHome from "../Shared/Utils/data/migrateHome.json";
import convertHome from "../Shared/Utils/data/convertHome.json";
import validateHome from "../Shared/Utils/data/validateHome.json";
import SubHome from "../Shared/Components/SubHome";
import Validation from "../pages/sdv/Validation";
import Configuration from "../pages/sdv/Configuration";
import SourceAndTarget from "../pages/sdv/SourceAndTarget";
import SourceSystemType from "../pages/sdv/SourceSystemType";

// TODO: To lazy load the pages and load on demand.


/**
 * Implementation of wrapper
 * @returns Wrapper to handle the routes, header , footer
 */

const MainWrapper = () => {
   return (
    <>
      <AppHeader />
      <Box>
        <Routes>
          <Route index element={<Home />} />
          <Route path="discover">
            <Route index element={<SubHome data={discoverHome} />} />
            <Route element={<SubWrapper />}>
              <Route path="codediscovery/sourcecode" element={<SourceCode />} />
              <Route path="codediscovery/execution" element={<Execution />} />
              <Route
                path="codediscovery/coderesult"
                element={<CodeResults />}
              />
              <Route
                path="datadiscovery/datasource"
                element={<DataSourcePage />}
              />
              <Route
                path="datadiscovery/metadataexecution"
                element={<MetaDataExecution />}
              />
              <Route
                path="datadiscovery/migrationwave"
                element={<MigrationWavePage />}
              />
              <Route
                path="datadiscovery/discoverysummary"
                element={<DiscoverySummary />}
              />
              <Route path="*" element={<Navigate replace to="/404" />} />
            </Route>
          </Route>
          <Route path="migrate">
            <Route index element={<SubHome data={migrateHome} />} />
            <Route element={<SubWrapper />}>
              <Route
                path="smartdatawarehousemover/migrationtype"
                element={<MigrationType />}
              />
              <Route
                path="smartdatawarehousemover/datasource"
                element={<SdmDataSource />}
              />
              <Route
                path="smartdatawarehousemover/migration"
                element={<Migration />}
              />
              <Route
                path="smartdatawarehousemover/migrationhistory"
                element={<MigrationHistory />}
              />
              <Route
                path="smartdatawarehousemover/discoveryoverview"
                element={<DiscoveryOverview />}
              />
              <Route
                path="smartdatawarehousemover/results"
                element={<Results />}
              />
              <Route
                path="smartdatawarehousemover/target"
                element={<Target />}
              />
              <Route path="*" element={<Navigate replace to="/404" />} />
            </Route>
          </Route>
          <Route path="convert">
            <Route index element={<SubHome data={convertHome} />} />
            <Route element={<SubWrapper />}>
              <Route
                path="codeconversion/converttarget"
                element={<ConvertTarget />}
              />
              <Route
                path="codeconversion/convertconversion"
                element={<ConvertConversion />}
              />
              <Route
                path="codeconversion/convertresults"
                element={<ConvertResults />}
              />
              <Route path="*" element={<Navigate replace to="/404" />} />
            </Route>
          </Route>
          <Route path="validate">
            <Route index element={<SubHome data={validateHome} />} />
            <Route element={<SubWrapper />}>
              <Route
                path="smartdatavalidator/Sourcesystemtype"
                element={<SourceSystemType />}
              />
              <Route
                path="smartdatavalidator/sourceandtarget"
                element={<SourceAndTarget />}
              />
              <Route
                path="smartdatavalidator/configuration"
                element={<Configuration />}
              />
              <Route
                path="smartdatavalidator/validation"
                element={<Validation />}
              />
              <Route path="*" element={<Navigate replace to="/404" />} />
            </Route>
          </Route>
          <Route path="*" element={<Navigate replace to="/404" />} />
        </Routes>
      </Box>
      <AppFooter />
    </>
  );
};
export default MainWrapper;
