import DataSourceReducer from "../slices/DataSourceSlice";
// import SelectedDataSourceReducer from "../slices/SelectedDataSourceSlice";
import DbAllDetailsReducer from "../slices/DbAllDetailsSlice";
import MigrationWaveReducer from "../slices/MigrationWaveSlice";
import SelectedMigrationWaveReducer from "../slices/SelectedMigrationWaveSlice";

const PulseCombinedReducers = {
  DataSourceReducer,
  //   SelectedDataSourceReducer,
  DbAllDetailsReducer,
  MigrationWaveReducer,
  SelectedMigrationWaveReducer,
};

export default PulseCombinedReducers;
