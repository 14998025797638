import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DataSourceDataType } from "./DataSourceSlice";

export interface SelectedDataSourceState {
  data: DataSourceDataType[];
}

const initialState: SelectedDataSourceState = {
  data: [],
};

const selectedDataSourceSlice = createSlice({
  name: "selecteddatasourceslice",
  initialState,
  reducers: {
    addDataSource: (state, action: PayloadAction<any>) => {
      const selectedData = action.payload.filter(
        (item: DataSourceDataType) => item?.isSelected
      );
      state.data = selectedData;
    },
    removeDataSource: (state) => {
      state.data = [];
    },
  },
});

export const { addDataSource, removeDataSource } =
  selectedDataSourceSlice.actions;
export default selectedDataSourceSlice.reducer;
