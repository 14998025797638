import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ObjectExecutionType } from "../../types/ObjectExecutionTypes";

export interface ObjectExecutionState {
  data: ObjectExecutionType[];
  loading: boolean;
  error: string | null;
}

const initialState: ObjectExecutionState = {
  data: [],
  loading: false,
  error: null,
};

const ObjectConfigExecuteSlice = createSlice({
  name: "objectconfigexecuteslice",
  initialState,
  reducers: {
    postExecuteLoading: (state) => {
      state.loading = true;
    },
    postExecuteSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    postExecuteError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    clearExecuteData: (state) => {
      state.data = [];
    },
    updateRowData: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
  },
});

export const {
  postExecuteLoading,
  postExecuteSuccess,
  postExecuteError,
  clearExecuteData,
  updateRowData,
} = ObjectConfigExecuteSlice.actions;

export default ObjectConfigExecuteSlice.reducer;
