import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  data: [],
  loading: false,
  error: null,
};

const InstanceDetails = createSlice({
  name: "instanceDetails",
  initialState,
  reducers: {
    fetchInstanceDetailsListLoading: (state) => {
      state.loading = true;
    },
    fetchInstanceDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchInstanceDetailsError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = {};
    },
  },
});

export const {
  fetchInstanceDetailsListLoading,
  fetchInstanceDetailsSuccess,
  fetchInstanceDetailsError,
} = InstanceDetails.actions;

export default InstanceDetails.reducer;
