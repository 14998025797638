import React from "react";
import AppTabPanel from "../../Shared/Components/AppTabPanel";
import { Tabs, Tab, useMediaQuery } from "@mui/material";
import ObjectConfiguration from "./ObjectConfiguration";
import ComputeConfiguration from "./ComputeConfiguration";
import ValidateConfiguration from "./ValidateConfiguration";

import "../../styles/TabStyles.css";

const Configuration = () => {
  const [value, setValue] = React.useState(0);
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const tabChangeFun = (tabValue: number) => {
    setValue(tabValue);
  };
  const isMobile = useMediaQuery("(max-width: 1000px)");

  return (
    <div>
      <div className="tabsContainer">
        <Tabs
          className="tabs"
          variant={isMobile ? "fullWidth" : "fullWidth"}
          orientation={isMobile ? "horizontal" : "vertical"}
          value={value}
          onChange={handleChange}
          sx={{
            minWidth: 210, // set the width of the tabs here
            borderRight: 1,
            borderColor: "transparent",
          }}
        >
          <Tab
            wrapped
            className="tab"
            disableRipple={true}
            label="Validate configuration"
          />
          <Tab
            wrapped
            className="tab"
            disableRipple={true}
            label="Object configuration"
          />
          <Tab
            wrapped
            className="tab"
            disableRipple={true}
            label="Compute configuration"
          />
        </Tabs>
        <AppTabPanel value={value} index={0}>
          <ValidateConfiguration tabChangeFun={tabChangeFun} />
        </AppTabPanel>
        <AppTabPanel value={value} index={1}>
          <ObjectConfiguration tabChangeFun={tabChangeFun} />
        </AppTabPanel>
        <AppTabPanel value={value} index={2}>
          <ComputeConfiguration />
        </AppTabPanel>
      </div>
    </div>
  );
};

export default Configuration;
