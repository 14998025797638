import {
  fetchComputeTypeListLoading,
  fetchComputeTypeListSuccess,
  fetchComputeTypeListError,
} from "../../slices/sdvslices/ComputeTypeListSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getComputeTypeList =
  (cloudId: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchComputeTypeListLoading());
    try {
      const response = await AxiosInstance.get(
        `api/sdv/compute_config/list_compute_type/${cloudId}`
      );
      if (response.status === 200) {
        const data = response?.data?.document;
        dispatch(fetchComputeTypeListSuccess(data));
      }
    } catch (error: any) {
      dispatch(fetchComputeTypeListError(error.message));
    }
  };
