import { Box } from "@mui/material";
import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  clearFileSystemList,
  FileSystemListType,
} from "../../redux/slices/sdvslices/FileSystemListSlice";
import { SdvCloudListType } from "../../redux/slices/sdvslices/SdvCloudListSlice";
import { addCloudPlatform } from "../../redux/slices/sdvslices/SelectedCloudPlatformSlice";
import {
  addFileSystem,
  removeFileSystem,
} from "../../redux/slices/sdvslices/SelectedFileSystemSlice";
import { RootState } from "../../redux/store/Store";
import { getFileSystemList } from "../../redux/thunks/sdv/FileSystemListThunk";
import { getCloudList } from "../../redux/thunks/sdv/SdvCloudListThunk";
import { AppDataCards } from "../../Shared/Components/AppDataCards";
import AppLoader from "../../Shared/Components/AppLoader";

import "../../styles/FileSystem.css";

const SdvFileSystem = () => {
  const dispatch: any = useDispatch();
  const [updatedCloudList, setUpdatedCloudList] = useState<
    SdvCloudListType[] | []
  >([]);

  const [updatedFileSystemList, setUpdatedFileSystemList] = useState<
    FileSystemListType[]
  >([]);

  const selectedSystemType = useSelector(
    (state: RootState) =>
      state.Sdv.SelectedSourceSystemTypeReducer.data[0]?.value
  );

  const { data: cloudPlatformDataList, loading: cloudLoading } = useSelector(
    (state: RootState) => state.Sdv.SdvCloudListReducer
  );

  const { data: fileSystemDataList, loading: fileSystemLoading } = useSelector(
    (state: RootState) => state.Sdv.FileSystemReducer
  );

  const selectedCloudId = useSelector(
    (state: RootState) => state.Sdv.SelectedCloudPlatformReducer?.data[0]?.id
  );

  const selectedFileSystemId = useSelector(
    (state: RootState) => state.Sdv.SelectedFileSystemReducer?.data[0]?.id
  );

  useEffect(() => {
    if (selectedCloudId) {
      const manipulatedList = cloudPlatformDataList?.map((item) => {
        if (item.id === selectedCloudId) return { ...item, isSelected: true };
        else {
          return { ...item, isSelected: false };
        }
      });
      setUpdatedCloudList(manipulatedList);
    } else {
      const manipulatedList = cloudPlatformDataList?.map((item) => {
        return { ...item, isSelected: false };
      });
      setUpdatedCloudList(manipulatedList);
    }
  }, [cloudPlatformDataList, selectedCloudId]);

  useEffect(() => {
    if (selectedSystemType) dispatch(getCloudList(selectedSystemType));
  }, [dispatch, selectedSystemType]);

  useEffect(() => {
    if (selectedCloudId && selectedSystemType)
      dispatch(getFileSystemList(selectedSystemType, selectedCloudId));
    else dispatch(clearFileSystemList());
  }, [dispatch, selectedCloudId, selectedSystemType]);

  const updateSelectedCloud = (ID: string) => {
    const selected = updatedCloudList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedCloudList(selected);
    dispatch(removeFileSystem());
    dispatch(addCloudPlatform(selected));
  };

  useEffect(() => {
    if (selectedFileSystemId) {
      const manipulatedList = fileSystemDataList?.map((item) => {
        if (item.id === selectedFileSystemId)
          return { ...item, isSelected: true };
        else {
          return { ...item, isSelected: false };
        }
      });
      setUpdatedFileSystemList(manipulatedList);
    } else {
      const manipulatedList = fileSystemDataList?.map((item) => {
        return { ...item, isSelected: false };
      });
      setUpdatedFileSystemList(manipulatedList);
    }
  }, [fileSystemDataList, selectedFileSystemId]);

  const updateSelectedFileSystem = (ID: string) => {
    const selected = updatedFileSystemList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedFileSystemList(selected);
    dispatch(addFileSystem(selected));
  };

  return (
    <div>
      <Box className="sdvAppdataCards">
        {cloudLoading ? (
          <AppLoader showBackDrop={false} />
        ) : (
          updatedCloudList.map((data, index) => {
            return (
              <AppDataCards
                key={index}
                dataItem={data}
                icon="target"
                handleClick={updateSelectedCloud}
              />
            );
          })
        )}
      </Box>
      {updatedFileSystemList.length !== 0 && (
        <p className="sdvFileSystemTextDesc">Select your file system</p>
      )}

      <Box className="sdvAppdataCards">
        {fileSystemLoading ? (
          <AppLoader showBackDrop={false} />
        ) : (
          updatedFileSystemList.map((data, index) => {
            return (
              <AppDataCards
                key={index}
                dataItem={data}
                handleClick={updateSelectedFileSystem}
              />
            );
          })
        )}
      </Box>
    </div>
  );
};
export default SdvFileSystem;
