import React, { useState } from "react";
import { Button, IconButton, Link } from "@mui/material";
import OcDatagrid from "./OcDatagrid";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import AxiosInstance from "../../core/AxiosInterceptor";
import { useDispatch, useSelector } from "react-redux";
import { postObjectExecute } from "../../redux/thunks/sdv/ObjectConfigExecuteThunk";
import { RootState } from "../../redux/store/Store";
import AppLoader from "../../Shared/Components/AppLoader";
import { updateRowData } from "../../redux/slices/sdvslices/ObjectConfigExecuteSlice";

import "../../styles/ObjectConfiguration.css";

interface PropType {
  tabChangeFun: (x: number) => void;
}

const ObjectConfiguration = ({ tabChangeFun }: PropType) => {
  const [gridState, setGridState] = useState<any>([]);
  const [dataGridView, setDataGridView] = useState(false);
  const [success, setsuccess] = useState({ id: 0, fileName: "" });
  const dispatch: any = useDispatch();

  const downloadTemplate = () => {
    try {
      AxiosInstance({
        url: "api/sdv/s3/download/template",
        method: "POST",
        responseType: "blob",
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "objectconfig_template.csv");
        document.body.appendChild(link);
        link.click();
      });
    } catch (error: any) {}
  };

  const uploadImage = async (files: any) => {
    var formData = new FormData();
    formData.append("Filename", files);
    try {
      const response = await AxiosInstance.post(
        `api/sdv/s3/upload/${files.name}`,
        formData
      );
      setsuccess({ id: response.status, fileName: files.name });
    } catch (error: any) {}
  };
  const fnTabChange = () => {
    dispatch(updateRowData(gridState));
    tabChangeFun(2);
  };

  const { loading: objectGridLoading } = useSelector(
    (state: RootState) => state.Sdv.ObjectConfigExecuteReducer
  );

  const handleExecute = () => {
    if (success.fileName && success.fileName !== undefined) {
      dispatch(postObjectExecute(success.fileName));
    }

    setDataGridView(true);
  };

  return (
    <div className="ocContainer">
      <h2 className="ocTitle">Object configuration </h2>
      <p className="ocDesc">
        In this step must upload your <b> Source and Target object mapping,</b>
        in case you don’t have a template please download it before to upload.
        Finally you can <b>Execute</b> in order Json file could be created.
      </p>
      <div className="ocBoxContainer">
        <h3 className="ocBoxContainerTitle">
          Source and Target object mapping
        </h3>

        <div className="ocBoxLinkContainer">
          <Link
            onClick={downloadTemplate}
            underline="none"
            className="ocBoxLinkContainer"
          >
            <img src="/assets/images/icon_download.svg" alt="downloadIcon" />
            download template
          </Link>

          <Link underline="none" className="ocBoxLinkContainer">
            <IconButton component="label" disableRipple disableFocusRipple>
              <input
                hidden
                accept="image/*"
                multiple
                onChange={(event: any) => uploadImage(event.target.files[0])}
                type="file"
              />
              <img src="/assets/images/icon_upload.svg" alt="doneIcon" />
              <div className="ocUploadLink"> upload file</div>
            </IconButton>
          </Link>
        </div>
        {success.id === 200 ? (
          <div className="ocProgressSucess">
            <img
              src="/assets/images/icon_done.svg"
              alt="doneicon"
              title="doneicon"
              style={{ verticalAlign: "middle" }}
            />
            {success.fileName} uploaded.
          </div>
        ) : (
          ""
        )}
      </div>
      <div className="ocExecBtnContainer">
        <Button
          className="ocExecBtn"
          variant="contained"
          size="small"
          onClick={handleExecute}
          disabled={!success.fileName}
        >
          Execute
        </Button>
      </div>
      {dataGridView && (
        <div>
          <h2 className="ocTitle">Object configuration table</h2>
          <p className="ocDesc">
            The following table shows the results after execution, if required,
            you can edit the fields and save to continue.{" "}
          </p>
          {objectGridLoading ? (
            <AppLoader showBackDrop={false} />
          ) : (
            <OcDatagrid gridState={gridState} setGridState={setGridState} />
          )}

          <div className="btnDatagridContainer">
            <span className="OcDatagridWarning">
              <InfoOutlinedIcon />
              &nbsp; Changes were found in the table information, click save to
              confirm
            </span>
            <Button variant="contained" size="small" onClick={fnTabChange}>
              Save and Proceed
            </Button>
          </div>
        </div>
      )}
    </div>
  );
};

export default ObjectConfiguration;
