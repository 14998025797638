import AxiosInstance from "../../../core/AxiosInterceptor";
import { fetchValidationTableListLoading, fetchValidationTableListSuccess, fetchValidationTableListError } from "../../slices/sdvslices/ValidationTableSlice";
import { AppThunkDispatch } from "../../store/Store";


export const getValidationTableList = ( ) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch(fetchValidationTableListLoading());
    try {
      const response = await AxiosInstance.get(
        `api/sdv/compute_config/validation_table`
      );
      const data = response?.data;
      
      dispatch(fetchValidationTableListSuccess(data));
    } catch (error: any) {
      dispatch(fetchValidationTableListError(error.message));
    }
  };