import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SourceSystemTypeDataType {
  id: string;
  name: string;
  icon: string;
  value: string;
  isSelected?: boolean;
}

export interface SelectedSourceSystemTypeState {
  data: SourceSystemTypeDataType[];
}

const initialState: SelectedSourceSystemTypeState = {
  data: [],
};

const SelectedSourceSystemTypeSlice = createSlice({
  name: "SelectedSourceSystemTypeSlice",
  initialState,
  reducers: {
    addSourceSystemType: (state, action: PayloadAction<any>) => {
      const selectedData = action.payload.filter(
        (item: SourceSystemTypeDataType) => item?.isSelected
      );
      state.data = selectedData;
    },
    removeSelectedSourceSystemType: (state) => {
      state.data = [];
    },
  },
});

export const { addSourceSystemType, removeSelectedSourceSystemType } =
  SelectedSourceSystemTypeSlice.actions;
export default SelectedSourceSystemTypeSlice.reducer;
