import { Button } from "@mui/material";
import { Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import AxiosInstance from "../../core/AxiosInterceptor";
import { removeDataSource } from "../../redux/slices/SelectedDataSourceSlice";
import { RootState } from "../../redux/store/Store";
import "../../styles/ConvertResults.css";

function ConvertResults() {
  const dispatch: Dispatch<any> = useDispatch();
  const selectedDataSourceList = useSelector(
    (state: RootState) => state.Common.SelectedDataSourceReducer
  );
  useEffect(() => {
    dispatch(removeDataSource());
  }, [dispatch]);

  const downloadResult = () => {
    try {
      AxiosInstance({
        url: "api/pulsesdm/S3/blob/data-migration-assets/report.xlsx",
        method: "POST",
        responseType: "blob",
        params: {
          location:
            "convert-and-migrate/code-convert/Snowflake/Convert_package/Report/",
        },
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", "report.xlsx");
        document.body.appendChild(link);
        link.click();
      });
    } catch (error: any) {}
  };
  return (
    <div>
      <p className="convertResultsDesc">
        Once you have executed your license, Code files are ready to use, in
        case do you need to download xls file will be automatically saved in
        your Download folder.
      </p>
      <Button variant="text" size="medium" onClick={downloadResult}>
        <img src="/assets/images/icon_download.svg" alt="download" />
        Download Code
      </Button>
    </div>
  );
}

export default ConvertResults;
