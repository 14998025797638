import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface SchemaDependencyType {
  id:number;
  label:String;
  group:String;

}
export interface SchemaDependencyState {
  data: [];
  loading: boolean;
  error: string|null;
}

const initialState: SchemaDependencyState = {
  data: [],
  loading: false,
  error: null,
};

const SchemaDependencySlice = createSlice({
  name: "schemadependencyslice",
  initialState,
  reducers: {
    fetchSchemaDependencyLoading: (state) => {
      state.loading = true;
    },
    fetchSchemaDependencySuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
      
    },
    fetchSchemaDependencyError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
  },
});

export const {
  fetchSchemaDependencyLoading,
  fetchSchemaDependencySuccess,
  fetchSchemaDependencyError,
} = SchemaDependencySlice.actions;

export default SchemaDependencySlice.reducer;
