import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import Encrypt from "../../../core/Encrypt";
import { RootState } from "../../../redux/store/Store";
import "../../../styles/SdvModal.css";
interface SdvModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  handleSave?: (payload: string) => void;
  handleEditSave?: (dbid: string, secretid: string, payload: string) => void;
  dbId?: string;
  secretId?: string;
  isEdit?: boolean;
}

export interface AwsFormState {
  ConfigurationTitle: string;
  bucketName: string;
  accessId: string;
  secretKey: string;
  fileformat: string;
  Delimiter: string;
}

const AwsModal: React.FC<SdvModalProps> = ({
  open,
  onClose,
  title,
  secretId,
  isEdit,
  handleSave,
  handleEditSave,
  dbId,
}) => {
  const initialFormValue: AwsFormState = {
    ConfigurationTitle: "",
    bucketName: "",
    accessId: "",
    secretKey: "",
    fileformat: "",
    Delimiter: "",
  };
  const [formData, setFormData] = useState<AwsFormState>(initialFormValue);
  const allConfigurationListsdv = useSelector(
    (state: RootState) => state.Sdv.ConfigurationDetailsSlice
  );

  const selectedConfig = allConfigurationListsdv?.data?.filter(
    (item) => Object.keys(item)[0] === secretId
  )[0];

  useEffect(() => {
    if (isEdit && secretId && selectedConfig) {
      setFormData({
        ConfigurationTitle:
          selectedConfig[secretId]?.ConfigurationTitle?.toString(),
        bucketName: selectedConfig[secretId]?.bucketName?.toString(),
        accessId: selectedConfig[secretId]?.accessId?.toString(),
        secretKey: selectedConfig[secretId]?.secretKey?.toString(),
        fileformat: selectedConfig[secretId]?.fileformat?.toString(),
        Delimiter: selectedConfig[secretId]?.Delimiter?.toString(),
      });
    }
  }, [isEdit, secretId, selectedConfig]);

  const handleSaveConfigClick = () => {
    if (!isEdit) {
      handleSave && handleSave(Encrypt(formData));
    } else {
      if (dbId && secretId && formData) {
        handleEditSave && handleEditSave(dbId, secretId, Encrypt(formData));
      }
    }
    onClose();
  };

  const regex = /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/;
  const validationCheck = () => {
    if (!regex.test(formData?.ConfigurationTitle)) return true;
    return false;
  };

  const hanldeFormInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const DelimiterOptions = [",", "|", ";"];
  return (
    <Dialog open={open} onClose={onClose}>
      <div>
        <Box className="SdvModalTitleContainer">
          <div className="SdvModalTitle">{title}</div>
          <IconButton onClick={onClose}>
            <CloseIcon className="SdvModalTitleClose" />
          </IconButton>
        </Box>
        <div className="sdvModalDesc">
          Enter the parameter configuration data. Title is mandatory field.
        </div>
        <div
          style={{
            display: "grid",
            gridTemplateColumns: "12fr",
            padding: "0 2rem",
          }}
        >
          <label>Configuration Title</label>
          <TextField
            required
            placeholder="Configuration Title"
            fullWidth
            name="ConfigurationTitle"
            value={formData.ConfigurationTitle}
            onChange={hanldeFormInputChange}
          />
        </div>
        <div className="AzureBlobAdlsContainer" style={{}}>
          <div>
            <label>bucketName</label>
            <TextField
              required
              placeholder="bucketName"
              fullWidth
              name="bucketName"
              value={formData.bucketName}
              onChange={hanldeFormInputChange}
            />
          </div>
          <div>
            <label>accessId</label>
            <TextField
              required
              placeholder="accessId"
              fullWidth
              name="accessId"
              value={formData.accessId}
              onChange={hanldeFormInputChange}
            />
          </div>
          <div>
            <label>secretKey"</label>
            <TextField
              required
              placeholder="secretKey"
              fullWidth
              name="secretKey"
              value={formData.secretKey}
              onChange={hanldeFormInputChange}
            />
          </div>
          <div>
            <label>fileformat</label>
            <TextField
              required
              placeholder="fileformat"
              fullWidth
              name="fileformat"
              value={formData.fileformat}
              onChange={hanldeFormInputChange}
            />
          </div>
          <div>
            <label>delimiter</label>
            <TextField
              InputLabelProps={{ shrink: false }}
              fullWidth
              select
              name="Delimiter"
              value={formData.Delimiter}
              onChange={hanldeFormInputChange}
            >
              {DelimiterOptions.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
        </div>
      </div>
      <DialogActions>
        <div className="actionContainer">
          <Button
            variant="outlined"
            size="small"
            onClick={onClose}
            sx={{ fontSize: "1.125rem" }}
          >
            Cancel
          </Button>
          <Button
            variant="contained"
            size="medium"
            onClick={handleSaveConfigClick}
            disabled={validationCheck()}
          >
            Save Configuration
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
};

export default AwsModal;
