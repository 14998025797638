import { Card } from "@mui/material";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Button from "@mui/material/Button";
import { Link } from "react-router-dom";

import "../../styles/Cards.css";

const cardStyle = {
  maxWidth: "21.875rem",
  margin: {
    xs: "auto",
    sm: "auto",
    md: "auto",
    lg: "10px",
    xl: "10px",
  },
  height: "-webkit-fill-available",
};

function Cards(props: { cardData: any }) {
  return (
    <div className="cardContainer">
      {props.cardData.map((card: any, index: any) => {
        return (
          <Card square={true} sx={cardStyle} key={index}>
            <div>
              <CardContent className="cardsCardContainer">
                <div
                  style={{ backgroundImage: `url(${card.svgUrl})` }}
                  className="cardTitle"
                >
                  {card.iconUrl && (
                    <img src={`${card.iconUrl}`} alt="Title-icon" />
                  )}
                  {card.title}
                </div>
              </CardContent>
              <CardContent className="cardContentContainer">
                <p className="cardDesc">{card.desc}</p>
              </CardContent>
            </div>
            <div className="cardActionContainer">
              <CardActions>
                <Button
                  className="cardBtn"
                  size="small"
                  variant="outlined"
                  component={Link}
                  to={card.href ? card.href : "/"}
                >
                  {card.btnTitle}
                </Button>
              </CardActions>
            </div>
          </Card>
        );
      })}
    </div>
  );
}

export default Cards;
