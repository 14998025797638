import {
  fetchSourceSystemListLoading,
  fetchSourceSystemListSuccess,
  fetchSourceSystemListError,
} from "../../slices/sdvslices/SourceSystemListSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getSourceSystemList =
  (systemType: string, cloudId?: string) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch(fetchSourceSystemListLoading());
    try {
      if (cloudId) {
        const response = await AxiosInstance.get(
          `api/sdv/sdv_list_db/source_system/${systemType}?cloud_id=${cloudId}`
        );
        const data = response?.data?.document;
        dispatch(fetchSourceSystemListSuccess(data));
      } else {
        const response = await AxiosInstance.get(
          `api/sdv/sdv_list_db/source_system/${systemType}`
        );
        const data = response?.data?.document;
        dispatch(fetchSourceSystemListSuccess(data));
      }
    } catch (error: any) {
      dispatch(fetchSourceSystemListError(error.message));
    }
  };
