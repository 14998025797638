import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface FileSystemListType {
  id: string;
  name: string;
  isSelected?: boolean;
}

export interface FileSystemListState {
  data: FileSystemListType[];
  loading: boolean;
  error: string | null;
}

const initialState: FileSystemListState = {
  data: [],
  loading: false,
  error: null,
};

const FileSystemListSlice = createSlice({
  name: "filesystemlistslice",
  initialState,
  reducers: {
    fetchfileSystemListLoading: (state) => {
      state.loading = true;
    },
    fetchfileSystemListSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchfileSystemListError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    clearFileSystemList: (state) => {
      state.data = [];
    },
  },
});

export const {
  fetchfileSystemListLoading,
  fetchfileSystemListSuccess,
  fetchfileSystemListError,
  clearFileSystemList,
} = FileSystemListSlice.actions;

export default FileSystemListSlice.reducer;
