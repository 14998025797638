import React, { useState, useEffect } from "react";
import { Box } from "@mui/material";
import data from "../Shared/Utils/data/data.json";
import Cards from "../Shared/Components/Cards";
import "../styles/Home.css";
import AppLoader from "../Shared/Components/AppLoader";
import { removeDataSource } from "../redux/slices/SelectedDataSourceSlice";
import { useDispatch } from "react-redux";
import { Dispatch } from "redux";

const Home = () => {
  const [loading, setLoading] = useState(true);
  const dispatch: Dispatch<any> = useDispatch();
  useEffect(() => {
    if (loading) {
      const timerHandle = setTimeout(() => {
        setLoading(false);
        clearTimeout(timerHandle);
      }, 3000);
    }
  }, [loading]);
  useEffect(() => {
    dispatch(removeDataSource());
  }, [dispatch]);

  return (
    <>
      <div style={{ display: loading ? "block" : "none" }}>
        <AppLoader showBackDrop={true} />
      </div>

      <Box className="homeContainer">
        <Box className="homeBackgroundImage">
          <div className="homePageHeader">Welcome to Data Migration Assets</div>
          <div className="homePageHeaderDescription">
            Leverage our proven assets to accelerate data migration journey
          </div>
        </Box>
        <Box className="homePageCards">
          <Cards cardData={data} />
        </Box>
      </Box>
    </>
  );
};

export default Home;
