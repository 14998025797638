import {
  postMigrationLoading,
  postMigrationSuccess,
  postMigrationError,
} from "../../slices/sdmslices/MigrationSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const postMigration =
  (sourceId: string, platformId: string, targetId: string, bodyPayload: any) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch(postMigrationLoading());
    try {
      const response = await AxiosInstance.post(
        `api/pulsesdm/Migration/${sourceId}/${platformId}/${targetId}`,
        JSON.stringify(bodyPayload)
      );
      if (response.status === 200) {
        const data = response?.data;
        dispatch(postMigrationSuccess(data));
      }
    } catch (error: any) {
      dispatch(postMigrationError(error.message));
    }
  };
