import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ViewSourceCodeState {
  data: string;
  loading: boolean;
  error: string | null;
}

const initialState: ViewSourceCodeState = {
  data: "",
  loading: false,
  error: null,
};

const ViewSourceCodeSlice = createSlice({
  name: "viewsourcecodeslice",
  initialState,
  reducers: {
    fetchViewSourceCodeLoading: (state) => {
      state.loading = true;
    },
    fetchViewSourceCodeSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchViewSourceCodeError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = "";
    },
  },
});

export const {
  fetchViewSourceCodeLoading,
  fetchViewSourceCodeSuccess,
  fetchViewSourceCodeError,
} = ViewSourceCodeSlice.actions;

export default ViewSourceCodeSlice.reducer;
