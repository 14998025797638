import AxiosInstance from "../../../core/AxiosInterceptor";
import { AppThunkDispatch } from "../../store/Store";
import {
  InstanceTypeLoading,
  InstanceTypeSuccess,
  InstanceTypeError,
} from "../../slices/sdvslices/InstanceTypeListSlice";

export const getInstanceTypeList =
  (cloudId: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(InstanceTypeLoading());
    try {
      const response = await AxiosInstance.get(
        `api/sdv/compute_config/instance_type_offerings/${cloudId}`
      );
      if (response.status === 200) {
        const data = response?.data;
        dispatch(InstanceTypeSuccess(data));
      }
    } catch (error: any) {
      dispatch(InstanceTypeError(error.message));
    }
  };
