import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import "../../../styles/MigrationProgress.css";
import "../../../styles/MigrationHistoryTable.css";
import DoughnutChart from "../../../Shared/Components/Charts/Doughnut/DoughnutChart";

const options = {
  plugins: {
    datalabels: {
      anchor: "end" as const,
      color: "#B3B2B5" as const,
      font: { size: 20 },
      offset: 6 as const,
      align: "end" as const,
    },
  },
  cutout: 90,
  radius: "75%",
};

function MigrationProgress(props: { data: any; optionData: any }) {
  return (
    <Grid container className="borderGrid">
      <Grid>
        <div className="titleContent">{props.data.title}</div>
        <Box className="migrationProgressLegendContainer">
          <div className="legends">
            <div className="individualItems">
              <p className="dotStyle successDot"> </p>{" "}
              {props.data.legendTypeOne}
            </div>
            <div className="individualItems">
              <p className="dotStyle inprogressDot"> </p>{" "}
              {props.data.legendTypeTwo}
            </div>
            <div className="individualItems">
              <p className="dotStyle failureDot"> </p>{" "}
              {props.data.legendTypeThree}
            </div>
          </div>
        </Box>
      </Grid>
      <Grid>
        <DoughnutChart chartData={props.optionData} options={options} />
      </Grid>
    </Grid>
  );
}

export default MigrationProgress;
