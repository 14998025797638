import { Link, Button, Box } from "@mui/material";
import { useEffect, useState } from "react";
import Popups from "../../Shared/Components/AppPopups";
import "../../styles/Execution.css";
import { useNavigate } from "react-router-dom";
import { getViewSnippetData } from "../../redux/thunks/codediscovery/ViewCodeSnippetThunk";
import { Dispatch } from "redux";
import ExecutionList from "./ExecutionList";
import { useDispatch, useSelector } from "react-redux";
import { getSourceCodeList } from "../../redux/thunks/codediscovery/SourceCodeListThunk";
import { RootState } from "../../redux/store/Store";
import AppLoader from "../../Shared/Components/AppLoader";

function Execution() {
  const [open, setOpen] = useState(false);
  const [viewCodeSnippet, setViewCodeSnippet] = useState(false);
  const [snippet, setSnippet] = useState<string[]>([]);
  const dispatch: Dispatch<any> = useDispatch();

  const {
    data: viewSnippetData,
    loading: loading,
    error: error,
  } = useSelector(
    (state: RootState) => state.CodeDiscovery.ViewCodeSnippetSliceReducer
  );

  const selectedDataSource = useSelector(
    (state: RootState) => state.Common.SelectedDataSourceReducer
  );
  const { data: SourceCodeListData } = useSelector(
    (state: RootState) => state.CodeConvert.SourceCodeListReducer
  );
  const selectedDataSourceId =
    selectedDataSource.data.length === 0 ? "" : selectedDataSource.data[0].id;
  useEffect(() => {
    setSnippet([]);
    let keys: string[] = Object.keys(viewSnippetData);
    let values = Array(viewSnippetData);
    keys.map((item: any) =>
      setSnippet((prev: string[]) => [...prev, item + " : " + values[0][item]])
    );
  }, [viewSnippetData]);

  const ModalCloseFunc = (modalClose: boolean) => {
    setOpen(modalClose);
    setViewCodeSnippet(modalClose);
  };
  let navigate = useNavigate();
  const handleClick = () => {
    setOpen(true);
  };
  const handleClickForView = () => {
    setViewCodeSnippet(true);
    dispatch(getViewSnippetData(selectedDataSourceId));
  };
  const executionModalData = [
    {
      title: "Successfully executed",
      src: "/assets/images/icon_check.svg",
      desc: "",
      btns: [
        {
          btnTitle: "View results",
          btnType: "contained",
          btnfunc: () => {
            setOpen(false);
            setTimeout(() => {
              navigate("/home/discover/codediscovery/coderesult");
            }, 100);
          },
        },
      ],
    },
  ];
  const codeSnippetModalData = [
    {
      titleExe: "Code snippet",
      descExe: "bbanalyzer.exe",
      srcExe: "/assets/images/icon_delete.svg",

      descData: loading === false && snippet.length > 0 ? snippet : error,
      btns: [],
    },
  ];

  useEffect(() => {
    dispatch(getSourceCodeList(selectedDataSourceId));
  }, [dispatch, selectedDataSourceId]);

  return (
    <div>
      <div className="sourceCodeList">
        <ExecutionList selectedDataSourceId={selectedDataSourceId} />
      </div>
      <Box>
        <div className="uploadFileContainer disabledContainer ">
          <h4 className="executionCommonStyle">Upload new source code </h4>
          <br />
          <Link component="button" variant="inherit" underline="none">
            <span className="executionCommonStyle executionPurpleText">
              <img
                className=""
                src="/assets/images/icon_upload.svg"
                alt="upload"
              />
              <input type="file" id="upload" hidden />
              <label className="executionLabel" htmlFor="upload">
                Upload file
              </label>
            </span>
          </Link>
        </div>
        <br />
        <h4 className="executionIconText executionCommonStyle">
          If you need to check your Code snippet just click.
        </h4>
        <Link
          component="button"
          variant="inherit"
          underline="none"
          onClick={handleClickForView}
        >
          <span className="executionViewConfig">
            <img src="/assets/images/icon_visibility.svg" alt="view" />
            <span className="executionCommonStyle executionViewConfigText">
              View Code snippet
            </span>
          </span>
        </Link>

        <Popups
          fullData={executionModalData}
          btnOpen={open}
          btnClose={!open}
          modalClose={ModalCloseFunc}
        />
        {viewCodeSnippet && loading === false ? (
          <Popups
            fullData={codeSnippetModalData}
            btnOpen={viewCodeSnippet && loading === false}
            btnClose={!viewCodeSnippet}
            modalClose={ModalCloseFunc}
          />
        ) : (
          viewCodeSnippet && <AppLoader showBackDrop={false} />
        )}
      </Box>

      <Box className="executionExecuteBtn">
        <Button
          size="large"
          variant="contained"
          onClick={handleClick}
          disabled={SourceCodeListData.length <= 0}
        >
          <span className="executionViewConfigText">Execute</span>
          <img src="/assets/images/right-arrow.png" alt="Add" />
        </Button>
      </Box>
    </div>
  );
}
export default Execution;
