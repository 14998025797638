import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

import {
  fetchDataTargetLoading,
  fetchDataTargetSuccess,
  fetchDataTargetError,
} from "../../slices/codediscovery/ConvertTargetSlice";

export const getConvertTargetData =
  () => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchDataTargetLoading());
    try {
      const response = await AxiosInstance.get("api/pulsesdm/code_convert/get");
      const data = response?.data?.document;
      dispatch(fetchDataTargetSuccess(data));
    } catch (error: any) {
      dispatch(fetchDataTargetError(error.message));
    }
  };
