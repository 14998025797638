import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import { DoughNutProps } from "../types/ChartTypes";

const DoughnutChart = ({ chartData, options }: DoughNutProps) => {
  ChartJS.register(...registerables);
  return <Doughnut data={chartData} options={options} />;
};

export default DoughnutChart;
