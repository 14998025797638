import { Box, MenuItem, TextField, Typography } from "@mui/material";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import React from "react";
import { useSelector } from "react-redux";
import Encrypt from "../../core/Encrypt";
import { DbAllDetailsType } from "../../redux/slices/DbAllDetailsSlice";
import { RootState } from "../../redux/store/Store";
import "../../styles/DialogForm.css";

export interface ConfigurationFormState {
  ConfigurationTitle: string;
  ConnectionType: string;
  System: string;
  Port: string;
  Username: string;
  Password: string;
  Database: string;
  Source: string;
  Delimiter: string;
}

export default function DialogForm(props: {
  setToggleForm: any;
  isEdit?: boolean;
  handleSave?: (payload: string) => void;
  handleEditSave?: (dbid: string, secretid: string, payload: string) => void;
  dbId?: string;
  secretId?: string;
  title?: string;
  isSdv: boolean;
}) {
  const initialFormValue: ConfigurationFormState = {
    ConfigurationTitle: "",
    ConnectionType: "",
    System: "",
    Port: "",
    Username: "",
    Password: "",
    Database: "",
    Source: "",
    Delimiter: "",
  };
  const [open, setOpen] = React.useState(true);
  const [formData, setFormData] =
    React.useState<ConfigurationFormState>(initialFormValue);

  const hanldeFormInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const allConfigurationList = useSelector(
    (state: RootState) => state.Pulse.DbAllDetailsReducer
  );

  const allConfigurationListsdv = useSelector(
    (state: RootState) => state.Sdv.ConfigurationDetailsSlice
  );

  const handleClose = () => {
    setOpen(false);
    props.setToggleForm(false);
  };

  const handleSaveConfigClick = () => {
    if (!props.isEdit) {
      props.handleSave && props.handleSave(Encrypt(formData));
    } else {
      if (props.dbId && props.secretId && formData) {
        props.handleEditSave &&
          props.handleEditSave(props.dbId, props.secretId, Encrypt(formData));
      }
    }
    handleClose();
  };

  const handleValue = (title: string) => {
    switch (title) {
      case "System":
        return formData.System;
      case "Port":
        return formData.Port;
      case "Username":
        return formData.Username;
      case "Password":
        return formData.Password;
      case "Database":
        return formData.Database;
      case "Source":
        return formData.Source;
      default:
        return "";
    }
  };

  const selectedConfig: DbAllDetailsType = props.isSdv
    ? allConfigurationListsdv?.data.filter(
        (item) => Object.keys(item)[0] === props.secretId
      )[0]
    : allConfigurationList?.data.filter(
        (item) => Object.keys(item)[0] === props.secretId
      )[0];

  React.useEffect(() => {
    if (props.isEdit && props.secretId) {
      setFormData({
        ConfigurationTitle:
          selectedConfig[props.secretId].ConfigurationTitle.toString(),
        ConnectionType:
          selectedConfig[props.secretId].ConnectionType.toString(),
        System: selectedConfig[props.secretId].System.toString(),
        Port: selectedConfig[props.secretId].Port.toString(),
        Username: selectedConfig[props.secretId].Username.toString(),
        Password: selectedConfig[props.secretId].Password.toString(),
        Database: selectedConfig[props.secretId].Database.toString(),
        Source: selectedConfig[props.secretId].Source.toString(),
        Delimiter: selectedConfig[props.secretId].Delimiter.toString(),
      });
    }
  }, [props.isEdit, props.secretId, selectedConfig]);

  const style = {
    width: "100%",
    "& .MuiInputLabel-root": { color: "#4F4B53" }, //styles the label
    "& .MuiOutlinedInput-root": {
      "& > fieldset": { borderColor: "#B3B2B5" },
    },
    "& .MuiOutlinedInput-root:hover": {
      "& > fieldset": {
        borderColor: "#A100FF",
      },
    },
  };

  const headings = [
    "System",
    "Port",
    "Username",
    "Password",
    "Database",
    "Source",
  ];

  const ConnectionTypeOptions = ["JDBC", "ODBC"];
  const DelimiterOptions = [",", "|", ";"];

  const regex = /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/;
  const validationCheck = () => {
    if (!regex.test(formData?.ConfigurationTitle)) return true;
    return false;
  };

  return (
    <Box>
      <div>
        <Dialog
          fullWidth={true}
          maxWidth="md"
          open={open}
          onClose={handleClose}
          scroll={"paper"}
          sx={{ p: "1rem", width: "100%", height: "100%" }}
        >
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography sx={{ ml: "2rem", p: 0, pt: "2rem" }} variant="h4">
              {props.title}
            </Typography>
            <Button sx={{ mt: 4, mr: 2 }} onClick={handleClose}>
              <img src="/assets/images/icon_delete.svg" alt="" />
            </Button>
          </Box>
          <Typography sx={{ fontSize: "1.125rem", m: "1rem", ml: "2rem" }}>
            Enter the parameter configuration data. Title is mandatory field.
          </Typography>

          <DialogContent sx={{ pl: "2rem", pr: "2rem", pt: "0" }}>
            <label>Configuration Title</label>
            <TextField
              required
              placeholder="Configuration Title"
              fullWidth
              name="ConfigurationTitle"
              value={formData.ConfigurationTitle}
              onChange={hanldeFormInputChange}
            />
            <label>Connection Type</label>
            <TextField
              InputLabelProps={{ shrink: false }}
              placeholder="Select Connection Type"
              select
              sx={style}
              name="ConnectionType"
              value={formData.ConnectionType}
              onChange={hanldeFormInputChange}
            >
              {ConnectionTypeOptions.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
            <div className="forAlign">
              {headings.map((heading, index) => {
                return (
                  <div className="one" key={`key${index}`}>
                    <label>{heading}</label>
                    <TextField
                      placeholder={heading}
                      type={heading === "Password" ? "password" : "text"}
                      fullWidth
                      name={heading}
                      value={handleValue(heading)}
                      onChange={hanldeFormInputChange}
                    />
                  </div>
                );
              })}
            </div>
            <label>Delimiter</label>
            <TextField
              InputLabelProps={{ shrink: false }}
              // label="Dropdown"
              select
              sx={style}
              name="Delimiter"
              value={formData.Delimiter}
              onChange={hanldeFormInputChange}
            >
              {DelimiterOptions.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
          </DialogContent>
          <DialogActions className="configFormDialogActionsContainer">
            <div className="configFormDialogActionsBtnContainer">
              <Button
                variant="outlined"
                size="small"
                onClick={handleClose}
                sx={{ fontSize: "1.125rem" }}
              >
                Cancel
              </Button>
              <Button
                sx={{ fontSize: "1.125rem" }}
                variant="contained"
                size="small"
                onClick={handleSaveConfigClick}
                disabled={validationCheck()}
              >
                Save Configuration
              </Button>
            </div>
          </DialogActions>
        </Dialog>
      </div>
    </Box>
  );
}
