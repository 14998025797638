import {
  fetchSdvConfigLoading,
  fetchSdvConfigSuccess,
  fetchSdvConfigError,
  deleteSdvConfigLoading,
  deleteSdvConfigError,
  editSdvConfigLoading,
  editSdvConfigSuccess,
  editSdvConfigError,
  addSdvConfigLoading,
  addSdvConfigSuccess,
  addSdvConfigError,
  deleteSdvConfigSuccess,
} from "../../slices/sdvslices/ConfigurationDetailsSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";
import Decrypt from "../../../core/Decrypt";

interface IEncryptedSdvConfigDetails {
  [key: number | string]: string;
}

export const getSdvConfigDetails =
  (dbId: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchSdvConfigLoading());
    try {
      const response = await AxiosInstance.get(
        `api/sdv/configuration/all_secret/${dbId}`
      );
      const data = response?.data;
      const decyptedValues = data?.map((item: IEncryptedSdvConfigDetails) => {
        for (const key in item) {
          item[key] = Decrypt(item[key]);
        }
        return item;
      });
      dispatch(fetchSdvConfigSuccess(decyptedValues));
    } catch (error: any) {
      dispatch(fetchSdvConfigError(error.message));
    }
  };

export const deleteSdvConfig =
  (dbId: string, secretId: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(deleteSdvConfigLoading());
    try {
      const response = await AxiosInstance.delete(
        `api/sdv/configuration/remove/${dbId}/${secretId}`
      );
      if (response.status === 200) {
        dispatch(deleteSdvConfigSuccess(secretId));
        dispatch(getSdvConfigDetails(dbId));
      }
    } catch (error: any) {
      dispatch(deleteSdvConfigError(error.message));
    }
  };

export const addSdvConfig =
  (dbId: string, bodyPayload: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(addSdvConfigLoading());
    try {
      const response = await AxiosInstance.post(
        `api/sdv/configuration/add/${dbId}`,
        JSON.stringify({
          configure_secret: bodyPayload,
        })
      );
      if (response.status === 200) {
        dispatch(addSdvConfigSuccess(dbId));
        dispatch(getSdvConfigDetails(dbId));
      }
    } catch (error: any) {
      dispatch(addSdvConfigError(error.message));
    }
  };

export const editSdvConfig =
  (dbId: string, secret_Id: string, bodyPayload: string) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch(editSdvConfigLoading());
    try {
      const response = await AxiosInstance.put(
        `api/sdv/configuration/update/${dbId}/${secret_Id}`,
        JSON.stringify({
          configure_secret: bodyPayload,
        })
      );
      if (response.status === 200) {
        dispatch(editSdvConfigSuccess(dbId));
        dispatch(getSdvConfigDetails(dbId));
      }
    } catch (error: any) {
      dispatch(editSdvConfigError(error.message));
    }
  };
