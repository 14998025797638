import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import TableRow from "@mui/material/TableRow";

export const checkEmptyObject = (object: Object) => {
  const keysCount = Object.keys(object).length;
  if (keysCount > 0) return false;
  return true;
};

export const StyledTableCell: any = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    color: "#7500C0",
    fontSize: 16,
    fontWeight: 600,
    borderBottom: "1px solid black",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    padding: "1rem",
    fontWeight: 600,
    paddingLeft: "1rem",
  },
}));

export const StyledTableRow: any = styled(TableRow)(({ theme }) => ({
  "&:nth-of-type(even)": {
    backgroundColor: "#EBEEF0",
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));
