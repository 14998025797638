import { useState } from "react";
import { useIdleTimer } from "react-idle-timer";

interface idleTimerProps {
  onIdle: () => void;
  idleTime: number;
}

export default function useIdle({ onIdle, idleTime }: idleTimerProps) {
  const [isIdle, setIsIdle] = useState(false);

  const handleOnIdle = () => {
    setIsIdle(true);
    onIdle();
  };

  const { getRemainingTime, getLastActiveTime, reset } = useIdleTimer({
    timeout: 1000 * 60 * idleTime,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  return {
    getRemainingTime,
    getLastActiveTime,
    isIdle,
    reset,
  };
}
