import SelectedSourceSystemTypeReducer from "../slices/sdvslices/SelectedSourceSystemTypeSlice";
import SdvCloudListReducer from "../slices/sdvslices/SdvCloudListSlice";
import SelectedCloudPlatformReducer from "../slices/sdvslices/SelectedCloudPlatformSlice";
import FileSystemReducer from "../slices/sdvslices/FileSystemListSlice";
import SelectedFileSystemReducer from "../slices/sdvslices/SelectedFileSystemSlice";
import SourceSystemReducer from "../slices/sdvslices/SourceSystemListSlice";
import TargetSystemReducer from "../slices/sdvslices/TargetSystemListSlice";
import SelectedTargetSystemReducer from "../slices/sdvslices/SelectedTargetSystemSlice";
import ValidationTableSliceReducer from "../slices/sdvslices/ValidationTableSlice";
import ConfigurationDetailsSlice from "../slices/sdvslices/ConfigurationDetailsSlice";
import TargetCloudListReducer from "../slices/sdvslices/TargetCloudListSlice";
import SelectedTargetCloudPlatformReducer from "../slices/sdvslices/SelectedTargetCloudPlatformSlice";
import SelectedSourceSystemDataReducer from "../slices/sdvslices/SelectSourceSystemDataSlice";
import ComputeTypeListReducer from "../slices/sdvslices/ComputeTypeListSlice";
import SelectedSdvSourceReducer from "../slices/sdvslices/SelectedSdvSourceCloudslice";
import SourceListReducer from "../slices/sdvslices/SdvSourceCloudListSlice";
import InstanceTypeListReducer from "../slices/sdvslices/InstanceTypeListSlice";
import ComputeConfigurationReducer from "../slices/sdvslices/ComputeConfigurationDataSlice";
import InstanceDetailsReducer from "../slices/sdvslices/InstanceDetailsSlice";
import ValidationConfigurationReducer from "../slices/sdvslices/ValidationConfigurationSlice";
import ObjectConfigExecuteReducer from "../slices/sdvslices/ObjectConfigExecuteSlice";

const Sdv = {
  SourceListReducer,
  SelectedSdvSourceReducer,
  SelectedSourceSystemTypeReducer,
  SdvCloudListReducer,
  SelectedCloudPlatformReducer,
  FileSystemReducer,
  SelectedFileSystemReducer,
  SourceSystemReducer,
  TargetSystemReducer,
  SelectedTargetSystemReducer,
  ValidationTableSliceReducer,
  ConfigurationDetailsSlice,
  TargetCloudListReducer,
  SelectedTargetCloudPlatformReducer,
  SelectedSourceSystemDataReducer,
  ComputeTypeListReducer,
  InstanceTypeListReducer,
  ComputeConfigurationReducer,
  InstanceDetailsReducer,
  validationConfiguration: ValidationConfigurationReducer,
  ObjectConfigExecuteReducer,
};

export default Sdv;
