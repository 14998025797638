import { useLocation, Outlet, Location } from "react-router-dom";
import { Typography, Box, Container, Alert } from "@mui/material";
import AppBreadCrumb from "../Shared/Components/AppBreadCrumb";
import { listDataBreadCrum as breadCrumbType } from "../Shared/Components/AppBreadCrumb";
import AppStepper from "../Shared/Components/AppStepper";
import { useEffect, useState } from "react";
import { HeadingData as subPage, IPageData } from "../Shared/Utils/HeadingData";
import ErrorBoundary from "../core/ErrorBoundary";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store/Store";

/**
 * Implementation of sub wrapper
 * @returns Wrapper to handle the child routes, stepper , breadcrumb
 */
const SubWrapper = () => {
  const location: Location = useLocation();
  const [stepper, setStepper] = useState<Array<string>>([]);
  const [breadcrumbs, setBreadCrumbs] = useState<Array<breadCrumbType>>([]);
  const [page, setPage] = useState({
    title: "title",
    subTitle: "subtitle",
    activeStep: 0,
    pageType: "discover",
  });

  useEffect(() => {
    getPageInfo(subPage, location);
    updateBreadcrumb(location);
  }, [location]);

  useEffect(() => {
    let migrationSteps: Array<string> = [
      "Migration type",
      "Data source",
      "Discovery",
      "Target platform",
      "Migration",
      "Results",
    ];
    let discoverSteps: Array<string> = [
      "Data source",
      "Metadata execution",
      "Discovery",
      "Migration wave",
    ];
    let convertSteps: Array<string> = ["Target", "Transform", "Results"];
    let CodeDiscoverSteps: Array<string> = [
      "Source code",
      "Execution",
      "Results",
    ];
    let validateSteps: Array<string> = [
      "System type",
      "Source and target",
      "Configuration",
      "Validation",
    ];

    if (page.pageType.toLowerCase() === "migrate") setStepper(migrationSteps);
    else if (page.pageType.toLowerCase() === "discover")
      setStepper(discoverSteps);
    else if (page.pageType.toLowerCase() === "convert")
      setStepper(convertSteps);
    else if (page.pageType.toLowerCase() === "codediscovery")
      setStepper(CodeDiscoverSteps);
    else if (page.pageType.toLowerCase() === "validate")
      setStepper(validateSteps);
  }, [page.pageType]);

  const updateBreadcrumb = (location: Location) => {
    const pathnames = location.pathname.split("/").filter((x) => x);
    let tempCrumbs: Array<breadCrumbType> = [];
    pathnames.forEach((value, index) => {
      const path = `/${pathnames.slice(0, index + 1).join("/")}`;
      const filteredBreadcrumb: any = subPage.find(
        (data) => data.path === path
      );
      if (filteredBreadcrumb) {
        tempCrumbs.push({
          title: filteredBreadcrumb.title,
          url: filteredBreadcrumb.linkInBreadCrumb ? path : "",
        });
      }
    });
    setBreadCrumbs(tempCrumbs);
  };

  const getPageInfo = (subPage: Array<IPageData>, location: Location) => {
    const baseLocation: string = location["pathname"];
    for (const data of subPage) {
      if (baseLocation === data?.path) {
        setPage({
          title: data?.title,
          subTitle: data?.subTitle,
          activeStep: data?.activeStep,
          pageType: data?.pageType,
        });
        return;
      }
    }
  };
  let curLocation = window.location.href;

  const selectedSystemName = useSelector(
    (state: RootState) =>
      state.Sdv.SelectedSourceSystemTypeReducer.data[0]?.name
  );

  return (
    <>
      <AppBreadCrumb listBreadCrum={breadcrumbs} />
      {curLocation.includes("migrationhistory") ? (
        <div></div>
      ) : (
        <AppStepper activeStep={page.activeStep} steps={stepper} />
      )}
      <Container
        fixed
        className="subContainer"
        maxWidth="xl"
        disableGutters
        sx={{ padding: "40px 60px" }}
      >
        <ErrorBoundary
          fallback={
            <Alert severity="error">
              Something went wrong !! Please try again later
            </Alert>
          }
        >
          <>
            {location["pathname"] ===
            "/home/validate/smartdatavalidator/sourceandtarget" ? (
              <Typography variant="h4">{selectedSystemName} source </Typography>
            ) : (
              <Typography variant="h4">{page.title} </Typography>
            )}
            <Typography variant="subtitle2">{page.subTitle} </Typography>
            <Box>
              <Outlet />
            </Box>
          </>
        </ErrorBoundary>
      </Container>
    </>
  );
};
export default SubWrapper;
