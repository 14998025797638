import React, { useEffect, useState } from "react";
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  MenuItem,
  Radio,
  RadioGroup,
  TextField,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";

import { setInputValue } from "../../redux/slices/sdvslices/ValidationConfigurationSlice";

import "../../styles/ValidateConfiguration.css";

const style = {
  minWidth: "17.688rem",
  "& .MuiInputLabel-root": { color: "#4F4B53" }, //styles the label
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: "#B3B2B5" },
  },
  "& .MuiOutlinedInput-root:hover": {
    "& > fieldset": {
      borderColor: "#A100FF",
    },
  },
};

const customplatformnamesobj = {
  spark: ["EMR", "DataProc", "DataBricks"],
  py: ["HDINSIGHT", "AWS_STANDALONE", "AZURE_STANDALONE"],
};
export interface ValidationConfigurationState {
  [key: string]: any;
  OutputDirectory: string;
  ErrorThreshold: string;
  ClusterPlatformName: string;
  IntelligentCompare: boolean;
  ColumnMapping: boolean;
  ValidationEngine: boolean;
  ObjectConfigurationType: boolean;
  ExtractionType: boolean;
  DataValidation: boolean;
  ColumnAggregationValidation: boolean;
  ValidationType: string[];
  AggregateArithmeticFunctions: string[];
}
const ValidateConfiguration = (props: any) => {
  const [validationEngine, setValidationEngine] = useState("SPARK");
  const fnTabChange = () => {
    props.tabChangeFun(1);
  };

  const initialFormValue: ValidationConfigurationState = {
    OutputDirectory: "",
    ErrorThreshold: "",
    ClusterPlatformName: "",
    IntelligentCompare: false,
    ColumnMapping: false,
    ValidationEngine: false,
    ObjectConfigurationType: false,
    ExtractionType: false,
    DataValidation: false,
    ColumnAggregationValidation: false,
    ValidationType: [],
    AggregateArithmeticFunctions: [],
  };
  const dispatch = useDispatch();
  const [inputValues, setInputValues] =
    React.useState<ValidationConfigurationState>(initialFormValue);
  const input = useSelector(
    (state: RootState) => state.Sdv.validationConfiguration
  );

  useEffect(() => {
    setInputValues(input);
  }, [inputValues]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked, type } = event.target;

    if (type === "checkbox") {
      const selectedCheckboxes = inputValues[name] || [];
      const index = selectedCheckboxes.indexOf(value);
      const newSelectedCheckboxes = [...selectedCheckboxes];

      if (checked) {
        newSelectedCheckboxes.push(value);
      } else if (index !== -1) {
        newSelectedCheckboxes.splice(index, 1);
      }

      setInputValues((prev: ValidationConfigurationState) => ({
        ...prev,
        [name]: newSelectedCheckboxes,
      }));
      dispatch(setInputValue({ name, value: newSelectedCheckboxes }));
    } else {
      setInputValues((prev: ValidationConfigurationState) => ({
        ...prev,
        [name]: value,
      }));
      dispatch(setInputValue({ name, value }));
    }
  };

  return (
    <div className="validateContainer">
      <h2 className="validateTitle">Validate configuration</h2>
      <FormControl>
        <div className="formContainer">
          <div className="ItemContainer">
            <div>
              <label>Output Directory</label>
              <TextField
                required
                placeholder="/temp"
                fullWidth
                name="OutputDirectory"
                value={inputValues.OutputDirectory}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Error Threshold</label>
              <TextField
                required
                placeholder="150"
                fullWidth
                name="ErrorThreshold"
                value={inputValues.ErrorThreshold}
                onChange={handleInputChange}
              />
            </div>
            <div>
              <label>Cluster Platform Name</label>
              <TextField
                InputLabelProps={{ shrink: false }}
                placeholder="clusterplatformname"
                select
                sx={style}
                name="ClusterPlatformName"
                value={
                  inputValues.ClusterPlatformName ||
                  customplatformnamesobj.spark[0]
                }
                onChange={handleInputChange}
              >
                {validationEngine === "SPARK"
                  ? customplatformnamesobj.spark.map((option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })
                  : customplatformnamesobj.py.map((option) => {
                      return (
                        <MenuItem key={option} value={option}>
                          {option}
                        </MenuItem>
                      );
                    })}
              </TextField>
            </div>
          </div>
          <div className="ItemContainer">
            <div>
              <label>Intelligent Compare</label>
              <RadioGroup
                name="IntelligentCompare"
                row
                value={inputValues.IntelligentCompare || "False"}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="True"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="False"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </div>
            <div>
              <label>Validation Engine</label>
              <RadioGroup
                onChange={(event) => {
                  const validationEngineVal = `${event.target.value}`;
                  setValidationEngine(validationEngineVal);

                  handleInputChange(event);
                }}
                name="ValidationEngine"
                row
                value={inputValues.ValidationEngine || "SPARK"}
              >
                <FormControlLabel
                  value="SPARK"
                  control={<Radio />}
                  label="SPARK"
                />
                <FormControlLabel value="PY" control={<Radio />} label="PY" />
              </RadioGroup>
            </div>
            <div>
              <label>Column Mapping</label>
              <RadioGroup
                name="ColumnMapping"
                row
                value={inputValues.ColumnMapping || "False"}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="True"
                  control={<Radio />}
                  label="True"
                />
                <FormControlLabel
                  value="False"
                  control={<Radio />}
                  label="False"
                />
              </RadioGroup>
            </div>
            {/* false radio default and disabled */}
            <div>
              <label>Object Configuration Type</label>
              <RadioGroup
                name="ObjectConfigurationType"
                row
                value={inputValues.ObjectConfigurationType || "json"}
                onChange={handleInputChange}
              >
                <FormControlLabel
                  value="json"
                  control={<Radio />}
                  label="JSON"
                />
                <FormControlLabel value="csv" control={<Radio />} label="CSV" />
              </RadioGroup>
            </div>
            <div>
              <label>Data Validation</label>
              <RadioGroup
                name="DataValidation"
                row
                value={inputValues.DataValidation || "Yes"}
                onChange={handleInputChange}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </div>
            <div>
              <label>Column Aggregation Validation</label>
              <RadioGroup
                name="ColumnAggregationValidation"
                onChange={handleInputChange}
                row
                value={inputValues.ColumnAggregationValidation || "Yes"}
              >
                <FormControlLabel value="Yes" control={<Radio />} label="Yes" />
                <FormControlLabel value="No" control={<Radio />} label="No" />
              </RadioGroup>
            </div>
            <div>
              {validationEngine === "PY" && (
                <>
                  <label>Extraction Type</label>
                  <RadioGroup
                    name="ExtractionType"
                    row
                    value={inputValues.ExtractionType || "RAW"}
                    onChange={handleInputChange}
                  >
                    <FormControlLabel
                      value="RAW"
                      control={<Radio />}
                      label="RAW"
                    />
                    <FormControlLabel
                      value="MD5"
                      control={<Radio />}
                      label="MD5"
                    />
                  </RadioGroup>
                </>
              )}
            </div>
          </div>
          <div className="ItemContainer">
            <div>
              <label>Validation Type</label>
              <FormGroup row onChange={handleInputChange}>
                <FormControlLabel
                  value={"SCHEMA"}
                  control={
                    <Checkbox
                      checked={inputValues.ValidationType.includes("SCHEMA")}
                    />
                  }
                  label="SCHEMA"
                  name="ValidationType"
                />
                <FormControlLabel
                  value={"DATA"}
                  control={
                    <Checkbox
                      checked={inputValues.ValidationType.includes("DATA")}
                    />
                  }
                  label="DATA"
                  name="ValidationType"
                />
                <FormControlLabel
                  value={"SCHEMADATA"}
                  control={
                    <Checkbox
                      checked={inputValues.ValidationType.includes(
                        "SCHEMADATA"
                      )}
                    />
                  }
                  label="SCHEMADATA"
                  name="ValidationType"
                />
              </FormGroup>
            </div>
            {/* only for PY */}
            {/* dropdown with checkbox / normal check box */}
            <div>
              <label>Aggregate Arithmetic Functions</label>
              <FormGroup row onChange={handleInputChange}>
                <FormControlLabel
                  value={"MEAN"}
                  control={
                    <Checkbox
                      checked={inputValues.AggregateArithmeticFunctions.includes(
                        "MEAN"
                      )}
                    />
                  }
                  label="MEAN"
                  name="AggregateArithmeticFunctions"
                />
                <FormControlLabel
                  value={"MEDIAN"}
                  control={
                    <Checkbox
                      checked={inputValues.AggregateArithmeticFunctions.includes(
                        "MEDIAN"
                      )}
                    />
                  }
                  label="MEDIAN"
                  name="AggregateArithmeticFunctions"
                />
                <FormControlLabel
                  value={"SUM"}
                  control={
                    <Checkbox
                      checked={inputValues.AggregateArithmeticFunctions.includes(
                        "SUM"
                      )}
                    />
                  }
                  label="SUM"
                  name="AggregateArithmeticFunctions"
                />
                <FormControlLabel
                  value={"COUNT"}
                  control={
                    <Checkbox
                      checked={inputValues.AggregateArithmeticFunctions.includes(
                        "COUNT"
                      )}
                    />
                  }
                  label="COUNT"
                  name="AggregateArithmeticFunctions"
                />
                <FormControlLabel
                  value={"MIN"}
                  control={
                    <Checkbox
                      checked={inputValues.AggregateArithmeticFunctions.includes(
                        "MIN"
                      )}
                    />
                  }
                  label="MIN"
                  name="AggregateArithmeticFunctions"
                />
                <FormControlLabel
                  value={"MAX"}
                  control={
                    <Checkbox
                      checked={inputValues.AggregateArithmeticFunctions.includes(
                        "MAX"
                      )}
                    />
                  }
                  label="MAX"
                  name="AggregateArithmeticFunctions"
                />
              </FormGroup>
            </div>
          </div>
        </div>
      </FormControl>
      <div className="validateBtnContainer">
        <Button
          className="validateBtn"
          variant="contained"
          size="small"
          onClick={fnTabChange}
        >
          Save and Proceed
        </Button>
      </div>
    </div>
  );
};

export default ValidateConfiguration;
