import Box from "@mui/material/Box";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect } from "react";

function CustomDataGrid(props: any) {
  const [rowSelectionModel, setRowSelectionModel] = useState<any>([]);
  const [selectedRowsData, setSelectedRowsData] = useState<any>([]);
  let rows = props.schemaData;
  let columns = props.columns;
  let rowCheck = props.rowCheck;

  useEffect(() => {
    rowCheck(rowSelectionModel.size);
  }, [rowSelectionModel]);

  useEffect(() => {
    props.selectedRows(selectedRowsData);
  }, [selectedRowsData]);

  return (
    <Box sx={{ height: 400, width: "86%" }}>
      <DataGrid
        sx={{
          "& .MuiDataGrid-footerContainer": {
            height: "1rem",
          },
          "& .MuiDataGrid-columnHeader:nth-of-child(2n)": {
            color: "red",
          },
          "& .MuiDataGrid-cell--editable": {
            "&:hover": {
              border: "2px solid #7500c0",
              color: "black",
            },
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#FFF",
            color: "#000",
            fontSize: 16,
            fontWeight: 600,
          },
          '& div[data-rowIndex][role="row"]:nth-of-type(2n)': {
            background: "#EBEEF0",
            fontSize: 16,
            "& div": {},
          },
          "& .Mui-selected": {
            color: "black !important",
            fontSize: "1rem",
            fontWeight: "600",
            border: "1px solid grey !important",
          },
          "& .Mui-checked": {},
        }}
        rows={rows}
        rowHeight={40}
        columns={columns}
        // rowsPerPageOptions={[]}
        checkboxSelection={true}
        onSelectionModelChange={(ids) => {
          const selectedIDs = new Set(ids);
          setRowSelectionModel(selectedIDs);
          const selectedRows = rows.filter((row: any) =>
            selectedIDs.has(row.id)
          );
          setSelectedRowsData(selectedRows);
        }}
      />
    </Box>
  );
}

export default CustomDataGrid;
