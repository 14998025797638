import * as React from "react";
import { DataGrid, GridPreProcessEditCellProps } from "@mui/x-data-grid";
import CustomToolbar from "../../Shared/Components/CustomToolbar";

import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";
import "../../styles/ConfigDatagrid.css";

interface OcDatagridProps {
  gridState: any;
  setGridState: any;
}

function OcDatagrid({ gridState, setGridState }: OcDatagridProps) {
  const { data: objectGirdData } = useSelector(
    (state: RootState) => state.Sdv.ObjectConfigExecuteReducer
  );

  React.useEffect(() => {
    setGridState(objectGirdData);
  }, [objectGirdData]);

  const editFunction = (
    params: GridPreProcessEditCellProps,
    fieldName: string
  ) => {
    if (params.props.value) {
      let updatedRow = {
        ...params.row,
        [fieldName]: params.props.value,
      };
      setGridState(
        gridState.map((row: { id: number }) =>
          row.id === params.row.id ? updatedRow : row
        )
      );
    }
    return { ...params.props };
  };

  const columns = [
    {
      field: "common_ordercolumnname",
      headerName: "common_order columnname",
      width: 150,
      editable: true,
      renderCell: (params: any) => {
       return (
          <div
            style={{
              width: 145,
              height: 40,
              display: "flex",
              alignItems: "center",
              justifyContent: "left",
              paddingLeft: "0.5rem",
              fontWeight: 400,
              fontSize: "14px",
            }}
          >
            {params.value as string}
          </div>
        );
      },
    },
    {
      field: "common_whereclause",
      headerName: "common_ whereclause",
      width: 150,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "common_whereclause");
      },
    },
    {
      field: "common_rangecolumnname",
      headerName: "common_range columnname",
      width: 150,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "common_rangecolumnname");
      },
    },
    {
      field: "common_startrange",
      headerName: "common_ startrange",
      width: 150,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "common_startrange");
      },
    },
    {
      field: "common_endrange",
      headerName: "common_ endrange",
      width: 150,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "common_endrange");
      },
    },
    {
      field: "common_aggcolumns",
      headerName: "common_ aggcolumns",
      width: 150,
      editable: true,
      required: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "common_aggcolumns");
      },
    },
    {
      field: "common_excludecolumnname",
      headerName: "common_exclude columnname",
      width: 150,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "common_excludecolumnname");
      },
    },
    {
      field: "common_primarykeyindex",
      headerName: "common_primary keyindex",
      width: 150,
      editable: true,
      required: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "common_primarykeyindex");
      },
    },
    {
      field: "common_excludespecialchars",
      headerName: "common_exclude specialchars",
      width: 150,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "common_excludespecialchars");
      },
    },
    {
      field: "common_partition_column",
      headerName: "common_ partition_column",
      width: 150,
      editable: true,
      required: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "common_partition_column");
      },
    },
    {
      field: "common_data_size",
      headerName: "common_ data_size",
      width: 150,
      editable: true,
      required: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        if (params.props.value) {
          let updatedRow = {
            ...params.row,
            common_data_size: params.props.value,
          };
          setGridState(
            gridState.map((row: { id: number }) =>
              row.id === params.row.id ? updatedRow : row
            )
          );
        }
        return { ...params.props };
      },
    },
    {
      field: "firstsource_objectname",
      headerName: "firstsource_ objectname",
      width: 150,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        if (params.props.value) {
          let updatedRow = {
            ...params.row,
            firstsource_objectname: params.props.value,
          };
          setGridState(
            gridState.map((row: { id: number }) =>
              row.id === params.row.id ? updatedRow : row
            )
          );
        }
        return { ...params.props };
      },
    },
    {
      field: "firstsource_schemaname",
      headerName: "firstsource_ schemaname",
      width: 150,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "firstsource_schemaname");
      },
    },

    {
      field: "firstsource_objectfiledir",
      headerName: "firstsource_ objectfiledir",
      width: 150,
      editable: true,
      required: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "firstsource_objectfiledir");
      },
    },
    {
      field: "firstsource_objecttype",
      headerName: "firstsource_ objecttype",
      width: 150,
      editable: true,
      required: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "firstsource_objecttype");
      },
    },
    {
      field: "firstsource_objectfilename",
      headerName: "firstsource_ objectfilename",
      width: 150,
      editable: true,
      required: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "firstsource_objectfilename");
      },
    },
    {
      field: "secondsource_objectname",
      headerName: "secondsource_ objectname",
      width: 150,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "secondsource_objectname");
      },
    },
    {
      field: "secondsource_schemaname",
      headerName: "secondsource_ schemaname",
      width: 150,
      editable: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "secondsource_schemaname");
      },
    },

    {
      field: "secondsource_objectfiledir",
      headerName: "secondsource_ objectfiledir",
      width: 150,
      editable: true,
      required: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "secondsource_objectfiledir");
      },
    },

    {
      field: "secondsource_objecttype",
      headerName: "secondsource_ objecttype",
      width: 100,
      editable: true,
      required: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "secondsource_objecttype");
      },
    },

    {
      field: "secondsource_objectfilename",
      headerName: "secondsource_ objectfilename",
      width: 150,
      editable: true,
      required: true,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return editFunction(params, "secondsource_objectfilename");
      },
    },
  ];

  return (
    <div className="ocDatagridContainer">
      {/* we can use filterable = false and sortable = false to remove those operations from the related columns */}
      <DataGrid
        sx={{
          "& .MuiDataGrid-footerContainer": {
            height: "1rem",
          },
          "& .MuiDataGrid-columnHeader:nth-of-child(2n)": {
            color: "red",
          },
          "& .MuiDataGrid-cell": {
            "&:hover": {
              outline: "none",
            },
          },
          "& .MuiDataGrid-cell--editable": {
            padding:0,
            "&:hover": {
              border: "1px solid #7500c0",
              color: "black",
              background: "transparent",
            },
            "&:selected": {
              border: "1px solid #7500c0",
              color: "black",
              background: "transparent",
            },
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: "#FFF",
            color: "#000",
            fontSize: 16,
            fontWeight: 600,
          },
          '& div[data-rowIndex][role="row"]:nth-of-type(2n)': {
            background: "#EBEEF0",
            fontSize: 16,
            "& div": {},
          },
          "& .Mui-selected": {
            color: "black !important",
            fontSize: "1rem",
            fontWeight: "600",
            border: "1px solid grey !important",
          },
        }}
        components={{
          Toolbar: CustomToolbar,
          OpenFilterButtonIcon: FilterAltIcon,
        }}
        rows={objectGirdData && objectGirdData}
        columns={columns}
        hideFooter
      />
    </div>
  );
}

export default OcDatagrid;
