import { createSlice, PayloadAction } from "@reduxjs/toolkit";

const initialState: any = {
  data: [],
  loading: false,
  error: null,
};

const InstanceTypeSlice = createSlice({
  name: "instanceTypeSlice",
  initialState,
  reducers: {
    InstanceTypeLoading: (state) => {
      state.loading = true;
    },
    InstanceTypeSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    InstanceTypeError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
    removeInstanceType: (state) => {
      state.data = [];
    },
  },
});

export const {
  InstanceTypeLoading,
  InstanceTypeSuccess,
  InstanceTypeError,
  removeInstanceType,
} = InstanceTypeSlice.actions;

export default InstanceTypeSlice.reducer;
