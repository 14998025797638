import {
  fetchTargetSystemListLoading,
  fetchTargetSystemListSuccess,
  fetchTargetSystemListError,
} from "../../slices/sdvslices/TargetSystemListSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getTargetSystemList =
  (systemType: string, cloudId: string) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch(fetchTargetSystemListLoading());
    try {
      const response = await AxiosInstance.get(
        `api/sdv/sdv_list_db/target_system/${systemType}?cloud_id=${cloudId}`
      );
      const data = response?.data?.document;
      dispatch(fetchTargetSystemListSuccess(data));
    } catch (error: any) {
      dispatch(fetchTargetSystemListError(error.message));
    }
  };
