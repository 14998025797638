import { Component } from "react";
import Slider from "react-slick";
import "../styles/carousel.css";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import AppLoader from "../Shared/Components/AppLoader";

export default class Intro extends Component {
  constructor(props: any) {
    super(props);
  }
  state = { isLoading: true };

  componentDidMount = () => {
    const timerHandle = setTimeout(() => {
      this.setState({ isLoading: false });
      clearTimeout(timerHandle);
    }, 3000);
  };
  render() {
    const settings = {
      dots: true,
      infinite: true,
      autoplay: true,
      speed: 500,
      slidesToShow: 1,
    };
    return (
      <>
        <div style={{ display: this.state.isLoading ? "block" : "none" }}>
          <AppLoader showBackDrop={true} />
        </div>
        <div>
          <Slider {...settings}>
            <div className="cusSlider">
              <img
                className="carouselImg"
                alt="Experience the best way to move your legacy data applications on cloud"
                src="/assets/images/intro_1.png"
                width="100%"
                height="100%"
              />
              <div className="cusText cusTextTop">
                <Typography className="introExploreHeading">
                  Experience the best way <br /> to move your legacy data <br />
                  applications on cloud
                </Typography>
                <div className="introExploreContainer">
                  <Link className="introLinkText" to={"/home"}>
                    <div className="introExploreIcon">
                      <img
                        src="/assets/images/icon-arrow.svg"
                        alt="arrow icon"
                      />
                    </div>
                    Let’s explore
                  </Link>
                </div>
              </div>
            </div>
            <div className="cusSlider">
              <img
                className="carouselImg"
                alt="Get your data on cloud faster with minimum risks"
                src="/assets/images/intro_2.png"
                width="100%"
                height="100%"
              />
              <div className="cusText">
                <Typography className="introExploreHeading">
                  Get your data on cloud
                  <br /> faster with minimum risks
                </Typography>
                <div className="introExploreContainer">
                  <Link className="introLinkText" to={"/home"}>
                    <div className="introExploreIcon">
                      <img
                        src="/assets/images/icon-arrow.svg"
                        alt="arrow icon"
                      />
                    </div>
                    Let’s explore
                  </Link>
                </div>
              </div>
            </div>
            <div className="cusSlider">
              <img
                className="carouselImg"
                alt="End-to-end Data Migration approach from Discover, Convert, Migrate & Validate"
                src="/assets/images/intro_1.png"
                width="100%"
                height="100%"
              />
              <div className="cusText">
                <Typography className="introExploreHeading">
                  End-to-end Data Migration <br /> approach from Discover,{" "}
                  <br /> Convert, Migrate <br /> & Validate
                </Typography>
                <div className="introExploreContainer">
                  <Link className="introLinkText" to={"/home"}>
                    <div className="introExploreIcon">
                      <img
                        src="/assets/images/icon-arrow.svg"
                        alt="arrow icon"
                      />
                    </div>
                    Let’s explore
                  </Link>
                </div>
              </div>
            </div>
            <div className="cusSlider">
              <img
                className="carouselImg"
                alt="Cutting-edge assets to migrate at scale to unleash quicker outcomes"
                src="/assets/images/intro_4.png"
                width="100%"
                height="100%"
              />
              <div className="cusText cusTextTop">
                <Typography className="introExploreHeading">
                  Cutting-edge assets to <br /> migrate at scale to unleash
                  <br /> quicker outcomes
                </Typography>
                <div className="introExploreContainer">
                  <Link className="introLinkText" to={"/home"}>
                    <div className="introExploreIcon">
                      <img
                        src="/assets/images/icon-arrow.svg"
                        alt="arrow icon"
                      />
                    </div>
                    Let’s explore
                  </Link>
                </div>
              </div>
            </div>
          </Slider>
        </div>
      </>
    );
  }
}
