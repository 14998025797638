const CryptoJS = require("crypto-js");

const Decrypt = (encrypted_key_string: string) => {
  const REACT_APP_AUTH_KEY = process.env.REACT_APP_AUTH_KEY;
  let decrypted = CryptoJS.AES.decrypt(
    encrypted_key_string,
    REACT_APP_AUTH_KEY
  );
  decrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));
  return decrypted;
};
export default Decrypt;
