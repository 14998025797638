import React from "react";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogProps,
  DialogTitle,
  Typography,
  Divider,
  Link,
} from "@mui/material";
import Privacy from "../Privacy";
import TermsOfUse from "../TermsOfUse";
import "../../styles/footer.css";

const AppFooter = () => {
  const [showTitle, setShowTitle] = React.useState("Privacy statement");
  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState<DialogProps["scroll"]>("paper");
  const date = new Date().getFullYear();
  const showContent =
    (scrollType: DialogProps["scroll"], type: string) => () => {
      setOpen(true);

      if (type.toLowerCase() === "privacy") {
        setShowTitle("Privacy Statement");
      } else if (type.toLowerCase() === "terms") {
        setShowTitle("Terms of Use");
      }
      setScroll(scrollType);
    };
  const closeDialog = () => {
    setOpen(false);
  };
  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);
  return (
    <>
      <Box className="footer">
        <Box className="footerContainer">
          <Typography variant="body2" color="black" className="footerContent">
            Copyright 2001-{date} Accenture. All rights reserved. Accenture
            Confidential. For internal use only.
          </Typography>
          <Box className="footerContent">
            <Link
              component="button"
              underline="none"
              onClick={showContent("paper", "privacy")}
            >
              Privacy Policy
            </Link>
            <Divider
              orientation="vertical"
              flexItem
              className="footerDivider"
            />
            <Link
              component="button"
              underline="none"
              onClick={showContent("paper", "terms")}
            >
              Terms of Use
            </Link>
          </Box>
        </Box>
        <Box className="AppFooterDisclaimer">
          This site is best viewed with a screen size of 1920 X 1080 pixels.
        </Box>

        <Dialog
          className="AppFooterDialogBox"
          open={open}
          onClose={closeDialog}
          scroll={scroll}
          aria-labelledby="scroll-dialog-title"
          aria-describedby="scroll-dialog-description"
        >
          <DialogTitle>{showTitle}</DialogTitle>
          <DialogContent
            className="AppFooterDialogBox AppFooterDialogContainer"
            dividers={scroll === "paper"}
          >
            <DialogContentText>
              {showTitle === "Privacy Statement" ? <Privacy /> : <TermsOfUse />}
            </DialogContentText>
          </DialogContent>
          <DialogActions className="AppFooterDialogActions">
            <Box className="AppFooterCloseActions" onClick={closeDialog}>
              Close
            </Box>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};
export { AppFooter };
