import { AppThunkDispatch } from "../store/Store";
import AxiosInstance from "../../core/AxiosInterceptor";
import {
  migrationWaveLoading,
  migrationWaveSuccess,
  migrationWaveError,
} from "../slices/MigrationWaveSlice";

export const getMigrationWave = () => async (dispatch: AppThunkDispatch) => {
  dispatch(migrationWaveLoading());
  try {
    const response = await AxiosInstance.get(
      "api/pulsesdm/Migration/migration_wave"
    );

    const data = response?.data;

    dispatch(migrationWaveSuccess(data));
  } catch (error: any) {
    dispatch(migrationWaveError(error.message));
  }
};
