import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CodeDiscoveryDbListType {
  id: string;
  name: string;
  isSelected?: boolean;
}

export interface CodeDiscoveryDbListState {
  data: CodeDiscoveryDbListType[];
  loading: boolean;
  error: string | null;
}

const initialState: CodeDiscoveryDbListState = {
  data: [],
  loading: false,
  error: null,
};

const CodeDiscoveryDbListSlice = createSlice({
  name: "codediscoverydblistslice",
  initialState,
  reducers: {
    fetchDbListLoading: (state) => {
      state.loading = true;
    },
    fetchDbListSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchDbListError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
  },
});

export const { fetchDbListLoading, fetchDbListSuccess, fetchDbListError } =
  CodeDiscoveryDbListSlice.actions;

export default CodeDiscoveryDbListSlice.reducer;
