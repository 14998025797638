import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SdvCloudListType } from "./SdvCloudListSlice";

export interface SelectedCloudPlatformState {
  data: SdvCloudListType[];
}

const initialState: SelectedCloudPlatformState = {
  data: [],
};

const SelectedCloudPlatformSlice = createSlice({
  name: "selectedcloudplatformslice",
  initialState,
  reducers: {
    addCloudPlatform: (state, action: PayloadAction<any>) => {
      const selectedData = action.payload.filter(
        (item: SdvCloudListType) => item?.isSelected
      );
      state.data = selectedData;
    },
    removeCloudPlatform: (state) => {
      state.data = [];
    },
  },
});

export const { addCloudPlatform, removeCloudPlatform } =
  SelectedCloudPlatformSlice.actions;
export default SelectedCloudPlatformSlice.reducer;
