import {
  migrationHistoryLoading,
  migrationHistorySuccess,
  migrationHistoryError,
} from "../../slices/sdmslices/MigrationHistorySlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getMigrationHistory = () => async (dispatch: AppThunkDispatch) => {
  dispatch(migrationHistoryLoading());
  try {
    const response = await AxiosInstance.get(
      `api/pulsesdm/Migration/migration_history`
    );
    if (response.status === 200) {
      const data = response?.data;
      dispatch(migrationHistorySuccess(data));
    }
  } catch (error: any) {
    dispatch(migrationHistoryError(error.message));
  }
};
