import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";
import {
  viewSnippetLoading,
  viewSnippetData,
  viewSnippetError,
} from "../../slices/codediscovery/ViewCodeSnippetSlice";

export const getViewSnippetData =
  (sourceList: any) => async (dispatch: AppThunkDispatch) => {
    dispatch(viewSnippetLoading());
    try {
      const response = await AxiosInstance.get(
        `api/pulsesdm/code_discovery/code_snippet/${sourceList}`
      );

      const data = response?.data;

      dispatch(viewSnippetData(data));
    } catch (error: any) {
      dispatch(viewSnippetError(error.message));
    }
  };
export { viewSnippetError, viewSnippetLoading };
