import {
  fetchObjectDependencyLoading,
  fetchObjectDependencySuccess,
  fetchObjectDependencyError,
} from "../../slices/discoveryoverview/ObjectDependencySlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getObjectDependency =
  (schema: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchObjectDependencyLoading());
    try {
      const response = await AxiosInstance.get(
        `api/pulsesdm/Discovery_overview/get/objectDependency/${schema}`
      );
      const data = response?.data;
      dispatch(fetchObjectDependencySuccess(data));
    } catch (error: any) {
      dispatch(fetchObjectDependencyError(error.message));
    }
  };
