import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TargetDatabaseType {
  id: string;
  name: string;
  isSelected?: boolean;
}

export interface TargetDatabaseState {
  data: TargetDatabaseType[];
  loading: boolean;
  error: string | null;
}

const initialState: TargetDatabaseState = {
  data: [],
  loading: false,
  error: null,
};

const TargetDatabaseSlice = createSlice({
  name: "targetdatabaseslice",
  initialState,
  reducers: {
    fetchTargetDatabaseLoading: (state) => {
      state.loading = true;
    },
    fetchTargetDatbaseSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchTargetDatabaseError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    clearTargetDatabase: (state) => {
      state.data = [];
    },
  },
});

export const {
  fetchTargetDatabaseLoading,
  fetchTargetDatbaseSuccess,
  fetchTargetDatabaseError,
  clearTargetDatabase,
} = TargetDatabaseSlice.actions;

export default TargetDatabaseSlice.reducer;
