import { createSlice, PayloadAction } from "@reduxjs/toolkit";
export interface DataValuesType {
  database: string;
  size_in_gb: number;
  application: string;
  table_count: number;
  Applications: number;
  Databases: number;
  Tables: number;
}
export interface MigrationWaveDataSummary {
  Applications: number;
  Databases: number;
  Tables: number;
}

export interface MigrationWaveDataType {
  [key: string]: DataValuesType[];
}
export interface MigrationWaveDataTypeSummary {
  [key: string]: MigrationWaveDataSummary;
}

export interface MigrationWave {
  migration_wave_details: MigrationWaveDataType;
  migration_wave_summary: MigrationWaveDataTypeSummary;
}

export interface MigrationWaveState {
  data: MigrationWave | undefined;
  loading: boolean;
  error: string | null;
}

const initialState: MigrationWaveState = {
  data: undefined,
  loading: false,
  error: null,
};

const MigrationWaveSlice = createSlice({
  name: "migrationwaveslice",
  initialState,
  reducers: {
    migrationWaveLoading: (state) => {
      state.loading = true;
    },
    migrationWaveSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    migrationWaveError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
  },
});

export const {
  migrationWaveLoading,
  migrationWaveSuccess,
  migrationWaveError,
} = MigrationWaveSlice.actions;

export default MigrationWaveSlice.reducer;
