import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SourceSystemListType {
  id: string;
  name: string;
  isSelected?: boolean;
}

export interface SourceSystemListState {
  data: SourceSystemListType[];
  loading: boolean;
  error: string | null;
}

const initialState: SourceSystemListState = {
  data: [],
  loading: false,
  error: null,
};

const SourceSystemListSlice = createSlice({
  name: "sourcesystemlistslice",
  initialState,
  reducers: {
    fetchSourceSystemListLoading: (state) => {
      state.loading = true;
    },
    fetchSourceSystemListSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchSourceSystemListError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    removeSourceSystemList: (state) => {
      state.data = [];
    },
  },
});

export const {
  fetchSourceSystemListLoading,
  fetchSourceSystemListSuccess,
  fetchSourceSystemListError,
  removeSourceSystemList,
} = SourceSystemListSlice.actions;

export default SourceSystemListSlice.reducer;
