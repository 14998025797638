import {
  fetchSchemaQueryCountError,
  fetchSchemaQueryCountLoading,
  fetchSchemaQueryCountSuccess,
} from "../../slices/discoveryoverview/SchemaQueryCountSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getSchemaQueryCount = () => async (dispatch: AppThunkDispatch) => {
  dispatch(fetchSchemaQueryCountLoading());
  try {
    const response = await AxiosInstance.get(
      `api/pulsesdm/Discovery_overview/get/queryCount`
    );
    const data = response?.data;
    dispatch(fetchSchemaQueryCountSuccess(data));
  } catch (error: any) {
    dispatch(fetchSchemaQueryCountError(error.message));
  }
};
