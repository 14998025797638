import { Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";
import { getViewSourceCode } from "../../redux/thunks/codediscovery/ViewSourceCodeThunk";
import AppLoader from "../../Shared/Components/AppLoader";
import AppModal from "../../Shared/Components/Modals/AppModal";
import "../../styles/Execution.css";

interface ExecutionListProps {
  selectedDataSourceId: string;
}

const ExecutionList = ({ selectedDataSourceId }: ExecutionListProps) => {
  const [isSourceCodeModalOpen, setIsSourceCodeModalOpen] = useState(false);
  const dispatch: any = useDispatch();

  const { data: SourceCodeListData, loading } = useSelector(
    (state: RootState) => state.CodeConvert.SourceCodeListReducer
  );

  const { data: sourceCodeItemData, loading: sourceCodeItemLoading } =
    useSelector((state: RootState) => state.CodeConvert.ViewSourceCodeReducer);

  const handleSourceCodeModalClose = () => {
    setIsSourceCodeModalOpen(false);
  };

  const handleViewIcon = (fileName: string) => {
    dispatch(getViewSourceCode(selectedDataSourceId, fileName));
    setIsSourceCodeModalOpen(true);
  };

  const SourceCodeModalBody = (
    <div>
      <Typography>{sourceCodeItemData}</Typography>
    </div>
  );

  return (
    <>
      {loading ? (
        <AppLoader showBackDrop={false} />
      ) : (
        SourceCodeListData?.map((data: string, index: number) => {
          return (
            <div key={index} className="sourceCodeListContainer">
              <span className="sourceCodeListItem">{data}</span>
              <span className="sourceCodeListImage sourceCodeListImage img">
                <span
                  role="button"
                  className="alignCenter"
                  onClick={() => handleViewIcon(data)}
                >
                  <img src="/assets/images/view-eye.svg" alt="view" />
                </span>
                <img src="/assets/images/icon_delete.svg" alt="delete" />
              </span>
              {sourceCodeItemLoading ? (
                <AppLoader showBackDrop={true} />
              ) : (
                <AppModal
                  open={isSourceCodeModalOpen}
                  onClose={handleSourceCodeModalClose}
                  title={data}
                  body={SourceCodeModalBody}
                />
              )}
            </div>
          );
        })
      )}
    </>
  );
};

export default ExecutionList;
