import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { FileSystemListType } from "./FileSystemListSlice";

export interface SelectedFileSystemState {
  data: FileSystemListType[];
}

const initialState: SelectedFileSystemState = {
  data: [],
};

const SelectedFileSystemSlice = createSlice({
  name: "selectedfilesystemslice",
  initialState,
  reducers: {
    addFileSystem: (state, action: PayloadAction<any>) => {
      const selectedData = action.payload.filter(
        (item: FileSystemListType) => item?.isSelected
      );
      state.data = selectedData;
    },
    removeFileSystem: (state) => {
      state.data = [];
    },
  },
});

export const { addFileSystem, removeFileSystem } =
  SelectedFileSystemSlice.actions;
export default SelectedFileSystemSlice.reducer;
