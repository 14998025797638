import { Box, Button } from "@mui/material";
import React, { useState, Dispatch, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";
import { AppDataCards } from "../../Shared/Components/AppDataCards";
import AppLoader from "../../Shared/Components/AppLoader";
import DataConfig from "../DataConfig";
import { SdmDataSourceDataType } from "../../redux/slices/SdmDataSourceSlice";
import { getSdmDatasource } from "../../redux/thunks/SdmDataSourceThunk";
import {
  addDataSource,
  removeDataSource,
} from "../../redux/slices/SelectedDataSourceSlice";
import "../../styles/SdmDataSource.css";
import { useNavigate } from "react-router-dom";
const SdmDataSource = () => {
  const [isBoxSelected, setIsBoxSelected] = useState(false);
  const [showSdmDataSource, setShowSdmDataSource] = useState<boolean>(true);
  const dispatch: Dispatch<any> = useDispatch();
  const {
    data: SdmDataSourceList,
    loading,
    error,
  } = useSelector((state: RootState) => state.Sdm.SdmDataSourceReducer);
  const selectedMigrationType = useSelector(
    (state: RootState) => state.Sdm.SelectedMigrationTypeReducer.data
  );
  const [updatedSdmDataSourceList, setUpdatedSdmDataSourceList] =
    React.useState<SdmDataSourceDataType[] | []>([]);

  useEffect(() => {
    dispatch(getSdmDatasource(selectedMigrationType[0].value));
    dispatch(removeDataSource());
  }, [dispatch, selectedMigrationType]);

  useEffect(() => {
    const manipulatedList = SdmDataSourceList?.map((item) => {
      return { ...item, isSelected: false };
    });
    setUpdatedSdmDataSourceList(manipulatedList);
  }, [SdmDataSourceList]);

  const updateSelectedSdmDatasource = (ID: string) => {
    const selected = updatedSdmDataSourceList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    const isAtLeastOneSelected = selected?.some((item) => item.isSelected);
    setIsBoxSelected(isAtLeastOneSelected);
    setUpdatedSdmDataSourceList(selected);
    dispatch(addDataSource(selected));
  };

  const handleContinueClick: () => void = () => {
    setShowSdmDataSource(!showSdmDataSource);
  };

  const navigate = useNavigate();
  const BackClick = () => {
    navigate(-1);
  };

  return (
    <>
      {showSdmDataSource ? (
        <Box className="sdmDataSourceDatabaseContainer">
          <Box className="sdmDataSourceDataSource">
            {loading && <AppLoader showBackDrop={false} />}
            {updatedSdmDataSourceList?.map(
              (item: SdmDataSourceDataType, index: number) => (
                <AppDataCards
                  key={index}
                  dataItem={item}
                  handleClick={updateSelectedSdmDatasource}
                />
              )
            )}
          </Box>
          <Box className="sdmDataSourceButtonContainer">
            <Button
              className="sdmdBackSwitchBtn"
              size="large"
              variant="outlined"
              color="inherit"
              onClick={BackClick}
            >
              <img
                className="sdmBackSwitchBtnImg"
                src="/assets/images/icon_back_arrow.svg"
                alt="Add"
              />
              Back
            </Button>
            <Button
              className="sdmDataSourceBtn2"
              size="large"
              variant="contained"
              disabled={!isBoxSelected}
              onClick={handleContinueClick}
            >
              Continue
              <img
                className="sdmDataSourceBtn2Image"
                src="/assets/images/right-arrow.png"
                alt="Add"
              />
            </Button>
          </Box>
        </Box>
      ) : (
        <DataConfig
          showDataSource={showSdmDataSource}
          handleClick={handleContinueClick}
          pathName="/home/migrate/smartdatawarehousemover/discoveryoverview"
        />
      )}
    </>
  );
};
export default SdmDataSource;
