import { Button, FormControlLabel, Radio } from "@mui/material";
import Box from "@mui/material/Box";
import Paper from "@mui/material/Paper";
import { Grid } from "@mui/material";
import { Close, Edit } from "@mui/icons-material";
import { useState } from "react";
import Popups from "./AppPopups";
import { DbAllDetailsType } from "../../redux/slices/DbAllDetailsSlice";
import GcpModal from "./CloudFormModals/GcpModal";
import AzureModal from "./CloudFormModals/AzureModal";
import AwsModal from "./CloudFormModals/AwsModal";
import "../../styles/AppConfiguration.css";

export interface IConfigurationCardProps {
  indexKey?: number;
  radioProps?: Object;
  dbConfigDetails: DbAllDetailsType;
  dbId?: string;
  handleDeleteClick?: (id1: string, id2: string) => void;
  isEditable?: boolean;
  handleEdit?: (dbID: string, secretID: string, payload: string) => void;
  setIsEditable?: (flag: boolean) => void;
  title: string;
  isAws?: boolean;
  isAzure?: boolean;
  isGcp?: boolean;
}

const ConfigurationCard: React.FC<IConfigurationCardProps> = ({
  indexKey,
  radioProps,
  dbConfigDetails,
  dbId,
  handleDeleteClick,
  isEditable,
  handleEdit,
  setIsEditable,
  title,
  isAws,
  isAzure,
  isGcp,
}) => {
  const [isSourceCodeModalOpen, setIsSourceCodeModalOpen] = useState(false);
  const handleSourceCodeModalClose = () => {
    setIsSourceCodeModalOpen(false);
  };

  const [open, setOpen] = useState(false);
  const { ...RadioProps } = radioProps;
  const ModalCloseFunc = (modalClose: any) => {
    setOpen(modalClose);
  };

  const secretId = Object.keys(dbConfigDetails)[0];
  const dataBaseId = dbId ?? "";

  const data: any = [
    {
      title: "Are you sure to delete this entry ?",
      src: "/assets/images/icon_warning.svg",
      desc: "This action will delete the configuration entry permanently",
      btns: [
        {
          btnTitle: "No",
          btnType: "outlined",
          btnfunc: () => {
            setOpen(false);
          },
        },
        {
          btnTitle: "Yes",
          btnType: "contained",
          btnfunc: () => {
            handleDeleteClick && handleDeleteClick(dataBaseId, secretId);
            setOpen(false);
          },
        },
      ],
    },
  ];

  const handleCloseClick = () => {
    setOpen(true);
  };

  const handleEditClick = () => {
    setIsSourceCodeModalOpen(!isSourceCodeModalOpen);
    setIsEditable && setIsEditable(true);
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexWrap: "wrap",
          p: 0,
          m: 0,
          "& > :not(style)": {
            mt: 2,
            mb: 0,
            paddingBottom: "3rem",
            paddingLeft: "4.5rem",
            paddingTop: "2.3rem",
            paddingRight: "2rem",
            marginLeft: "1.5rem",
            width: "100%",
          },
        }}
      >
        <Paper elevation={5}>
          <Box className="AppConfigHeaderContainer">
            <FormControlLabel
              {...RadioProps}
              control={<Radio />}
              label={
                <div className="AppConfigTitle">
                  {dbConfigDetails[secretId]?.ConfigurationTitle}
                </div>
              }
              className="AppConfigLeftMargin"
            />

            <Box className="AppConfigBtnContainer">
              <Button className="AppConfigBtnStyle" onClick={handleEditClick}>
                <Edit />
              </Button>
              <Button className="AppConfigBtnStyle" onClick={handleCloseClick}>
                <Close />
              </Button>

              {isGcp ? (
                <GcpModal
                  open={isSourceCodeModalOpen}
                  onClose={handleSourceCodeModalClose}
                  title={title}
                  isEdit={isEditable || false}
                  dbId={dbId}
                  secretId={secretId}
                  handleEditSave={handleEdit}
                />
              ) : isAws ? (
                <AwsModal
                  open={isSourceCodeModalOpen}
                  onClose={handleSourceCodeModalClose}
                  title={title}
                  isEdit={isEditable || false}
                  dbId={dbId}
                  secretId={secretId}
                  handleEditSave={handleEdit}
                />
              ) : isAzure ? (
                <AzureModal
                  open={isSourceCodeModalOpen}
                  onClose={handleSourceCodeModalClose}
                  title={title}
                  isEdit={isEditable || false}
                  dbId={dbId}
                  secretId={secretId}
                  handleEditSave={handleEdit}
                />
              ) : null}
            </Box>
          </Box>
          {isAzure ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">Authentication type</div>
                <div className="AppConfigFontWeight">
                  {dbConfigDetails[secretId]?.authenticationtype}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">Account Name</div>
                <div className="AppConfigFontWeight">
                  {" "}
                  {dbConfigDetails[secretId]?.accountname}
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="AppConfigEachHead">AZ Container</div>
                <div className="AppConfigFontWeight">
                  {" "}
                  {dbConfigDetails[secretId]?.azcontainer}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">Account Key</div>
                <div className="AppConfigFontWeight">*************</div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">File Format</div>
                <div className="AppConfigFontWeight">
                  {dbConfigDetails[secretId]?.fileformat}
                </div>
              </Grid>
              <Grid item xs={4}>
                <div className="AppConfigEachHead">Is Header Present</div>
                <div className="AppConfigFontWeight">
                  {dbConfigDetails[secretId]?.IsHeaderPresent}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">Delimiter</div>
                <div className="AppConfigFontWeight">
                  {dbConfigDetails[secretId]?.Delimiter}
                </div>
              </Grid>
            </Grid>
          ) : isAws ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">Bucket Name</div>
                <div className="AppConfigFontWeight">
                  {dbConfigDetails[secretId]?.bucketName}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">Secret Key</div>
                <div className="AppConfigFontWeight">*************</div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">Access ID</div>
                <div className="AppConfigFontWeight">
                  {dbConfigDetails[secretId]?.accessId}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">File Format</div>
                <div className="AppConfigFontWeight">
                  {dbConfigDetails[secretId]?.fileformat}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">Is Quoted</div>
                <div className="AppConfigFontWeight">
                  {dbConfigDetails[secretId]?.isquoted}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">Delimiter</div>
                <div className="AppConfigFontWeight">
                  {dbConfigDetails[secretId]?.Delimiter}
                </div>
              </Grid>
            </Grid>
          ) : isGcp ? (
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">Bucket Name</div>
                <div className="AppConfigFontWeight">
                  {dbConfigDetails[secretId]?.bucketname}
                </div>
              </Grid>
              <Grid item xs={12} sm={4}>
                <div className="AppConfigEachHead">File Format</div>
                <div className="AppConfigFontWeight">
                  {dbConfigDetails[secretId]?.fileformat}
                </div>
              </Grid>
            </Grid>
          ) : null}
        </Paper>
      </Box>
      <Popups
        fullData={data}
        btnOpen={open}
        btnClose={!open}
        modalClose={ModalCloseFunc}
      />
    </>
  );
};

export default ConfigurationCard;
