import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";
import {
  fetchInstanceDetailsError,
  fetchInstanceDetailsListLoading,
  fetchInstanceDetailsSuccess,
} from "../../slices/sdvslices/InstanceDetailsSlice";

export const getInstanceDetails =
  (cloudId: string, instanceType: string) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch(fetchInstanceDetailsListLoading());
    try {
      const response = await AxiosInstance.get(
        `api/sdv/compute_config/instance_type_details/${cloudId}/${instanceType}`
      );
      const data = response?.data;
      dispatch(fetchInstanceDetailsSuccess(data));
    } catch (error: any) {
      dispatch(fetchInstanceDetailsError(error.message));
    }
  };
