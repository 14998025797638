import * as React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { PublicClientApplication } from "@azure/msal-browser";
import { MsalProvider } from "@azure/msal-react";
import { msalConfig as config } from "./core/auth/msalConfig";

const msalInstance = new PublicClientApplication(config);

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);
root.render(
  <React.StrictMode> 
    <MsalProvider instance={msalInstance}>
      <App />
    </MsalProvider>
  </React.StrictMode> 
);

reportWebVitals();
