import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TargetSystemListType {
  id: string;
  name: string;
  isSelected?: boolean;
}

export interface TargetSystemListState {
  data: TargetSystemListType[];
  loading: boolean;
  error: string | null;
}

const initialState: TargetSystemListState = {
  data: [],
  loading: false,
  error: null,
};

const TargetSystemListSlice = createSlice({
  name: "targetsystemlistslice",
  initialState,
  reducers: {
    fetchTargetSystemListLoading: (state) => {
      state.loading = true;
    },
    fetchTargetSystemListSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchTargetSystemListError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    clearTargetSystemList: (state) => {
      state.data = [];
    },
  },
});

export const {
  fetchTargetSystemListLoading,
  fetchTargetSystemListSuccess,
  fetchTargetSystemListError,
  clearTargetSystemList,
} = TargetSystemListSlice.actions;

export default TargetSystemListSlice.reducer;
