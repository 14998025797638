import {
  fetchMovementLoading,
  fetchMovementSuccess,
  fetchMovementError,
} from "../../slices/sdmslices/MovementDetailsSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getMovementDetails =
  (movementId: number) => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchMovementLoading());
    try {
      const response = await AxiosInstance.get(
        `api/pulsesdm/Migration/${movementId}`
      );
      const data = response?.data;
      dispatch(fetchMovementSuccess(data));
    } catch (error: any) {
      dispatch(fetchMovementError(error.message));
    }
  };
