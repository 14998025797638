import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SchemaBySizeType {
  DATABASE: String;
  SIZE_GB: number;
}

export interface SchemaBySizeState {
  data: SchemaBySizeType[];
  loading: boolean;
  error: string | null;
}

const initialState: SchemaBySizeState = {
  data: [],
  loading: false,
  error: null,
};

const SchemaBySizeSlice = createSlice({
  name: "schemabysizeslice",
  initialState,
  reducers: {
    fetchSchemaBySizeLoading: (state) => {
      state.loading = true;
    },
    fetchSchemaBySizeSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchSchemaBySizeError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
  },
});

export const {
  fetchSchemaBySizeLoading,
  fetchSchemaBySizeSuccess,
  fetchSchemaBySizeError,
} = SchemaBySizeSlice.actions;

export default SchemaBySizeSlice.reducer;
