import SourceCodeListReducer from "../slices/codediscovery/SourceCodeListSlice";
import ViewSourceCodeReducer from "../slices/codediscovery/ViewSourceCodeSlice";
import ConvertTargetDataList from "../slices/codediscovery/ConvertTargetSlice";
import selectedConvertTargetReducer from "../slices/SelectedConvertTargetDataSlice";

const CodeConvert = {
  SourceCodeListReducer,
  ViewSourceCodeReducer,
  ConvertTargetDataList,
  selectedConvertTargetReducer,
};

export default CodeConvert;
