import { Box, Button } from "@mui/material";
import MigrationProgress from "./MigrationProgress";
import Overview from "./Overview";
import "../../../styles/Results.css";
import ResultTable from "./ResultTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store/Store";
import { useEffect, useState } from "react";
import { MigrationDetailsType } from "../../../redux/types/MigrationTypes";
import { Link } from "react-router-dom";
import { clearMigrationWave } from "../../../redux/slices/SelectedMigrationWaveSlice";

const Results = () => {
  const [resultTableData, setResultTableData] = useState<any[] | []>([]);

  const dispatch: any = useDispatch();

  const { data: sourceName } = useSelector(
    (state: RootState) => state.Common.SelectedDataSourceReducer
  );
  const { data: platformName } = useSelector(
    (state: RootState) => state.Sdm.SelectedCloudReducer
  );
  const { data: targetName } = useSelector(
    (state: RootState) => state.Sdm.SelectedTargetDbReducer
  );

  const [jobOverviewData, setJobOverviewData] = useState<any>([]);
  const [migrationProgressData, setMigrationProgressData] = useState<
    number[] | []
  >([]);

  const { data: migrationData } = useSelector(
    (state: RootState) => state.Sdm.MigrationReducer
  );

  const resultKeys: string[] = [
    "object_name",
    "object_row_count",
    "content_match",
    "migration_status",
    "source_row_count",
    "target_row_count",
  ];

  useEffect(() => {
    if (migrationData) {
      let arr = [];
      const temp = migrationData.job_overview;
      for (const [k, v] of Object.entries(temp)) {
        if (k === "StartDate" || k === "EndDate") {
          const formattedTime = v.substring(0, v.indexOf("."));
          arr.push({ title: k, desc: formattedTime });
        } else {
          arr.push({ title: k, desc: v });
        }
      }
      setJobOverviewData(arr);
    }
  }, [migrationData]);

  interface InputObject {
    [key: string]: any;
  }

  useEffect(() => {
    if (migrationData) {
      const tempData = migrationData.migration_Details.map((item: any) => {
        const newObj: InputObject = {};
        resultKeys.forEach((key: string) => (newObj[key] = item[key]));
        return newObj;
      });
      setResultTableData(tempData);
    }
  }, [migrationData]);

  useEffect(() => {
    if (migrationData) {
      const successCount = migrationData.migration_Details.filter(
        (item: any) => item?.migration_status.toLowerCase() === "success"
      ).length;
      const failureCount = migrationData.migration_Details.filter(
        (item: any) => item?.migration_status.toLowerCase() === "failure"
      ).length;
      const inProgressCount = migrationData.migration_Details.filter(
        (item: any) => item?.migration_status.toLowerCase() === "in-progress"
      ).length;
      setMigrationProgressData([successCount, inProgressCount, failureCount]);
    }
  }, [migrationData]);

  useEffect(() => {
    dispatch(clearMigrationWave());
  }, []);

  const data = {
    datasets: [
      {
        label: "",
        data: migrationProgressData,
        borderWidth: 0.5,
        backgroundColor: ["#18AA18", "#F1984C", "#EB5757"],
      },
    ],
  };
  const schemasByUsage = {
    title: "Migration Progress",
    legendTypeOne: " Success ",
    legendTypeTwo: " In Progress ",
    legendTypeThree: " Failure ",
  };

  const titlesAndDesc = {
    heading: "Job Overview",
    data: jobOverviewData,
  };

  const source_Name = sourceName[0].name ? sourceName[0].name : "";
  const platform_Name = platformName[0].name ? platformName[0].name : "";
  const target_Name = targetName[0].name ? targetName[0].name : "";

  const detailsData = [
    {
      title: "Data Source",
      description: source_Name,
    },
    {
      title: "Cloud Platform",
      description: platform_Name,
    },
  ];

  const titleData = [
    "Table name",
    // "No of Rows",
    "No. of rows in source",
    "No. of rows in target",
    "Content match",
    "Migration Status",
  ];

  return (
    <Box className="resultsContainer">
      <div>
        {detailsData.map((details: any, index: any) => {
          return (
            <span key={index} className="resultsHeadDataContainer">
              <span className="tableHeads">{details.title}</span>
              <span className="tableDefs"> {details.description}</span>{" "}
              {index === 0 && <span className="resultsHeaderDivider"></span>}
            </span>
          );
        })}
      </div>
      <div className="resultsGridContainer">
        <div className="tableFlexCenter resultContainer">
          <Overview data={titlesAndDesc} />
        </div>
        <div className="tableFlexCenter resultContainer">
          <MigrationProgress data={schemasByUsage} optionData={data} />
        </div>
      </div>
      <div className="resultsTableContainer">
        <ResultTable data={resultTableData} titleData={titleData} />
      </div>
      <Button
        className="reslutsViewMigrationButton"
        variant="text"
        size="medium"
        component={Link}
        to={"/home/migrate/smartdatawarehousemover/migrationhistory"}
      >
        <img src="/assets/images/icon_visibility.svg" alt="" />
        View migration history
      </Button>
    </Box>
  );
};

export default Results;
