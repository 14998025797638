import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SourceListType } from "./SdvSourceCloudListSlice";

export interface SelectedSourceState {
  data: SourceListType[];
}

const initialState: SelectedSourceState = {
  data: [],
};

const SelectedSdvSourceSlice = createSlice({
  name: "selectedsdvsourceslice",
  initialState,
  reducers: {
    addSource: (state, action: PayloadAction<any>) => {
      const selectedData = action.payload.filter(
        (item: SourceListType) => item?.isSelected
      );
      state.data = selectedData;
    },
    removesource: (state) => {
      state.data = [];
    },
  },
});

export const { addSource, removesource } =
SelectedSdvSourceSlice.actions;
export default SelectedSdvSourceSlice.reducer;
