import { CssBaseline, ThemeProvider } from "@mui/material";
import { store } from "./redux/store/Store";
import { Provider } from "react-redux";
import { AppTheme } from "./Shared/Utils/AppTheme";
import { Login } from "./core/auth/Login";
import RouterWrapper from "./layout/RouterWrapper";
import {
  AuthenticatedTemplate,
  UnauthenticatedTemplate,
  useIsAuthenticated,
  useMsal,
} from "@azure/msal-react";
import {
  AuthenticationResult,
  EventMessage,
  EventType,
} from "@azure/msal-browser";

import "./App.css";
import useIdle from "./hooks/useIdleTimer";

const App = () => {
  const { instance, inProgress } = useMsal();
  const isAuthenticated = useIsAuthenticated();

  // MSAL event info, setting the active account on successful sign in
  instance.addEventCallback((event: EventMessage) => {
    if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
      const payload = event.payload as AuthenticationResult;
      const account = payload.account;
      instance.setActiveAccount(account);
    }
  });

  const hadleIdleLogout = () => {
    instance.logoutRedirect();
  };

  const { isIdle } = useIdle({ onIdle: hadleIdleLogout, idleTime: 60 });

  return (
    <Provider store={store}>
      <ThemeProvider theme={AppTheme}>
        <CssBaseline />
        <AuthenticatedTemplate>
          {inProgress ? <RouterWrapper></RouterWrapper> : null}
        </AuthenticatedTemplate>
        <UnauthenticatedTemplate>{<Login />}</UnauthenticatedTemplate>
      </ThemeProvider>
    </Provider>
  );
};
export default App;
