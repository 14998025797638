import React, {  useEffect, useState } from "react";
import { Grid,  Box, Button, Typography, Alert } from "@mui/material";
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";
import AppLoader from "../../Shared/Components/AppLoader";
import { AppFooter } from "../../Shared/Components/AppFooter";
import { useMsal } from "@azure/msal-react";
import "./Login.css";


export const Login = () => {
  const [loader, setLoader] = useState(true);
    const dispatch: Dispatch<any> = useDispatch();
 const { loading, error, userToken } = useSelector(
    (state: RootState) => state.UserData
  ); 
  const { instance } = useMsal();


  const handleSignIn=()=>{
    instance.loginRedirect({
     scopes: ["User.Read"]    
    });
  };
 
  useEffect(() => {
    if (loader) {
      const timerHandle = setTimeout(() => {
        setLoader(false);
        clearTimeout(timerHandle);
      }, 3000);
    }
  }, [loader]);

 

  return (
    <>
      <div style={{ display: loader ? "block" : "none" }}>
        <AppLoader showBackDrop={true} />
      </div>
      <>
        <Grid container columnSpacing={0}>
          <Grid item xs={4} sm={6} md={6} lg={6} xl={7}>
            <Box
              sx={{
                backgroundImage: `url(/assets/images/login_image.png)`,
                backgroundSize: "cover",
                objectFit: "cover",
                backgroundRepeat: "no-repeat",
                height: "100vh",
                objectPosition: "right",
              }}
            ></Box>
          </Grid>
          <Grid item xs={8} sm={6} md={6} lg={6} xl={5}>
            <div className="loginPageContentContainer">
              <Box sx={{ width: "80%", marginLeft: "0%" }}>
                <Box>
                  <Typography sx={{ fontSize: "1.5rem", fontWeight: "400" }}>
                    Welcome To
                  </Typography>
                  <Typography sx={{ fontSize: "3rem", fontWeight: "600" }}>
                    Data Migration Assets
                  </Typography>
                </Box>
                <Box>
                   {error && (
                      <Grid item xs={12}>
                         <Alert severity="error">{error}</Alert>
                      </Grid>
                    )}
                    {loading && userToken && (
                      <Grid item xs={12}>
                        <AppLoader showBackDrop={false} />
                      </Grid>
                    )}
                    <Button
                      type="submit"
                      size="medium"
                      variant="contained"
                      sx={{
                        marginTop: "0.375rem",
                        float: "right",
                        width: "6.6875rem",
                      }}
                      onClick={handleSignIn}
                    >
                      Sign In
                    </Button>
                 </Box>
              </Box>
              <div className="AppFooterContainer">
                <AppFooter />
              </div>
            </div>
          </Grid>
        </Grid>
      </>
    </>
  );
};
