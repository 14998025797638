import {
  fetchTargetCloudListLoading,
  fetchTargetCloudListSuccess,
  fetchTargetCloudListError,
} from "../../slices/sdvslices/TargetCloudListSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getTargetCloudList =
  (systemType: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchTargetCloudListLoading());
    try {
      const response = await AxiosInstance.get(
        `api/sdv/sdv_list_db/cloud_platform/${systemType}`
      );
      const data = response?.data?.document;
      dispatch(fetchTargetCloudListSuccess(data));
    } catch (error: any) {
      dispatch(fetchTargetCloudListError(error.message));
    }
  };
