import * as React from "react";
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { Box, styled } from "@mui/material";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/Store";
import AppLoader from "../../../Shared/Components/AppLoader";
import { StyledTableCell, StyledTableRow } from "../../../Shared/Utils/HelperFunc";
import "../../../styles/MigrationHistoryTable.css";

const TablePopup = (props: {
  movementID: number;
  tableData: any;
  openData: any;
  close: any;
}) =>  {

  const {
    data: movementDetails,
    loading: movementDetailsLoading,
    error: movementDetailsError,
  } = useSelector((state: RootState) => state.Sdm.MovementDetailsReducer);


  const handleClose = () => {
    props.close(false);
  };
  return (
    <div>
      <Dialog onClose={handleClose} open={props.openData} maxWidth={"lg"}>
        {movementDetailsLoading ? (
          <AppLoader showBackDrop={true} />
        ) : (
          movementDetails && (
            <DialogContent>
              <Typography className="migrationHistoryTablePopupHeading">
                Object Summary
              </Typography>
              <Box>
                <span className="tablePopupSubHeadingContainer">
                  <span className="tableHeads">Movement ID</span>
                  <span className="tableDefs"> {props?.movementID}</span>
                </span>
                <span className="tablePopupSubHeadingContainer">
                  <span className="tableHeads">Schema / Database Name</span>
                  <span className="tableDefs">
                    {" "}
                    {/* {props.tableData.databaseName} */}
                    {movementDetails?.Schema}
                  </span>
                </span>
                <span className="tablePopupSubHeadingContainer">
                  <span className="tableHeads">Source</span>
                  {/* <span className="tableDefs"> {props.tableData.source}</span> */}
                  <span className="tableDefs"> {movementDetails?.Source}</span>
                </span>
              </Box>
              <Box>
                <TableContainer component={Paper}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        {/* <StyledTableCell>Agent ID</StyledTableCell> */}
                        <StyledTableCell>Object Name</StyledTableCell>
                        <StyledTableCell>Row Count</StyledTableCell>
                        <StyledTableCell>Size in MB</StyledTableCell>
                        <StyledTableCell>Target</StyledTableCell>
                        <StyledTableCell>Start Date and Time</StyledTableCell>
                        <StyledTableCell>End Date and Time</StyledTableCell>
                        <StyledTableCell>Migration Status</StyledTableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {movementDetails?.object_details.map((obj, index) => (
                        <TableRow key={index}>
                          <TableCell>{obj.Object_name}</TableCell>
                          <TableCell>{obj.Row_count}</TableCell>
                          <TableCell>{obj["Size in MB"]}</TableCell>
                          <TableCell>{obj.Target}</TableCell>
                          <TableCell>
                            {obj["StartDate&time"].substring(
                              0,
                              obj["StartDate&time"].indexOf(".")
                            )}
                          </TableCell>
                          <TableCell>
                            {obj["EndDate&time"].substring(
                              0,
                              obj["EndDate&time"].indexOf(".")
                            )}
                          </TableCell>
                          <TableCell>
                            {obj.Migration_status.toLowerCase() ===
                            "failure" ? (
                              <span className="migrationData">
                                <p className="dotStyle failureDot"> </p>
                                <p className="dataStyle failedData">Failure</p>
                              </span>
                            ) : obj.Migration_status.toLowerCase() ===
                              "success" ? (
                              <span className="migrationData">
                                <p className="dotStyle successDot"> </p>
                                <p className="dataStyle successData">Success</p>
                              </span>
                            ) : (
                              <span className="migrationData">
                                <p className="dotStyle inprogressDot"> </p>
                                <p className="dataStyle inprogressData">
                                  In Progress
                                </p>
                              </span>
                            )}
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            </DialogContent>
          )
        )}
      </Dialog>
    </div>
  );
};
export default TablePopup;