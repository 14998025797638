import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MigrationHistoryType {
  MovementID: number;
  Source: string;
  Target: string;
  Operation: string;
  ["StartDate&time"]: string;
  ["EndDate&time"]: string;
  Migration_status: string;
  Volume: number;
  Time_taken_summary: string;
}
export interface MigrationHistoryState {
  data: MigrationHistoryType[] | undefined;
  loading: boolean;
  error: string | null;
}

const initialState: MigrationHistoryState = {
  data: undefined,
  loading: false,
  error: null,
};

const MigrationHistorySlice = createSlice({
  name: "migrationhistoryslice",
  initialState,
  reducers: {
    migrationHistoryLoading: (state) => {
      state.loading = true;
    },
    migrationHistorySuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    migrationHistoryError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
  },
});

export const {
  migrationHistoryLoading,
  migrationHistorySuccess,
  migrationHistoryError,
} = MigrationHistorySlice.actions;

export default MigrationHistorySlice.reducer;
