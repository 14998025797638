import CloudPlatFormReducer from "../slices/sdmslices/CloudPlatFormSlice";
import SelectedMigrationTypeReducer from "../slices/SelectedMigrationTypeSlice";
import SdmDataSourceReducer from "../slices/SdmDataSourceSlice";
import SelectedCloudReducer from "../slices/sdmslices/SelectedCloudSlice";
import TargetDatabaseReducer from "../slices/sdmslices/TargetDatabaseSlice";
import SelectedTargetDbReducer from "../slices/sdmslices/SelectedTargetDbSlice";
import MigrationReducer from "../slices/sdmslices/MigrationSlice";
import MigrationHistoryReducer from "../slices/sdmslices/MigrationHistorySlice";
import MovementDetailsReducer from "../slices/sdmslices/MovementDetailsSlice";
import ViewMigrationScemaReducer from "../slices/sdmslices/ViewMigrationSchemaSlice";
import MigrationSchemaListReducer from "../slices/sdmslices/MigrationSchemaListSlice";

const Sdm = {
  CloudPlatFormReducer,
  SelectedMigrationTypeReducer,
  SdmDataSourceReducer,
  SelectedCloudReducer,
  TargetDatabaseReducer,
  SelectedTargetDbReducer,
  MigrationReducer,
  MigrationHistoryReducer,
  MovementDetailsReducer,
  ViewMigrationScemaReducer,
  MigrationSchemaListReducer,
};

export default Sdm;
