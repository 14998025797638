import React, { useState } from "react";
import {
  Box,
  Collapse,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  Link,
  FormControlLabel,
  Radio,
  RadioGroup,
} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import "../../../styles/MigrationWave.css";
import { useDispatch } from "react-redux";
import { addMigrationWave } from "../../../redux/slices/SelectedMigrationWaveSlice";
import { DataValuesType } from "../../../redux/slices/MigrationWaveSlice";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../Shared/Utils/HelperFunc";

function Accordion(props: { data: any; radioProps: any; app: any }) {
  const { data } = props;
  const { radioProps } = props;
  const [accodrionOpen, setAccordionOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow>
        {/* // Radio button */}
        <TableCell component="th" scope="row">
          <div className="radioSpan">
            <FormControlLabel
              {...radioProps}
              className="radioButton"
              name="selectedData"
              control={<Radio />}
              label={radioProps.label}
            />
          </div>
        </TableCell>
        {/* Time Period */}
        <TableCell>{data.timePeriod}</TableCell>
        {/* Accordion opener */}
        <TableCell align="right">
          <Link
            className="migrationWaveLink"
            onClick={() => setAccordionOpen(!accodrionOpen)}
          >
            View Details
            {accodrionOpen ? (
              <KeyboardArrowUpIcon />
            ) : (
              <KeyboardArrowDownIcon />
            )}
          </Link>
        </TableCell>
      </TableRow>
      {/* inner Table code starts below this */}
      <TableRow>
        <TableCell className="tableCellsOuter" colSpan={6}>
          <Collapse in={accodrionOpen} timeout="auto" unmountOnExit>
            <Box className="migrationWaveInnerTableBox">
              <TableContainer className="migrationWaveTableContainer">
                <Table size="medium">
                  <TableHead>
                    <TableRow>
                      <StyledTableCell>Application</StyledTableCell>
                      <StyledTableCell>Database</StyledTableCell>
                      <StyledTableCell align="right">Size</StyledTableCell>
                      <StyledTableCell align="right">
                        No. of Tables
                      </StyledTableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data &&
                      data.map((innerTableData: any, index: number) => (
                        <StyledTableRow key={index}>
                          <TableCell>{innerTableData.application}</TableCell>
                          <TableCell>{innerTableData.database}</TableCell>
                          <TableCell align="right">
                            {innerTableData.size_in_gb}
                          </TableCell>
                          <TableCell align="right">
                            {innerTableData.table_count}
                          </TableCell>
                        </StyledTableRow>
                      ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}
interface MigrationWaveTableProps {
  dataValues: DataValuesType[][];
  app: string[];
  setSelection: Function;
  selection: string;
}

const MigrationWaveTable: React.FC<MigrationWaveTableProps> = ({
  dataValues,
  app,
  setSelection,
  selection,
}) => {
  const dispatch: any = useDispatch();
  const handleRadioSelection = (e: any) => {
    if (app) {
      setSelection(e.target.value);
      const waveName = app[e.target.value];
      dispatch(addMigrationWave(waveName));
    }
  };

  return (
    <>
      <Typography id="tableHead">Select one wave to migrate</Typography>
      <RadioGroup
        name="RadioGroup"
        className="migrationWaveRG"
        value={selection}
        onChange={handleRadioSelection}
      >
        <TableContainer component={Paper}>
          <Table>
            <TableBody>
              {dataValues &&
                dataValues.map((row, index) => (
                  <Accordion
                    key={index}
                    data={row}
                    app={app[index]}
                    radioProps={{ value: index, label: app[index] }}
                  />
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </RadioGroup>
    </>
  );
};

export default MigrationWaveTable;
