import { Box, Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { CodeDiscoveryDbListType } from "../../redux/slices/codediscovery/CodeDisoveryDbListSlice";
import {
  addDataSource,
  removeDataSource,
} from "../../redux/slices/SelectedDataSourceSlice";
import { RootState } from "../../redux/store/Store";
import { getCodeDiscoveryDbList } from "../../redux/thunks/codediscovery/CodeDiscoveryDbListThunk";
import { AppDataCards } from "../../Shared/Components/AppDataCards";
import AppLoader from "../../Shared/Components/AppLoader";
import "../../styles/DataSourcePage.css";
import { Link as RouteLink } from "react-router-dom";

const SourceCode = () => {
  const [updatedDataSourceList, setUpdatedDataSourceList] = useState<
    CodeDiscoveryDbListType[] | []
  >([]);
  const dispatch: any = useDispatch();
  const navigate = useNavigate();

  const { data: codeDiscoveryDbList, loading: codeDiscoveryDbLoading } =
    useSelector(
      (state: RootState) => state.CodeDiscovery.CodeDiscoveryDbListReducer
    );

  const selectedDataSourceList = useSelector(
    (state: RootState) => state.Common.SelectedDataSourceReducer
  );

  useEffect(() => {
    dispatch(getCodeDiscoveryDbList());
    dispatch(removeDataSource());
  }, [dispatch]);

  useEffect(() => {
    const manipulatedList = codeDiscoveryDbList?.map((item) => {
      return { ...item, isSelected: false };
    });
    setUpdatedDataSourceList(manipulatedList);
  }, [codeDiscoveryDbList]);

  const updateSelectedDatasource = (ID: string) => {
    const selected = updatedDataSourceList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedDataSourceList(selected);
    dispatch(addDataSource(selected));
  };

  return (
    <>
      <Box className="dataSourcePageDatabaseContainer">
        <Box className="dataSourcePageDataSource">
          {codeDiscoveryDbLoading ? (
            <AppLoader showBackDrop={false} />
          ) : (
            updatedDataSourceList?.map(
              (item: CodeDiscoveryDbListType, index: number) => (
                <AppDataCards
                  key={index}
                  dataItem={item}
                  handleClick={updateSelectedDatasource}
                />
              )
            )
          )}
        </Box>
        <Box className="dataSourcePageContainer">
          <Button
            size="large"
            variant="contained"
            component={RouteLink}
            to={"/home/discover/codediscovery/execution"}
            disabled={selectedDataSourceList.data.length === 0}
            // onClick={() => navigate("/home/discover/execution")}
          >
            <span className="dataSourcePageBtnImage">Continue</span>
            <img src="/assets/images/right-arrow.png" alt="Add" />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default SourceCode;
