import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MigrationTypeDataType {
  id: string;
  name: string;
  icon:string;
  value:string;
  isSelected?: boolean;
}

export interface SelectedMigrationTypeState {
  data: MigrationTypeDataType[];
}

const initialState: SelectedMigrationTypeState = {
  data: [],
};

const SelectedMigrationTypeSlice = createSlice({
  name: "selectMigrationTypeSlice",
  initialState,
  reducers: {
    addMigrationType: (state, action: PayloadAction<any>) => {
      const selectedData = action.payload.filter(
        (item: MigrationTypeDataType) => item?.isSelected
      );
      state.data = selectedData;
    },
  },
});

export const { addMigrationType } =SelectedMigrationTypeSlice.actions;
export default SelectedMigrationTypeSlice.reducer;