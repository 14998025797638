import { createSlice, PayloadAction } from "@reduxjs/toolkit";

import { TargetSystemListType } from "./TargetSystemListSlice";

export interface SelectedTargetSystemState {
  data: TargetSystemListType[];
}

const initialState: SelectedTargetSystemState = {
  data: [],
};

const SelectedTargetSystemSlice = createSlice({
  name: "selectedtargetsystemslice",
  initialState,
  reducers: {
    addTargetSystem: (state, action: PayloadAction<any>) => {
      const selectedData = action.payload.filter(
        (item: TargetSystemListType) => item?.isSelected
      );
      state.data = selectedData;
    },
    removeTargetSystem: (state) => {
      state.data = [];
    },
  },
});

export const { addTargetSystem, removeTargetSystem } =
  SelectedTargetSystemSlice.actions;
export default SelectedTargetSystemSlice.reducer;
