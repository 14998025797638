import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MigrationState {
  data: [] | undefined;
  loading: boolean;
  error: string | null;
}

const initialState: MigrationState = {
  data: [],
  loading: false,
  error: null,
};

const ViewMigrateSchema = createSlice({
  name: "viewmigrationschemaslice",
  initialState,
  reducers: {
    postSchemaViewLoading: (state) => {
      state.loading = true;
    },
    postSchemaViewSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    postSchemaViewError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
  },
});

export const {
  postSchemaViewLoading,
  postSchemaViewSuccess,
  postSchemaViewError,
} = ViewMigrateSchema.actions;

export default ViewMigrateSchema.reducer;
