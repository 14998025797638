import {
  fetchViewSourceCodeLoading,
  fetchViewSourceCodeSuccess,
  fetchViewSourceCodeError,
} from "../../slices/codediscovery/ViewSourceCodeSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getViewSourceCode =
  (sourceId: string, fileName: string) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch(fetchViewSourceCodeLoading());
    try {
      const response = await AxiosInstance.get(
        `api/pulsesdm/code_discovery/${sourceId}/${fileName}`
      );
      const data = response?.data?.content_details;
      dispatch(fetchViewSourceCodeSuccess(data));
    } catch (error: any) {
      dispatch(fetchViewSourceCodeError(error.message));
    }
  };
