import { Box, Button } from "@mui/material";
import Grid2 from "@mui/material/Unstable_Grid2";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Popups from "../../../Shared/Components/AppPopups";
import MigrationWaveChart from "./MigrationWaveChart";
import MigrationWaveTable from "./MigrationWaveTable";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store/Store";
import { getMigrationWave } from "../../../redux/thunks/MigrationWaveThunk";
import "../../../styles/MigrationWave.css";

const dataSource = {
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  paddingTop: "2.25rem",
  columnGap: "2.5rem",
  rowGap: "2.4rem",
};

function MigrationWavePage() {
  const [open, setOpen] = useState(false);
  const [selection, setSelection] = useState("");
  const [openTransform, setOpenTransform] = useState(false);
  const [dataValues, setDataValues] = useState<any>([]);
  const [app, setApp] = useState<string[] | []>([]);
  let navigate = useNavigate();
  const ModalCloseFunc = (modalClose: any) => {
    setOpen(modalClose);
  };

  const dispatch: any = useDispatch();
  const { data } = useSelector(
    (state: RootState) => state.Pulse.MigrationWaveReducer
  );

  useEffect(() => {
    dispatch(getMigrationWave());
  }, [dispatch]);

  useEffect(() => {
    if (data) {
      const tempDataValues = Object.values(data?.migration_wave_details);
      const tempAppNames = Object.keys(data?.migration_wave_details);
      setDataValues(tempDataValues);
      setApp(tempAppNames);
    }
  }, [data]);

  const modalData: any = [
    {
      title: "Do you want to migrate to cloud",
      src: "/assets/images/icon_cloud_transfer.svg",
      desc: "Choose 'Yes' to navigate to Migrate Asset to migrate your data to cloud or 'No' to remain in the Discovery Asset",
      btns: [
        {
          btnTitle: "No",
          btnType: "outlined",
          btnfunc: () => {
            setOpen(false);
          },
        },
        {
          btnTitle: "Yes",
          btnType: "contained",
          btnfunc: () => {
            setOpen(false);
            setOpenTransform(true);

            setTimeout(() => {
              setOpenTransform(false);
              navigate("/home/migrate/smartdatawarehousemover/target");
            }, 3000);
          },
        },
      ],
    },
  ];

  const modalDataTransform: any = [
    {
      title: "Redirecting to migration assets",
      src: "/assets/images/icon_cloud.svg",
      desc: "You are being redirecting to Smart Data Mover Tool to continue with the data Migration",
      btns: [],
    },
  ];

  const handleClick = () => {
    setOpen(true);
  };

  return (
    <Box className="databaseContainer">
      <Grid2 container spacing={0}>
        <Grid2 xs={12} md={12} lg={6} xl={6}>
          <p className="migrationWaveDescription">
            Data Migration project is divided into waves. A wave represents
            group of applications that are organized and prioritized in a wave
            based on business criticality, complexity and risks involved in
            moving to the cloud. Migration Wave Planning is a complex step which
            requires deep dive discussion between various stakeholders and
            integration of multiple data points.
          </p>
        </Grid2>
        <Grid2 xs={12} md={12} lg={6} xl={6}>
          <MigrationWaveChart chartData={data} />
        </Grid2>
        <Grid2 xs={12}>
          <MigrationWaveTable
            dataValues={dataValues}
            app={app}
            setSelection={setSelection}
            selection={selection}
          />
        </Grid2>
      </Grid2>
      <Box sx={dataSource}></Box>

      <Box className="migrationWaveButtons">
        <Button
          variant="outlined"
          size="medium"
          component={Link}
          to={"/home/discover/datadiscovery/discoverysummary"}
        >
          <img src="/assets/images/icon_back_arrow.svg" alt="backIcon" />
          Return to discover
        </Button>

        <Button
          variant="contained"
          size="medium"
          onClick={handleClick}
          disabled={selection.length === 0}
        >
          Migrate to Cloud
          <img
            src="/assets/images/right-arrow.png"
            alt="Add"
            style={{ paddingLeft: "0.75rem" }}
          />
        </Button>
      </Box>

      <Popups
        fullData={modalData}
        btnOpen={open}
        btnClose={!open}
        modalClose={ModalCloseFunc}
      />
      <Popups
        fullData={modalDataTransform}
        btnOpen={openTransform}
        btnClose={!openTransform}
        modalClose={ModalCloseFunc}
      />
    </Box>
  );
}

export default MigrationWavePage;
