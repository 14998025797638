import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SourceListType {
  id: string;
  name: string;
  isSelected?: boolean;
}

export interface SourceListState {
  data: SourceListType[];
  loading: boolean;
  error: string | null;
}

const initialState: SourceListState = {
  data: [],
  loading: false,
  error: null,
};

const SourceListSlice = createSlice({
  name: "Sourcelistslice",
  initialState,
  reducers: {
    fetchSourceListLoading: (state) => {
      state.loading = true;
    },
    fetchSourceSystemListSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchSourceSystemListError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    clearSourceSystemList: (state) => {
      state.data = [];
    },
  },
});

export const {
  fetchSourceListLoading,
  fetchSourceSystemListSuccess,
  fetchSourceSystemListError,
  clearSourceSystemList,
} = SourceListSlice.actions;

export default SourceListSlice.reducer;
