import {
  Configuration,
  LogLevel,
  PopupRequest,
  EventType,
} from "@azure/msal-browser";

/**
Config object to be passed to Msal on creation
*/
export const msalConfig: Configuration = {
  auth: {
    clientId: "d19a4dc2-4ae6-4991-bbda-4b31a0002662",
    authority:
      "https://login.microsoftonline.com/f6009c61-3de1-417c-a92a-b343e274dc9f",
    redirectUri: "/", // relative path
    postLogoutRedirectUri: "/", // relative path
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
  system: {
    allowRedirectInIframe: true, // for Front end logout channel
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {},
      logLevel: LogLevel.Verbose,
    },
  },
};

// Add here the endpoints for MS Graph API services.
export const graphConfig = {
  graphMeEndpoint: "https://graph.microsoft.com/v1.0/me",
};
