import {
  postExecuteLoading,
  postExecuteSuccess,
  postExecuteError,
} from "../../slices/sdvslices/ObjectConfigExecuteSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";
import { ObjectExecutionObjectType } from "../../types/ObjectExecutionTypes";

export const postObjectExecute =
  (bodyPayload: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(postExecuteLoading());
    try {
      const response = await AxiosInstance.post(
        `api/sdv/object_config/save?Filename=${bodyPayload}`
        // JSON.stringify(bodyPayload)
      );
      if (response.status === 200) {
        const data = response?.data;
        const formattedData = data?.execution?.map(
          (item: ObjectExecutionObjectType, index: number) => {
            return {
              id: index,
              ...item.validation_set.common,
              ...item.validation_set.firstsource,
              ...item.validation_set.secondsource,
            };
          }
        );
        dispatch(postExecuteSuccess(formattedData));
      }
    } catch (error: any) {
      dispatch(postExecuteError(error.message));
    }
  };
