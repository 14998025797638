import { Chart } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { useEffect, useState } from "react";

ChartJS.register(...registerables, ChartDataLabels);

interface MigrateSummaryType {
  Total: number;
  "In-Scope": number;
  "Out-Of-Scope": number;
}
interface MigrateChartType {
  migration_wave_summary: MigrateSummaryType | any;
}
interface PropsTypes {
  chartData: MigrateChartType | undefined;
}
const MigrationWaveChart = (props: PropsTypes) => {
  const [Application, setApplication] = useState<number[]>();
  const [Databases, setDatabases] = useState<number[]>();
  const [Tables, setTables] = useState<number[]>();

  let rootData =
    props.chartData !== undefined && props.chartData.migration_wave_summary;

  let labelsData: string[] =
    rootData !== undefined
      ? Object.keys(rootData)
      : ["Total", "In-Scope", "Out-Of-Scope"];

  const getApplicationData = () => {
    let applicationData: number[] = [];
    rootData.Total !== undefined &&
      applicationData.push(rootData.Total.Applications);
    rootData["In-Scope"] !== undefined &&
      applicationData.push(rootData["In-Scope"].Applications);
    rootData["Out-Of-Scope"] !== undefined &&
      applicationData.push(rootData["Out-Of-Scope"].Applications);
    setApplication(applicationData);
  };
  const getDatabasesData = () => {
    let setDatabasesData: number[] = [];
    rootData.Total !== undefined &&
      setDatabasesData.push(rootData.Total.Databases);
    rootData["Out-Of-Scope"] !== undefined &&
      setDatabasesData.push(rootData["Out-Of-Scope"].Databases);
    rootData["In-Scope"] !== undefined &&
      setDatabasesData.push(rootData["In-Scope"].Databases);
    setDatabases(setDatabasesData);
  };
  const getTablesData = () => {
    let setTablesData: number[] = [];
    rootData.Total !== undefined && setTablesData.push(rootData.Total.Tables);
    rootData["Out-Of-Scope"] !== undefined &&
      setTablesData.push(rootData["Out-Of-Scope"].Tables);
    rootData["In-Scope"] !== undefined &&
      setTablesData.push(rootData["In-Scope"].Tables);
    setTables(setTablesData);
  };

  useEffect(() => {
    getApplicationData();
    getDatabasesData();
    getTablesData();
  }, [rootData]);

  const dataItem = {
    labels: labelsData,
    datasets: [
      {
        label: "Application",
        yAxisID: "ya",
        data: Application,
        backgroundColor: "rgba(70, 0, 115, 1)",
        barThickness: 37,
        borderRadius: 6,
        order: 2,
      },
      {
        label: "Databases",
        yAxisID: "ya",
        data: Databases,
        backgroundColor: "rgba(161, 0, 255, 1)",
        barThickness: 37,
        borderRadius: 6,
        order: 2,
      },
      {
        type: "line" as const,
        label: "Tables",
        datalabels: {
          labels: {
            title: null,
          },
        },
        yAxisID: "y1",
        data: Tables,
        pointRadius: 10,
        backgroundColor: "#F5C9FF",
        borderColor: "#F5C9FF",
        order: 1,
        pointHoverRadius: 10.5,
      },
    ],
  };
  const options = {
    responsive: true,
    interaction: {
      mode: "index" as const,
      intersect: false,
    },
    stacked: true,
    plugins: {
      datalabels: {
        // align: "end" as const,
        color: "white",
      },
      yScaleText: {
        fontColor: "white",
      },
      legend: {
        display: false,
      },
      title: {
        display: false,
      },
    },
    scales: {
      ya: {
        stacked: true,
        type: "linear" as const,
        display: true,
        position: "left" as const,
        ticks: {
          stepSize: 30,
          color: "#7500C0",
        },
      },
      y1: {
        // stacked: true,
        type: "linear" as const,
        display: true,
        position: "right" as const,
        grid: {
          drawOnChartArea: false,
        },
        ticks: {
          color: "#7500C0",
        },
      },
      x: {
        stacked: true,
      },
    },
  };

  return (
    <>
      <div>
        <h2>Migration Strategy</h2>
      </div>
      <div className="migrationWaveTableTitles">
        <p>Databases</p>
        <p>Tables</p>
      </div>
      <Chart data={dataItem} options={options} type={"bar"} />
    </>
  );
};

export default MigrationWaveChart;
