import { Box, Button } from "@mui/material";
import { useRef } from "react";
import { Link } from "react-router-dom";
import "../../styles/DiscoverySummary.css";

const DiscoverySummary = () => {
  const linkElement = useRef<null | HTMLAnchorElement>(null);
  const link =
    "https://app.powerbi.com/groups/1eab908d-0ae8-4a94-bc4f-c41468f7feb4/reports/0757a7c7-8b5f-4bda-b0c4-1d24287910f5/ReportSection9e97366b14040e995855";

  return (
    <div>
      <Box className="discoverySummaryContainer">
        <a
          ref={linkElement}
          href={link}
          target="_blank"
          rel="noreferrer noopener"
        >
          View dashboard
        </a>
      </Box>
      <Box className="discoverySummaryBtn">
        <Button
          size="large"
          variant="contained"
          component={Link}
          to={"/home/discover/datadiscovery/migrationwave"}
        >
          Continue
          <img
            src="/assets/images/right-arrow.png"
            alt="Add"
            style={{ paddingLeft: "0.75rem" }}
          />
        </Button>
      </Box>
    </div>
  );
};

export default DiscoverySummary;
