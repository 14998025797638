import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DataTargetDataType {
  id: string;
  name: string;
  isSelected?: boolean;
}

export interface DataTargetState {
  data: DataTargetDataType[];
  loading: boolean;
  error: string | null;
}

const initialState: DataTargetState = {
  data: [],
  loading: false,
  error: null,
};

const ConvertTargetSlice = createSlice({
  name: "datatargetslice",
  initialState,
  reducers: {
    fetchDataTargetLoading: (state) => {
      state.loading = true;
    },
    fetchDataTargetSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchDataTargetError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
  },
});

export const {
  fetchDataTargetLoading,
  fetchDataTargetSuccess,
  fetchDataTargetError,
} = ConvertTargetSlice.actions;

export default ConvertTargetSlice.reducer;
