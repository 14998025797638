import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface MigrationSchemaListState {
  data: string[];
  loading: boolean;
  error: string | null;
}

const initialState: MigrationSchemaListState = {
  data: [],
  loading: false,
  error: null,
};

const MigrationSchemaListSlice = createSlice({
  name: "migrationschemalistslice",
  initialState,
  reducers: {
    fetchSchemaListLoading: (state) => {
      state.loading = true;
    },
    fetchSchemaListSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchSchemaListError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    clearSchemaList: (state) => {
      state.data = [];
    },
  },
});

export const {
  fetchSchemaListLoading,
  fetchSchemaListSuccess,
  fetchSchemaListError,
  clearSchemaList,
} = MigrationSchemaListSlice.actions;

export default MigrationSchemaListSlice.reducer;
