import * as React from "react";
import {
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/Store";
import { queries } from "@testing-library/react";
import { StyledTableCell, StyledTableRow } from "../../../Shared/Utils/HelperFunc";


const DataTable = () => {
  const startData = [
    {
      id: "1",
      queries: ">7.2M",
      lowerCount: 7200000,
      upperCount: 90000000000,
      dbs: 0,
      percents: 0,
    },
    {
      id: "2",
      queries: "5.4M-7.2M",
      lowerCount: 5400000,
      upperCount: 7200000,
      dbs: 0,
      percents: 0,
    },
    {
      id: "3",
      queries: "3.6M-5.4M",
      lowerCount: 3600000,
      upperCount: 5400000,
      dbs: 0,
      percents: 0,
    },
    {
      id: "4",
      queries: "1.8M-3.6M",
      lowerCount: 1800000,
      upperCount: 3600000,
      dbs: 0,
      percents: 0,
    },
    {
      id: "5",
      queries: "<1.8M",
      lowerCount: 0,
      upperCount: 1800000,
      dbs: 0,
      percents: 0,
    },
  ];
  const schemaCountData = useSelector(
    (state: RootState) => state.DiscoveryOverview.SchemaQueryCountSlice.data
  );
  interface TableRowsType {
    id: string;
    queries: string;
    lowerCount: number;
    upperCount: number;
    dbs: number;
    percents: number;
  }
  const [tableRows, setTableRows] = React.useState<TableRowsType[]>(startData);

  React.useEffect(() => {
    if (schemaCountData) {
      let datatemp = [...startData];
      const totalSchemas = schemaCountData?.length;
      schemaCountData?.map((schema): any => {
        datatemp.forEach((row, index) => {
          if (
            schema?.total_queries >= row?.lowerCount &&
            schema?.total_queries <= row?.upperCount
          ) {
            datatemp[index] = { ...row, dbs: row.dbs + 1 };
          }
        });
      });
      setTableRows(datatemp);
      datatemp.forEach((row) => {
        const percentage = (row.dbs / totalSchemas) * 100;
        row.percents = Math.ceil(percentage * 10) / 10;
      });
    }
  }, [schemaCountData]);

  return (
    <TableContainer component={Paper}>
      <Table sx={{ minWidth: 500 }}>
        <TableHead>
          <TableRow>
            <StyledTableCell>#Queries</StyledTableCell>
            <StyledTableCell>#DBs</StyledTableCell>
            <StyledTableCell>% of DBs</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {tableRows.map((row) => (
            <StyledTableRow key={row.id}>
              <StyledTableCell>{row.queries}</StyledTableCell>
              <StyledTableCell>{row.dbs}</StyledTableCell>
              <StyledTableCell>{row.percents}</StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
export default DataTable;