import {
  fetchTargetDatabaseLoading,
  fetchTargetDatbaseSuccess,
  fetchTargetDatabaseError,
} from "../../slices/sdmslices/TargetDatabaseSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getTargetDatabase =
  (platformId: string, sourceId: string) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch(fetchTargetDatabaseLoading());
    try {
      const response = await AxiosInstance.get(
        `api/pulsesdm/List_target_db/${platformId}/${sourceId}`
      );
      const data = response?.data?.document;
      dispatch(fetchTargetDatbaseSuccess(data));
    } catch (error: any) {
      dispatch(fetchTargetDatabaseError(error.message));
    }
  };
