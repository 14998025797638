import React from "react";
import { Box, Dialog, IconButton } from "@mui/material";
import "../../../styles/AppModal.css";
import CloseIcon from "@mui/icons-material/Close";

interface AppModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  body: React.ReactNode;
  footer?: React.ReactNode;
}

const AppModal: React.FC<AppModalProps> = ({
  open,
  onClose,
  title,
  body,
  footer,
}) => {
  return (
    <Dialog open={open} onClose={onClose}>
      <div>
        <Box className="AppModalTitleContainer">
          <div className="AppModalTitle">{title}</div>
          <IconButton onClick={onClose}>
            <CloseIcon className="AppModalTitleClose" />
          </IconButton>
        </Box>
        <Box className="AppModalBody">{body}</Box>
        {footer && <Box>{footer}</Box>}
      </div>
    </Dialog>
  );
};

export default AppModal;
