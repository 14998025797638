import {
  fetchDbListLoading,
  fetchDbListSuccess,
  fetchDbListError,
} from "../../slices/codediscovery/CodeDisoveryDbListSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getCodeDiscoveryDbList =
  () => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchDbListLoading());
    try {
      const response = await AxiosInstance.get(
        "api/pulsesdm/Pulse_list_db/code_discover"
      );
      const data = response?.data?.document;
      dispatch(fetchDbListSuccess(data));
    } catch (error: any) {
      dispatch(fetchDbListError(error.message));
    }
  };
