import axios, { AxiosHeaders } from "axios";
import { graphConfig } from "../../core/auth/msalConfig";
export const fetchProfileInfo = (accessToken:string) => {

      return new Promise(async (resolve, reject) => {
        await axios.get(graphConfig.graphMeEndpoint, {
            headers: {
                  authorization: `Bearer ${accessToken}`
            }
        })
            .then(response => {
                const data = response.data;
                if (data) {
                    resolve(data);
                } else {
                    resolve("false");
                }
            })
            .catch((error) => {
                resolve("false");
            });
    });
};

//TODO: to test the profile image 
export const fetchPhotoFromGraphAPI = (userId: string) => {
    return new Promise(async (resolve, reject) => {
        await axios.get(
            graphConfig.graphMeEndpoint + "users/" + userId + "/photo/$value", {
                responseType: "blob"
            }
        )
            .then(response => {
                const data = response.data;
                if (data) {
                    const url = window.URL || window.webkitURL;
                    const pic = url.createObjectURL(data);
                    resolve(pic);
                } else {
                    resolve("false");
                }
            })
            .catch((error) => {
                resolve("false");
            });
    });
};