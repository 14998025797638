import { createSlice, PayloadAction } from "@reduxjs/toolkit";
interface ValidationConfigurationState {
  [key: string]: string | boolean | string[];
  OutputDirectory: string;
  ErrorThreshold: string;
  ClusterPlatformName: string;
  IntelligentCompare: boolean;
  ColumnMapping: boolean;
  ValidationEngine: boolean;
  ObjectConfigurationType: boolean;
  DataValidation: boolean;
  ExtractionType: boolean;
  ColumnAggregationValidation: boolean;
  ValidationType: string[];
  AggregateArithmeticFunctions: string[];
}
const initialState: ValidationConfigurationState = {
  OutputDirectory: "",
  ErrorThreshold: "",
  ClusterPlatformName: "",
  IntelligentCompare: false,
  ColumnMapping: false,
  ValidationEngine: false,
  ObjectConfigurationType: false,
  ExtractionType: false,
  DataValidation: false,
  ColumnAggregationValidation: false,
  ValidationType: [],
  AggregateArithmeticFunctions: [],
};

const validationConfigurationSlice = createSlice({
  name: "validationConfiguration",
  initialState,
  reducers: {
    setInputValue: (
      state,
      action: PayloadAction<{
        name: string;
        value: string[] | string | boolean;
      }>
    ) => {
      state[action.payload.name] = action.payload.value;
    },
    resetInputValues: (state) => {
      return initialState;
    },
  },
});

export const { setInputValue, resetInputValues } =
  validationConfigurationSlice.actions;
export default validationConfigurationSlice.reducer;
