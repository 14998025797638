var CryptoJS = require("crypto-js");

const Encrypt = (message: any) => {
  const REACT_APP_AUTH_KEY = process.env.REACT_APP_AUTH_KEY;
  const encrypted_key = CryptoJS.AES.encrypt(
    JSON.stringify(message),
    REACT_APP_AUTH_KEY
  );
  const encrypted_key_string = encrypted_key.toString();
  return encrypted_key_string;
};
export default Encrypt;
