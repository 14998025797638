import React, { useState } from "react";
import "../../../styles/MigrationHistoryTable.css";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Link, Typography } from "@mui/material";
import TablePopup from "./TablePopup";
import { useDispatch } from "react-redux";
import { getMovementDetails } from "../../../redux/thunks/sdmthunks/MovementDetailsThunk";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../Shared/Utils/HelperFunc";

const MigrationHistoryTable = (props: { data: any; titleData: any }) => {
  const [data, setData] = useState<any>();
  const [open, setOpen] = useState(false);
  const [movementID, setMovementID] = useState<number | 0>(0);

  const dispatch: any = useDispatch();
  const handlLinkClick = (index: any) => {
    props.data.map((row: any) => {
      if (row.MovementID === index) {
        setMovementID(index);
        setData(row);
        setOpen(true);
        dispatch(getMovementDetails(index));
      }
    });
  };

  const handleClose = (id: any) => {
    setOpen(id);
  };

  return (
    <>
      <Typography variant="h4" className="migrationTableHeader">
        Migration Table
      </Typography>

      <TableContainer component={Paper} sx={{ maxHeight: 344 }}>
        <Table className="migrationHistoryTableHead" stickyHeader>
          <TableHead className="migrationHistoryTableHead">
            <TableRow>
              {props.titleData.map((row: any, index: number) => {
                return <StyledTableCell key={index}>{row}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row: any) => (
              <StyledTableRow key={row.MovementID}>
                <StyledTableCell
                  className="migrationHistoryTableCellHeading"
                  component="th"
                  scope="row"
                >
                  <Link
                    className="migrationHistoryTableLink"
                    onClick={() => {
                      handlLinkClick(row?.MovementID);
                    }}
                  >
                    {row?.MovementID}
                  </Link>
                </StyledTableCell>
                <StyledTableCell>{row?.Source}</StyledTableCell>
                <StyledTableCell>{row?.Target}</StyledTableCell>
                <StyledTableCell>{row?.Operation}</StyledTableCell>
                <StyledTableCell>
                  {row["StartDate&time"].substring(
                    0,
                    row["StartDate&time"].indexOf(".")
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  {row["EndDate&time"].substring(
                    0,
                    row["EndDate&time"].indexOf(".")
                  )}
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    className={
                      row?.Migration_status.toLocaleLowerCase() === "failure"
                        ? "failedData "
                        : row?.Migration_status.toLocaleLowerCase() ===
                          "success"
                        ? "successData"
                        : "inprogressData"
                    }
                  >
                    <p className="dataStyle ">
                      ● &nbsp; {row?.Migration_status}
                    </p>
                  </div>
                </StyledTableCell>
                <StyledTableCell>{row?.Volume}</StyledTableCell>
                <StyledTableCell>{row?.Time_taken_summary}</StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePopup
        movementID={movementID}
        tableData={data}
        openData={open}
        close={handleClose}
      />
    </>
  );
};

export default MigrationHistoryTable;
