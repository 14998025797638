import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface TargetCloudListType {
  id: string;
  name: string;
  isSelected?: boolean;
}

export interface TargetCloudListState {
  data: TargetCloudListType[];
  loading: boolean;
  error: string | null;
}

const initialState: TargetCloudListState = {
  data: [],
  loading: false,
  error: null,
};

const TargetCloudListSlice = createSlice({
  name: "targetcloudlistslice",
  initialState,
  reducers: {
    fetchTargetCloudListLoading: (state) => {
      state.loading = true;
    },
    fetchTargetCloudListSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchTargetCloudListError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    removeTargetCloudList: (state) => {
      state.data = [];
    },
  },
});

export const {
  fetchTargetCloudListLoading,
  fetchTargetCloudListSuccess,
  fetchTargetCloudListError,
  removeTargetCloudList,
} = TargetCloudListSlice.actions;

export default TargetCloudListSlice.reducer;
