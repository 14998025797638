import { createSlice , PayloadAction } from '@reduxjs/toolkit'

const initialState = {
  loading: false,
  userToken : null,
  isUserAuthenticated : false,
  error: null,
  success: false, // for monitoring the login process.
}

const AuthSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    tokenLoading: (state) => {
      state.loading = true;
    },
    tokenSuccess: (state, action: PayloadAction<any>) => {
      state.userToken = action.payload; 
      state.isUserAuthenticated = true;
      state.success = true;
      state.loading = false;
      state.error = null;
    },
    tokenError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.isUserAuthenticated = false;
      state.success = false;
      state.userToken = null;
    },
  }
})

export const {
  tokenLoading,
  tokenSuccess,
  tokenError,
} = AuthSlice.actions;

export default AuthSlice.reducer
