import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface CloudPlatformDataType {
  id: string;
  name: string;
  isSelected?: boolean;
}

export interface CloudPlatformState {
  data: CloudPlatformDataType[];
  loading: boolean;
  error: string | null;
}

const initialState: CloudPlatformState = {
  data: [],
  loading: false,
  error: null,
};

const CloudPlatformSlice = createSlice({
  name: "cloudplatformslice",
  initialState,
  reducers: {
    fetchCloudPlatformLoading: (state) => {
      state.loading = true;
    },
    fetchCloudPlatformSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchCloudPlatformError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
  },
});

export const {
  fetchCloudPlatformLoading,
  fetchCloudPlatformSuccess,
  fetchCloudPlatformError,
} = CloudPlatformSlice.actions;

export default CloudPlatformSlice.reducer;
