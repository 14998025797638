import {
  postSchemaViewLoading,
  postSchemaViewSuccess,
  postSchemaViewError,
} from "../../slices/sdmslices/ViewMigrationSchemaSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const viewSchemas =
  (schemaData: any) => async (dispatch: AppThunkDispatch) => {
    dispatch(postSchemaViewLoading());
    try {
      const response = await AxiosInstance.post(
        `api/pulsesdm/Migration/list_object`,
        JSON.stringify(schemaData)
      );
      if (response.status === 200) {
        const data = response?.data;
        dispatch(postSchemaViewSuccess(data));
      }
    } catch (error: any) {
      dispatch(postSchemaViewError(error.message));
    }
  };
