import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Chart } from "react-chartjs-2";
import { Chart as ChartJS, registerables } from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "../../../styles/SchemaChart.css";
import { SchemaChartType } from "./DiscoveryOverview";
import Grid2 from "@mui/material/Unstable_Grid2";
ChartJS.register(...registerables, ChartDataLabels);

export const options = {
  plugins: {
    legend: {
      display: false,
    },
    datalabels: {
      anchor: "end" as const,
      color: "#000000" as const,
      font: { size: 20, weight: 600 },
      offset: 8 as const,
      align: "end" as const,
    },
  },
  cutout: 90,
  radius: "75%",
};
const schemaStyle = {
  padding: "0px",
  marginTop: "50px",
  marginLeft: "1rem",
};
export interface SchemaChartProps {
  data: SchemaChartType;
}
// function SchemaChart(props: { data: any }) {

export interface SchemaChartProps {
  data: SchemaChartType;
}

function SchemaChart({ data }: SchemaChartProps) {
  const finalData = {
    datasets: [
      {
        data: data.chartData,
        borderWidth: 0.5,
        backgroundColor: ["rgba(159, 80, 209, 0.5)", "rgba(159, 80, 209, 1)"],
      },
    ],
    datalabels: {
      anchor: "center",
      align: "center",
      color: "green",
    },
    labels: [data.legendTypeTwo, data.legendTypeOne],
  };
  return (
    <Grid2 container className="schemaChartGridContainer">
      <Grid2 xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box>
          <Typography className="schemaChartTitleContainer">
            {data.title}
          </Typography>
          <Box className="schemChartLegendContainer">
            <div className="legends">
              <p className="purpleDot"> </p>
              {data.legendTypeOne} <p className="lightPurpleDot"> </p>
              {data.legendTypeTwo}
            </div>
          </Box>
        </Box>
      </Grid2>
      <Grid2 xs={12} sm={12} md={12} lg={6} xl={6}>
        <Box>
          <Chart data={finalData} options={options} type={"doughnut"} />
        </Box>
      </Grid2>
    </Grid2>
  );
}

export default SchemaChart;
