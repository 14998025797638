import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DataSourceDataType {
  id: string;
  name: string;
  isSelected?: boolean;
}

export interface DataSourceState {
  data: DataSourceDataType[];
  loading: boolean;
  error: string | null;
}

const initialState: DataSourceState = {
  data: [],
  loading: false,
  error: null,
};

const DataSoureSlice = createSlice({
  name: "datasourceslice",
  initialState,
  reducers: {
    fetchDataSourceLoading: (state) => {
      state.loading = true;
      state.error = null;
    },
    fetchDataSourceSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchDataSourceError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
  },
});

export const {
  fetchDataSourceLoading,
  fetchDataSourceSuccess,
  fetchDataSourceError,
} = DataSoureSlice.actions;

export default DataSoureSlice.reducer;
