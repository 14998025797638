import { createTheme } from "@mui/material/styles";

/* All theme related css to be mentioned here*/
export const AppTheme = createTheme({
  palette: {
    primary: {
      main: "#A100FF", // Primary Color
    },
    secondary: {
      main: "#7500C0", // Secondary Color
    },
  },
  typography: {
    fontFamily: "'Graphik',sans-serif",
    htmlFontSize: 16,
    body1: {
      fontSize: "1rem",
      fontFamily: "'Graphik',sans-serif",
      letterSpacing: "-0.02em",
    },
    body2: {
      fontSize: "1rem",
      fontFamily: "'Graphik',sans-serif",
      letterSpacing: "-0.02em",
    },
    h4: {
      fontSize: "2rem",
      fontFamily: "'Graphik',sans-serif",
      letterSpacing: "-0.02em",
      fontWeight: "700",
      paddingLeft: "1.25rem",
      paddingTop: "1rem",
    },
    subtitle2: {
      fontSize: "1.25rem",
      fontFamily: "'Graphik',sans-serif",
      letterSpacing: "-0.02em",
      fontWeight: "400",
      paddingLeft: "1.25rem",
      paddingTop: "1rem",
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 900,
      md: 1000,
      lg: 1200,
      xl: 1536,
    },
  },
  components: {
    MuiAppBar: {
      styleOverrides: {
        root: {
          position: "static",
          backgroundColor: "#FFFFFF",
          boxShadow: "none",
          paddingLeft: "1.5rem",
          paddingRight: "1.5rem",
        },
      },
    },
    MuiToolbar: {
      defaultProps: {
        disableGutters: true,
      },
      styleOverrides: {
        root: {
          height: "4.5rem",
        },
      },
    },

    MuiBreadcrumbs: {
      styleOverrides: {
        root: {
          paddingLeft: "6.25rem",
          height: "4rem",
          backgroundColor: "#460073",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          color: "#fff",
        },
      },
    },
    MuiButton: {
      defaultProps: {
        disableRipple: true,
        centerRipple: false,
        disableElevation: true,
      },
      variants: [
        {
          props: { variant: "contained", size: "large" },
          style: {
            color: "#FFFFFF",
            textTransform: "none",
            fontSize: "1.125rem",
            padding: "15.5px 24px 15.5px 24px",
            fontWeight: 600,
            height: "48px",
            alignItems: "center",
            minWidth: "152px",
            background: "#A100FF",
            borderRadius: 25,
            border: "2px solid #A100FF",
            "&:disabled": {
              color: "#F2F2F2",
              borderColor: "#B3B2B5",
              background: "#B3B2B5",
            },
            "&:hover": {
              border: "2px solid #7500C0",
              color: "white",
              backgroundColor: "#7500C0",
            },
            "&:focus": {
              border: "2px solid #0041F0",
              color: "white",
              backgroundColor: "#7500C0",
            },
          },
        },
        {
          props: { variant: "contained", size: "small" },
          style: {
            display: "flex",
            alignContent: "center",
            fontSize: "1rem",
            justifyContent: "center",
            marginLeft: "0.5rem",
            marginTop: "-1rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            textTransform: "none",
            borderRadius: 25,
            background: "#A100FF",
            border: "2px solid #A100FF",
            "&:disabled": {
              color: "#F2F2F2",
              borderColor: "#B3B2B5",
              background: "#B3B2B5",
            },
            "&:hover": {
              border: "2px solid #7500C0",
              color: "white",
              backgroundColor: "#7500C0",
            },
            "&:focus": {
              border: "2px solid #0041F0",
              color: "white",
              backgroundColor: "#7500C0",
            },
          },
        },
        {
          props: { variant: "contained", size: "medium" },
          style: {
            fontSize: "1.125rem",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            marginLeft: "0.5rem",
            marginTop: "-1rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            textTransform: "none",
            borderRadius: 25,
            border: "2px solid #A100FF",
            "&:disabled": {
              color: "#F2F2F2",
              borderColor: "#B3B2B5",
              background: "#B3B2B5",
            },
            "&:hover": {
              border: "2px solid #7500C0",
              color: "white",
              backgroundColor: "#7500C0",
            },
            "&:focus": {
              border: "2px solid #0041F0",
              color: "white",
              backgroundColor: "#7500C0",
            },
          },
        },
        {
          props: { variant: "outlined", size: "medium" },
          style: {
            fontSize: "1.125rem",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            marginLeft: "0.5rem",
            marginTop: "-1rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            textTransform: "none",
            borderRadius: 25,
            border: "2px solid #9C34C6",
            "&:disabled": {
              color: "#F2F2F2",
              borderColor: "#B3B2B5",
              background: "#B3B2B5",
            },
            "&:hover": {
              border: "2px solid #7500c0",
              color: "white",
              backgroundColor: "#7500c0",
            },
            "&:focus": {
              border: "2px solid #0041F0",
              color: "white",
              backgroundColor: "#7500C0",
            },
          },
        },
        {
          props: { variant: "outlined", size: "small" },
          style: {
            fontSize: "1rem",
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            marginLeft: "0.5rem",
            marginTop: "-1rem",
            paddingLeft: "1rem",
            paddingRight: "1rem",
            textTransform: "none",
            borderRadius: 25,
            border: "2px solid #9C34C6",
            "&:disabled": {
              color: "#F2F2F2",
              borderColor: "#B3B2B5",
              background: "#B3B2B5",
            },
            "&:hover": {
              border: "2px solid #7500c0",
              color: "white",
              backgroundColor: "#7500c0",
            },
            "&:focus": {
              border: "2px solid #0041F0",
              color: "white",
              backgroundColor: "#7500C0",
            },
          },
        },
        {
          props: { variant: "outlined", size: "large" },
          style: {
            textTransform: "none",
            fontSize: "1.1rem",
            padding: "15.5px 24px 15.5px 24px",
            fontWeight: 600,
            height: "48px",
            alignItems: "center",
            minWidth: "152px",
            borderRadius: 25,
            border: "2px solid #9C34C6",
            "&:disabled": {
              color: "#B3B2B5",
              borderColor: "#B3B2B5",
            },
            "&:hover": {
              border: "2px solid #9C34C6",
              color: "white",
              backgroundColor: "#9C34C6",
            },
            "&:focus": {
              border: "2px solid #0041F0",
              color: "white",
              backgroundColor: "#7500C0",
            },
          },
        },
        {
          props: { variant: "text", size: "large" },
          style: {
            textTransform: "none",
            fontSize: "1rem",
            padding: "24rem",
            fontWeight: 600,
            height: "48px",
            alignItems: "center",
            minWidth: "152px",
            borderRadius: 25,
          },
        },
        {
          props: { variant: "text", size: "medium" },
          style: {
            color: "#A100FF",
            textTransform: "none",
            fontSize: "1rem",
            padding: "1rem",
            fontWeight: 600,
            height: "48px",
            alignItems: "center",
            minWidth: "152px",
            borderRadius: 25,
            "&:hover": {
              background: "white",
            },
          },
        },
        {
          props: { variant: "text", size: "small" },
          style: {
            color: "#A100FF",
            textTransform: "none",
            fontSize: "1rem",
            padding: "1rem",
            fontWeight: 600,
            height: "48px",
            alignItems: "center",
            minWidth: "152px",
            borderRadius: 25,
            "&:hover": {
              background: "transparent",
            },
          },
        },
      ],
    },
    MuiMenuItem: {
      defaultProps: {
        disableRipple: true,
        disableTouchRipple: true,
      },
      styleOverrides: {
        root: {
          height: "3rem",
          borderRadius: 0,
          p: "2rem",
          borderColor: "#b3b2b5",
          width: "100%",
          color: "#000",
          fontSize: "1rem",
          pl: "1.063rem",
          "&:hover": {
            borderRadius: 0,
            color: "#A100FF",
            border: "1px solid #9C34C6",
          },
          "&:focus": {
            borderRadius: 0,
            border: "2px solid #9C34C6",
            color: "#A100FF",
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {},
      styleOverrides: {
        root: {
          "& fieldset": {
            borderRadius: 0,
            border: "2px solid #b3b2b5",
          },
          "&:focus": {
            borderRadius: 0,
            border: "2px solid #9C34C6",
          },
          "& input:valid + fieldset": {
            border: "2px solid #4f4b53",
          },
          "& input:invalid + fieldset": {
            border: "2px solid #b3b2b5",
          },
          "& input:valid:focus + fieldset": {
            border: "2px solid #9C34C6",
          },
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        disableRipple: true,
      },
    },
    MuiCard: {
      defaultProps: {},
      styleOverrides: {
        root: {
          padding: 0,
          display: "flex",
          flexDirection: "column",
          margin: "1.25rem",
          paddingBottom: "1rem",
        },
      },
    },
    // MuiModal: {
    //   defaultProps: {
    //     disableEnforceFocus: true,
    //     // hideBackdrop: true,
    //   },
    //   styleOverrides: {
    //     root: {
    //       // display: "flex",
    //       // alignContent: "center",
    //       // justifyContent: "center",
    //       position: "absolute" as "absolute",
    //       // top: "50%",
    //       // left: "50%",
    //       bgcolor: "#FFF",
    //       border: "2px solid #000",
    //       width: 823,
    //       overflow: "auto",
    //       overflowX: "hidden",
    //       margin: "auto",
    //       // p:6,
    //     },
    //   },
    // },
    MuiBackdrop: {
      styleOverrides: {
        root: {
          zIndex: "1",
        },
      },
    },
    //    MuiStepper:{
    //     defaultProps: {},
    //     styleOverrides:{
    //       root:{
    //         display: "block",
    // flexDirection: "row",
    // justifyContent: "center",
    // alignItems: "center",
    // padding: "50px 0px",
    // marginLeft: "350px",
    // marginRight: "250px",

    //       }
    //     }

    //    }
  },
});
