import { Box, Button } from "@mui/material";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import { AppDataCards } from "../../Shared/Components/AppDataCards";
import {
  addSourceSystemType,
  removeSelectedSourceSystemType,
  SourceSystemTypeDataType,
} from "../../redux/slices/sdvslices/SelectedSourceSystemTypeSlice";
import { removeCloudPlatform } from "../../redux/slices/sdvslices/SelectedCloudPlatformSlice";
import { removeFileSystem } from "../../redux/slices/sdvslices/SelectedFileSystemSlice";
import { removeTargetCloudPlatform } from "../../redux/slices/sdvslices/SelectedTargetCloudPlatformSlice";
import { removesource } from "../../redux/slices/sdvslices/SelectedSdvSourceCloudslice";

import { removedComputeConfiguration } from "../../redux/slices/sdvslices/ComputeConfigurationDataSlice";

import { removeDataSource } from "../../redux/slices/sdvslices/SelectSourceSystemDataSlice";
import { resetInputValues } from "../../redux/slices/sdvslices/ValidationConfigurationSlice";

import "../../styles/SourceSystemType.css";
import { clearConfigurationDetails } from "../../redux/slices/sdvslices/ConfigurationDetailsSlice";
import { removeTargetCloudList } from "../../redux/slices/sdvslices/TargetCloudListSlice";
import { removeTargetSystem } from "../../redux/slices/sdvslices/SelectedTargetSystemSlice";
import { clearTargetSystemList } from "../../redux/slices/sdvslices/TargetSystemListSlice";
import { removeSdvCloudList } from "../../redux/slices/sdvslices/SdvCloudListSlice";
import { clearFileSystemList } from "../../redux/slices/sdvslices/FileSystemListSlice";
import { removeSourceSystemList } from "../../redux/slices/sdvslices/SourceSystemListSlice";
import { removeValidationTable } from "../../redux/slices/sdvslices/ValidationTableSlice";
import { clearComputeTypeList } from "../../redux/slices/sdvslices/ComputeTypeListSlice";
import { removeInstanceType } from "../../redux/slices/sdvslices/InstanceTypeListSlice";

function SourceSystemType() {
  const data = [
    {
      id: "1",
      icon: "/assets/images/icons/datasource.png",
      name: "On-Prem",
      value: "on_prem",
      isSelected: false,
    },

    {
      id: "2",
      icon: "/assets/images/icons/datasource.png",
      name: "Cloud",
      value: "on_cloud",
      isSelected: false,
    },
    {
      id: "3",
      icon: "/assets/images/icons/datasource.png",
      name: "File On Cloud",
      value: "file_on_cloud",
      isSelected: false,
    },
  ];
  const [isBoxSelected, setIsBoxSelected] = useState(false);
  const [updatedSourceSystemTypeList, setUpdatedSourceSystemTypeList] =
    React.useState<SourceSystemTypeDataType[]>(data);
  const dispatch: Dispatch<any> = useDispatch();
  const updateSelectedSourceSystemType = (ID: string) => {
    const selected = updatedSourceSystemTypeList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedSourceSystemTypeList(selected);
    dispatch(addSourceSystemType(selected));
    const isAtLeastOneSelected = selected?.some((item) => item.isSelected);
    setIsBoxSelected(isAtLeastOneSelected);
  };

  useEffect(() => {
    dispatch(removeCloudPlatform());
    dispatch(removeFileSystem());
    dispatch(removeTargetCloudPlatform());
    dispatch(removesource());
    dispatch(removedComputeConfiguration());
    dispatch(removeDataSource());
    dispatch(resetInputValues());
    dispatch(removeTargetCloudList());
    dispatch(removeTargetSystem());
    dispatch(clearTargetSystemList());
    dispatch(removeSelectedSourceSystemType());
    dispatch(removeSdvCloudList());
    dispatch(clearFileSystemList());
    dispatch(removeSourceSystemList());
    dispatch(removeValidationTable());
    dispatch(clearComputeTypeList());
    dispatch(removeInstanceType());
  }, [dispatch]);

  useEffect(() => {
    dispatch(clearConfigurationDetails());
  }, [updatedSourceSystemTypeList]);

  return (
    <Box className="sourceSystemTypeContainer">
      <Box className="sourceSystemTypeAppdataCards">
        {updatedSourceSystemTypeList.map((data, index) => {
          return (
            <AppDataCards
              key={index}
              dataItem={data}
              handleClick={updateSelectedSourceSystemType}
            />
          );
        })}
      </Box>
      <Box className="sourceSystemTypePageContainer">
        <Button
          size="large"
          variant="contained"
          disabled={!isBoxSelected}
          component={Link}
          to={"/home/validate/smartdatavalidator/sourceandtarget"}
        >
          <span className="sourceSystemTypePageBtnImage">Continue</span>
          <img src="/assets/images/right-arrow.png" alt="Add" />
        </Button>
      </Box>
    </Box>
  );
}
export default SourceSystemType;
