import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ObjectDependencyType {
  name: string;
  children: ObjectDependencyType[];
}
export interface ObjectDependencyState {
  data: { [key: string]: ObjectDependencyType };
  loading: boolean;
  error: string | null;
}

const initialState: ObjectDependencyState = {
  data: {},
  loading: false,
  error: null,
};

const ObjectDependencySlice = createSlice({
  name: "objectdependencyslice",
  initialState,
  reducers: {
    fetchObjectDependencyLoading: (state) => {
      state.loading = true;
    },
    fetchObjectDependencySuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchObjectDependencyError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = {};
    },
  },
});

export const {
  fetchObjectDependencyLoading,
  fetchObjectDependencySuccess,
  fetchObjectDependencyError,
} = ObjectDependencySlice.actions;

export default ObjectDependencySlice.reducer;
