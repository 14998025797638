import {
  fetchCloudPlatformLoading,
  fetchCloudPlatformSuccess,
  fetchCloudPlatformError,
} from "../../slices/sdmslices/CloudPlatFormSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getCloudPlatform = () => async (dispatch: AppThunkDispatch) => {
  dispatch(fetchCloudPlatformLoading());
  try {
    const response = await AxiosInstance.get(
      "api/pulsesdm/List_cloud_platform/get"
    );
    const data = response?.data?.document;
    dispatch(fetchCloudPlatformSuccess(data));
  } catch (error: any) {
    dispatch(fetchCloudPlatformError(error.message));
  }
};
