import React from "react";
import Tree from "react-d3-tree";
import { ObjectDependencyType } from "../../../../redux/slices/discoveryoverview/ObjectDependencySlice";
import "./DecompTree.css";

const exactData = {
  name: "HR",
  children: [
    {
      name: "VIEW",
      children: [
        {
          name: "EMP_DETAILS_VIEW",
          children: [
            {
              name: "LOCATIONS",
              children: [
                { name: "Asia" },
                { name: "Africa" },
                { name: "Europe" },
              ],
            },
            { name: "REGIONS" },
            { name: "EMPLOYEES" },
            {
              name: "JOBS",
              children: [
                { name: "Analyst" },
                { name: "Manager" },
                { name: "Lawyer" },
              ],
            },
            {
              name: "COUNTRIES",
              children: [
                {
                  name: "India",
                  children: [{ name: "Maharshtra" }, { name: "Karnataka" }],
                },
                {
                  name: "USA",
                  children: [
                    {
                      name: "Texas",
                      children: [{ name: "Houston" }, { name: "Dallas" }],
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
    { name: "PROCEDURE" },
    { name: "TRIGGER" },
  ],
};

const nodeSize = { x: 250, y: 140 };
export interface treeProps {
  treedata: ObjectDependencyType;
}
export default function DecompTree({ treedata }: treeProps) {
  return (
    <div id="treeWrapper" style={{ width: "70em", height: "30em" }}>
      <Tree
        data={treedata}
        rootNodeClassName="node__root node"
        branchNodeClassName="node__branch node"
        leafNodeClassName="node__leaf node"
        nodeSize={nodeSize}
      />
    </div>
  );
}
