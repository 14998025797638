import { createSlice, PayloadAction } from "@reduxjs/toolkit";
// import { DataState } from "../../core/contentType";
// import { IAppConfigurationProps } from "../../Shared/Components/AppConfiguration";

export interface DbAllDetailsType {
  [key: string]: {
    // delimeter: string;
    bucketName: string;
    ConfigurationTitle: string;
    ConnectionType: string;
    Database: string;
    Delimiter: string;
    Password: string;
    Port: string;
    Source: string;
    System: string;
    Username: string;
    bucketname: string;
    fileformat: string;
    authenticationtype: string;
    accountname: string;
    azcontainer: string;
    accountkey: string;
    IsHeaderPresent: string;
    isquoted: string;
    accessId: string;
    secretKey: string;
    AuthenticationType: string;
    AccountName: string;
    AZContainer: string;
    AccountKey: string;
    FileFormat: string;
    IsQuoted: string;
  };
}
export interface DbAllDetailsState {
  data: DbAllDetailsType[];
  loading: boolean;
  error: string | null;
}

const initialState: DbAllDetailsState = {
  data: [],
  loading: false,
  error: null,
};

const DbAllDetailsSlice = createSlice({
  name: "datasourceslice",
  initialState,
  reducers: {
    fetchDbAllDetailsLoading: (state) => {
      state.loading = true;
    },
    fetchDbAllDetailsSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchDbAllDetailsError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    deleteDbDetailLoading: (state) => {
      state.loading = true;
    },
    deleteDbDetailSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.data = state.data.filter(
        (item) => Object.keys(item)[0] !== action.payload
      );
    },
    deleteDbDetailError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addConfigurationLoading: (state) => {
      state.loading = true;
    },
    addConfigurationSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    addConfigurationError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    editConfigurationLoading: (state) => {
      state.loading = true;
    },
    editConfigurationSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    editConfigurationError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const {
  fetchDbAllDetailsLoading,
  fetchDbAllDetailsSuccess,
  fetchDbAllDetailsError,
  deleteDbDetailLoading,
  deleteDbDetailSuccess,
  deleteDbDetailError,
  addConfigurationLoading,
  addConfigurationSuccess,
  addConfigurationError,
  editConfigurationLoading,
  editConfigurationSuccess,
  editConfigurationError,
} = DbAllDetailsSlice.actions;

export default DbAllDetailsSlice.reducer;
