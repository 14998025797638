import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface DataTargetDataType {
  id: string;
  name: string;
  isSelected?: boolean;
}

export interface SelectedDataTargetState {
  data: DataTargetDataType[];
}

const initialState: SelectedDataTargetState = {
  data: [],
};

const selectedConvertTargetDataSlice = createSlice({
  name: "selecteddatasourceslice",
  initialState,
  reducers: {
    addTargetSelectedData: (state, action: PayloadAction<any>) => {
      const selectedData = action.payload.filter(
        (item: DataTargetDataType) => item?.isSelected
      );
      state.data = selectedData;
    },
    removeTargetData: (state) => {
      state.data = [];
    },
  },
});

export const { addTargetSelectedData, removeTargetData } =
  selectedConvertTargetDataSlice.actions;
export default selectedConvertTargetDataSlice.reducer;
