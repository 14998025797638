import { useEffect, useState } from "react";
import { Box, RadioGroup } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";
import {
  deleteSdvConfig,
  editSdvConfig,
  getSdvConfigDetails,
} from "../../redux/thunks/sdv/ConfigurationDetailsThunk";
import AppLoader from "../../Shared/Components/AppLoader";
import ConfigurationCard from "../../Shared/Components/ConfigurationCard";
import "../../styles/DataConfig.css";

interface SdvDataConfigProps {
  showDataSource?: boolean;
  handleClick?: () => void;
  handleClickTarget?: () => void;
  targetDbId?: string;
  typeOfModal?: string;
  target?: string;
  pathName?: string;
  selectedConfig?: any;
}
const SdvDataConfig = ({
  targetDbId,
  typeOfModal,
  target,
  selectedConfig,
}: SdvDataConfigProps) => {
  const dispatch: any = useDispatch();
  const [isEditable, setIsEditable] = useState(false);
  const [selection, setSelection] = useState("");

  const { data: configDetailsList, loading: configDetailsLoading } =
    useSelector((state: RootState) => state.Sdv.ConfigurationDetailsSlice);

  useEffect(() => {
    if (targetDbId) {
      dispatch(getSdvConfigDetails(targetDbId));
    }
  }, [dispatch, targetDbId]);

  const handleRadioSelection = (e: any) => {
    setSelection(e.target.value);
    selectedConfig(e.target.value);
  };
  const handleAppConfigDeleteClick = (db_id: string, secret_Id: string) => {
    dispatch(deleteSdvConfig(db_id, secret_Id));
  };

  const handleEditSaveButtonClick = (
    dbID: string,
    secretID: string,
    payload: string
  ) => {
    dispatch(editSdvConfig(dbID, secretID, payload));
  };

  return (
    <div>
      <Box className="dataConfigContainer">
        <Box>
          <RadioGroup
            name="RadioGroup"
            value={selection}
            onChange={handleRadioSelection}
          >
            {configDetailsLoading ? (
              <AppLoader showBackDrop={false} />
            ) : (
              configDetailsList &&
              configDetailsList.length > 0 &&
              configDetailsList.map((item, index) => (
                <ConfigurationCard
                  dbConfigDetails={item}
                  key={index}
                  indexKey={index}
                  radioProps={{ value: index, label: index }}
                  dbId={targetDbId !== undefined ? targetDbId : ""}
                  handleDeleteClick={handleAppConfigDeleteClick}
                  handleEdit={handleEditSaveButtonClick}
                  isEditable={isEditable}
                  setIsEditable={setIsEditable}
                  title={
                    target === "target"
                      ? "Target Configuration"
                      : "File System Configuration"
                  }
                  isGcp={typeOfModal === "gcp"}
                  isAws={typeOfModal === "aws"}
                  isAzure={typeOfModal === "azure"}
                />
              ))
            )}
          </RadioGroup>
        </Box>
      </Box>
    </div>
  );
};
export default SdvDataConfig;
