import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { CloudPlatformDataType } from "./CloudPlatFormSlice";

export interface SelectedCloudState {
  data: CloudPlatformDataType[];
}

const initialState: SelectedCloudState = {
  data: [],
};

const SelectedCloudSlice = createSlice({
  name: "selectedcloudslice",
  initialState,
  reducers: {
    addCloudPlatform: (state, action: PayloadAction<any>) => {
      const selectedData = action.payload.filter(
        (item: CloudPlatformDataType) => item?.isSelected
      );
      state.data = selectedData;
    },
    clearSelectedCloudData: (state) => {
      state.data = [];
    },
  },
});

export const { addCloudPlatform, clearSelectedCloudData } =
  SelectedCloudSlice.actions;
export default SelectedCloudSlice.reducer;
