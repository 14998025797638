import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { CloudPlatformDataType } from "../../redux/slices/sdmslices/CloudPlatFormSlice";
import { addCloudPlatform } from "../../redux/slices/sdmslices/SelectedCloudSlice";
import { addTargetDb } from "../../redux/slices/sdmslices/SelectedTargetDbSlice";
import {
  clearTargetDatabase,
  TargetDatabaseType,
} from "../../redux/slices/sdmslices/TargetDatabaseSlice";
import { RootState } from "../../redux/store/Store";
import { getCloudPlatform } from "../../redux/thunks/sdmthunks/CloudPlatformThunk";
import { getTargetDatabase } from "../../redux/thunks/sdmthunks/TargetDatabaseThunk";
import { AppDataCards } from "../../Shared/Components/AppDataCards";
import AppLoader from "../../Shared/Components/AppLoader";
import DataConfig from "../DataConfig";
import "../../styles/Target.css";

function TargetSource() {
  const [showDataSource, setShowDataSource] = useState<boolean>(true);
  const [button, setButton] = useState("Continue");
  const [updatedCloudList, setUpdatedCloudList] = useState<
    CloudPlatformDataType[] | []
  >([]);
  const [updatedTargetDb, setUpdatedTargetDb] = useState<
    TargetDatabaseType[] | []
  >([]);

  const { data: cloudPlatformDataList, loading: cloudLoading } = useSelector(
    (state: RootState) => state.Sdm.CloudPlatFormReducer
  );

  const { data: targetDatabaseList, loading: targetDbLoading } = useSelector(
    (state: RootState) => state.Sdm.TargetDatabaseReducer
  );

  const { data: targetSelectedDatabaseList } = useSelector(
    (state: RootState) => state.Sdm.SelectedTargetDbReducer
  );

  const selectedDatabaseId = useSelector(
    (state: RootState) => state.Common.SelectedDataSourceReducer?.data[0]?.id
  );
  const selectedCloudId = useSelector(
    (state: RootState) => state.Sdm.SelectedCloudReducer?.data[0]?.id
  );

  const dispatch: any = useDispatch();
  useEffect(() => {
    dispatch(getCloudPlatform());
  }, [dispatch]);

  useEffect(() => {
    if (selectedCloudId)
      dispatch(getTargetDatabase(selectedCloudId, selectedDatabaseId));
    else dispatch(clearTargetDatabase());
  }, [dispatch, selectedCloudId, selectedDatabaseId]);

  useEffect(() => {
    const manipulatedList = cloudPlatformDataList?.map((item) => {
      return { ...item, isSelected: false };
    });
    setUpdatedCloudList(manipulatedList);
  }, [cloudPlatformDataList]);

  const updateSelectedCloud = (ID: string) => {
    const selected = updatedCloudList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedCloudList(selected);
    dispatch(addCloudPlatform(selected));
  };

  useEffect(() => {
    const manipulatedTargetDbList = targetDatabaseList?.map((item) => {
      return { ...item, isSelected: false };
    });
    setUpdatedTargetDb(manipulatedTargetDbList);
  }, [targetDatabaseList]);

  const updateTargetDb = (ID: string) => {
    const selected = updatedTargetDb?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedTargetDb(selected);
    dispatch(addTargetDb(selected));
  };

  const handleContinueClick: () => void = () => {
    setShowDataSource(!showDataSource);
  };

  return (
    <div>
      {showDataSource ? (
        <div>
          <Box className="targetContainer">
            {cloudLoading && <AppLoader showBackDrop={false} />}
            {updatedCloudList?.map((item, index) => {
              return (
                <AppDataCards
                  key={index}
                  dataItem={item}
                  icon="target"
                  handleClick={updateSelectedCloud}
                />
              );
            })}
          </Box>
          <Box>
            {targetDatabaseList.length > 0 && (
              <Typography variant="subtitle2">
                Select your target system
              </Typography>
            )}
            <Box>
              <Box className="targetContainer">
                {!targetDbLoading ? (
                  updatedTargetDb?.map((data, index) => {
                    return (
                      <AppDataCards
                        key={index}
                        dataItem={data}
                        handleClick={updateTargetDb}
                      />
                    );
                  })
                ) : (
                  <AppLoader showBackDrop={false} />
                )}
              </Box>
            </Box>
          </Box>
          <Box className="targetButtonsContainer">
            <Button
              className="targetBtn"
              size="large"
              variant="contained"
              style={{ marginLeft: "auto" }}
              disabled={
                targetDatabaseList.length === 0 ||
                targetSelectedDatabaseList.length === 0
              }
              onClick={handleContinueClick}
            >
              {button}
              <img
                className="targetBtnImage"
                src="/assets/images/right-arrow.png"
                alt="Add"
              />
            </Button>
          </Box>
        </div>
      ) : (
        <DataConfig
          showDataSource={showDataSource}
          handleClickTarget={handleContinueClick}
          target="target"
          pathName="/home/migrate/smartdatawarehousemover/migration"
        />
      )}
    </div>
  );
}

export default TargetSource;
