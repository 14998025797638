import React, { useEffect, useState } from "react";
import {
  GridColDef,
  GridPreProcessEditCellProps,
  GridRenderCellParams,
  GridRowId,
  useGridApiContext,
} from "@mui/x-data-grid";
import "../../../styles/Migration.css";
import CustomDataGrid from "../../../Shared/Components/DataGrid/CustomDataGrid";
import {
  Button,
  Checkbox,
  FormControlLabel,
  MenuItem,
  TextField,
  Typography,
} from "@mui/material";
import { Dispatch } from "@reduxjs/toolkit";
import { useDispatch, useSelector } from "react-redux";
import { postMigration } from "../../../redux/thunks/sdmthunks/MigrationThunk";
import { RootState } from "../../../redux/store/Store";
import AppLoader from "../../../Shared/Components/AppLoader";
import { useNavigate } from "react-router-dom";
import { viewSchemas } from "../../../redux/thunks/sdmthunks/ViewMigrationSchemas";
import { getMigrationSchemaList } from "../../../redux/thunks/sdmthunks/MigrationSchemaListThunk";

const style = {
  width: "300px",
  "& .MuiInputLabel-root": { color: "#4F4B53" },
  "& .MuiOutlinedInput-root": {
    "& > fieldset": { borderColor: "#B3B2B5" },
  },
  "& .MuiOutlinedInput-root:hover": {
    "& > fieldset": {
      borderColor: "#A100FF",
    },
  },
};
interface itemDataTypes {
  id: number;
  TABLE_TYPE: string;
  SOURCE_TABLE: string;
  name: string;
  NUM_ROWS: number;
  size: string;
  storageType: string;
  PARTITION_COLUMNS: string | string[];
  USAGE: string | string[];
  SIZE_MB: number;
  PARTITION_TYPE: string;
}
interface selectedItemTypes {
  id: number;
  object_type: string;
  object_row_count: number;
  object_name: string;
  object_size_in_MB: number;
  object_storageType: string;
  object_usage: string | string[];
  object_size: string | string[];
  object_partitionBy: string | string[];
  object_partitionType: string;
  object_condition: string;
  object_slice: string;
}
function Migration() {
  const [schemaOptions, setSchemaOptions] = useState<string[] | []>([]);
  const [selectedSchema, setSelectedSchema] = useState("");
  const [checked, setChecked] = React.useState([false, false, false]);
  const [checkedUsage, setCheckedUsage] = React.useState([false, false, false]);
  const [size, setSize] = React.useState<string[]>([]);
  const [usage, setUsage] = React.useState<string[]>([]);
  const [isSchemaChecked, setIsSchemaChecked] = useState([false, false]);
  const [handleDisabled, setHandleDisabled] = useState(false);
  const [checkedFlag, setcheckedFlag] = useState(false);
  const [selectedRowsData, setSelectedRowsData] = useState<any>();
  const [selectedData, setSelectedData] = useState<object[]>();
  const [isTableRowChecked, setIsTableRowChecked] = useState<number>();
  const [showTable, setShowTable] = useState(false);
  const [rows, setRows] = useState<any>();
  const [gridEditRows, setGridEditRows] = useState<any>([]);
  const [isIdAvailable, setisIdAvailable] = useState(true);

  const dispatch: Dispatch<any> = useDispatch();
  const navigate = useNavigate();
  const { data: migrationData, loading: migrationLoading } = useSelector(
    (state: RootState) => state.Sdm.MigrationReducer
  );
  const { data: viewSchemaData, loading: viewSchemaDataLoading } = useSelector(
    (state: RootState) => state.Sdm.ViewMigrationScemaReducer
  );

  const { data: sourceId } = useSelector(
    (state: RootState) => state.Common.SelectedDataSourceReducer
  );
  const { data: platformId } = useSelector(
    (state: RootState) => state.Sdm.SelectedCloudReducer
  );
  const { data: targetId } = useSelector(
    (state: RootState) => state.Sdm.SelectedTargetDbReducer
  );

  const { data: selectedAppName } = useSelector(
    (state: RootState) => state.Pulse.SelectedMigrationWaveReducer
  );

  const { data: migrationSchemaList } = useSelector(
    (state: RootState) => state.Sdm.MigrationSchemaListReducer
  );

  const renderSelectEditInputCell: GridColDef["renderCell"] = (params) => {
    return <SelectEditInputCell {...params} />;
  };
  const renderSelectEditTypeCell: GridColDef["renderCell"] = (params) => {
    return <SelectEditTypeCell {...params} />;
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "", hide: true },
    { field: "object_name", headerName: "Name", width: 150 },
    {
      field: "object_size_in_MB",
      align: "left",
      headerAlign: "left",
      headerName: "Size (MB)",
      type: "number",
      width: 150,
    },
    {
      field: "object_storageType",
      headerName: "Storage Type",
      editable: false,
      width: 150,
      hide: true,
    },
    { field: "object_usage", headerName: "Usage", width: 150 },
    {
      field: "object_partitionBy",
      headerName: "Partition By",
      type: "singleSelect",
      editable: true,
      valueOptions: [],
      width: 200,
      renderCell: (param) => {
        return param.row.object_partitionBy === "NA" ? (
          <Typography>NA</Typography>
        ) : (
          <SelectEditInputCell {...param} />
        );
      },
      renderEditCell: renderSelectEditInputCell,
    },
    {
      field: "object_partitionType",
      headerName: "Partition Type",
      width: 200,
      renderCell: (param) => {
        return param.row.object_partitionType === "NO" ? (
          <Typography>NA</Typography>
        ) : (
          <SelectEditTypeCell {...param} />
        );
      },
      renderEditCell: renderSelectEditTypeCell,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        return { ...params.props };
      },
    },
    {
      field: "object_condition",
      headerName: "Condition",
      editable: true,
      width: 200,
      renderCell: (param) => {
        if (
          param.row.object_partitionType === "Custom" &&
          param.row.object_condition === ""
        ) {
          gridEditRows[param.row.id].object_condition = "- =James, =Riley";
          return (
            <Typography>
              {gridEditRows[param.row.id].object_condition}
            </Typography>
          );
        }
        if (
          param.row.object_partitionType === "Range" &&
          param.row.object_condition === ""
        ) {
          gridEditRows[param.row.id].object_condition = "1000,7500";
          return (
            <Typography>
              {gridEditRows[param.row.id].object_condition}
            </Typography>
          );
        }
      },
      renderEditCell: ({ row }) => {
        if (row.object_partitionType === "Date") {
          return <CustomDateField {...row} />;
        } else {
          return <CustomTextField {...row} />;
        }
      },
    },
    {
      field: "object_slice",
      headerName: "#Slice",
      editable: true,
      width: 150,
      preProcessEditCellProps: (params: GridPreProcessEditCellProps) => {
        if (params.props.value) {
          let updatedRow = { ...params.row, object_slice: params.props.value };
          setGridEditRows(
            gridEditRows.map((row: { id: number }) =>
              row.id === params.row.id ? updatedRow : row
            )
          );
        }
        return { ...params.props };
      },
    },
  ];

  function CustomDateField(props: { id: string | number }) {
    const [date, setDate] = useState<string>();
    const apiRef = useGridApiContext();
    const setDateValue = async (event: React.ChangeEvent<HTMLInputElement>) => {
      setDate(event.target.value);
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: "object_condition",
        value: event.target.value,
      });
    };
    gridEditRows[props.id].object_condition = date;
    return <TextField type="date" value={date} onChange={setDateValue} />;
  }

  function CustomTextField(props: { id: string | number }) {
    const [textValue, setTextValue] = useState<string>();
    const apiRef = useGridApiContext();
    const setText = async (event: React.ChangeEvent<HTMLInputElement>) => {
      setTextValue(event.target.value);
      await apiRef.current.setEditCellValue({
        id: props.id,
        field: "object_condition",
        value: event.target.value,
      });
    };
    gridEditRows[props.id].object_condition = textValue;

    return <TextField type="string" value={textValue} onChange={setText} />;
  }
  useEffect(() => {
    const rows =
      viewSchemaData !== undefined &&
      viewSchemaData.map((item: itemDataTypes, index: number) => ({
        id: index,
        object_type: "Table",
        object_row_count: item.NUM_ROWS,
        object_name: item.SOURCE_TABLE,
        object_size_in_MB: item.SIZE_MB,
        object_storageType: "S3",
        object_usage: item.USAGE,
        object_size: selectedRowSize(item.TABLE_TYPE),
        object_partitionBy: item.PARTITION_COLUMNS,
        object_partitionType: item.PARTITION_TYPE,
        object_condition: "",
        object_slice: "",
      }));
    setRows(rows);
    setGridEditRows(rows);
  }, [viewSchemaData]);
  function SelectEditInputCell(props: GridRenderCellParams) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();

    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });

      setGridEditRows(
        gridEditRows.map((row: { id: GridRowId }) => {
          let updatedRow = { ...row, object_partitionBy: event.target.value };
          if (id === row?.id) {
            return updatedRow;
          } else {
            return row;
          }
        })
      );
    };

    let index = Number(id);
    return (
      <TextField
        select
        InputLabelProps={{ shrink: false }}
        value={value}
        sx={{ width: "350px" }}
        onChange={handleChange}
      >
        {rows[index].object_partitionBy !== undefined &&
        typeof rows[index].object_partitionBy !== "string" ? (
          rows[index].object_partitionBy.map((item: string, index: number) => (
            <MenuItem key={index} value={item}>
              {item}
            </MenuItem>
          ))
        ) : (
          <MenuItem
            key={rows[index].object_partitionBy}
            value={rows[index].object_partitionBy}
          >
            NA
          </MenuItem>
        )}
      </TextField>
    );
  }
  function SelectEditTypeCell(props: GridRenderCellParams) {
    const { id, value, field } = props;
    const apiRef = useGridApiContext();
    const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
      await apiRef.current.setEditCellValue({
        id,
        field,
        value: event.target.value,
      });

      setGridEditRows(
        gridEditRows.map((row: { id: GridRowId }) => {
          let updatedRow = { ...row, object_partitionType: event.target.value };
          if (id === row?.id) {
            return updatedRow;
          } else {
            return row;
          }
        })
      );
    };

    return (
      <TextField
        select
        InputLabelProps={{ shrink: false }}
        value={value}
        sx={{ width: "350px" }}
        onChange={handleChange}
      >
        <MenuItem value="Date">Date</MenuItem>
        <MenuItem value="Range">Range</MenuItem>
        <MenuItem value="Custom">Custom</MenuItem>
      </TextField>
    );
  }
  useEffect(() => {
    if (selectedAppName.length === 0) {
      dispatch(getMigrationSchemaList());
    }
    if (selectedAppName.length > 0) {
      dispatch(getMigrationSchemaList(selectedAppName));
    }
  }, [dispatch, selectedAppName]);

  useEffect(() => {
    setSchemaOptions([...migrationSchemaList]);
  }, [migrationSchemaList]);

  const rowChecked = (rowChecked: number) => {
    setIsTableRowChecked(rowChecked);
  };

  const selectedRowSize = (size: string) => {
    if (size === "S") {
      return "Small";
    } else if (size === "M") {
      return "Medium";
    } else {
      return "Large";
    }
  };

  useEffect(() => {
    let selectedRow = [];
    if (selectedRowsData !== undefined) {
      selectedRow = selectedRowsData.map(
        (item: selectedItemTypes) => gridEditRows[item.id]
      );
      setSelectedData(selectedRow);
    }
  }, [selectedRowsData, gridEditRows]);

  const payload = {
    schema: selectedSchema,
    operation: "All",
    object_details: selectedData,
  };

  const handleChange = (event: any) => {
    const newChecked = checked;
    let val = size;
    newChecked[Number(event.target.name)] = event.target.checked;
    event.target.checked
      ? val.push(event.target.value)
      : (val = val.filter((e) => e !== event.target.value));
    setSize([...val]);
    setChecked([...newChecked]);
  };
  const handleChangeUsage = (event: any) => {
    const newChecked = checkedUsage;
    let val = usage;
    newChecked[Number(event.target.name)] = event.target.checked;
    event.target.checked
      ? val.push(event.target.value)
      : (val = val.filter((e) => e !== event.target.value));
    setUsage([...val]);
    setCheckedUsage([...newChecked]);
  };
  const checkedCollection = (event: any) => {
    const newChecked = isSchemaChecked;
    newChecked[Number(event.target.name)] = event.target.checked;
    setIsSchemaChecked([...newChecked]);
  };
  useEffect(() => {
    if (isTableRowChecked !== undefined) {
      if (isTableRowChecked > 0) {
        setcheckedFlag(true);
      } else {
        setcheckedFlag(false);
      }
    }
  }, [isTableRowChecked]);
  useEffect(() => {
    isSchemaChecked.includes(true)
      ? setHandleDisabled(false)
      : setHandleDisabled(true);
  }, [isSchemaChecked]);

  const schemaData = {
    schema: selectedSchema,
    size: size,
    usage_threshold: {
      Hot: 100000,
      Warm: 10000,
      Cold: 0,
    },
    usage: usage,
  };
  const handleMigrate = () => {
    const source_Id = sourceId.length > 0 ? sourceId[0].id : "";
    const platform_Id = platformId.length > 0 ? platformId[0].id : "";
    const target_Id = targetId.length > 0 ? targetId[0].id : "";

    if (source_Id !== "" && platform_Id !== "" && target_Id !== "") {
      setisIdAvailable(true);
      dispatch(postMigration(source_Id, platform_Id, target_Id, payload));
    } else {
      setisIdAvailable(false);
    }
  };

  const submitSchema = () => {
    setShowTable(true);
    dispatch(viewSchemas(schemaData));
  };
  useEffect(() => {
    if (migrationData) {
      const migrationKeyCount = Object.keys(migrationData).length;
      if (migrationKeyCount > 0)
        navigate("/home/migrate/smartdatawarehousemover/results");
    }
  }, [migrationData]);

  const getSelectedRows = (selectedData: object) => {
    setSelectedRowsData(selectedData);
  };
  return (
    <div className="migrationPage">
      <div className="options">
        <div className="optionsOne">
          <label>Schema</label>
          <TextField
            InputLabelProps={{ shrink: false }}
            label=""
            select
            sx={style}
            value={selectedSchema}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSelectedSchema(e.target.value)
            }
          >
            {schemaOptions?.map((option) => {
              return (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              );
            })}
          </TextField>
        </div>
        <div className="optionsTwo">
          <label>Size</label>
          <FormControlLabel
            value="S"
            checked={checked[0]}
            name="0"
            label="Small"
            control={<Checkbox />}
            onChange={(e) => handleChange(e)}
            labelPlacement="end"
          />
          <FormControlLabel
            value="M"
            checked={checked[1]}
            name="1"
            control={<Checkbox />}
            onChange={(e) => handleChange(e)}
            label="Medium"
            labelPlacement="end"
          />
          <FormControlLabel
            value="L"
            checked={checked[2]}
            name="2"
            control={<Checkbox />}
            onChange={(e) => handleChange(e)}
            label="Large"
            labelPlacement="end"
          />
        </div>
        <div className="optionsThree">
          <label>Usage</label>
          <FormControlLabel
            value="Hot"
            checked={checkedUsage[0]}
            name="0"
            label="Hot"
            control={<Checkbox />}
            onChange={(e) => handleChangeUsage(e)}
            labelPlacement="end"
          />
          <FormControlLabel
            value="Warm"
            checked={checkedUsage[1]}
            name="1"
            label="Warm"
            control={<Checkbox />}
            onChange={(e) => handleChangeUsage(e)}
            labelPlacement="end"
          />
          <FormControlLabel
            value="Cold"
            checked={checkedUsage[2]}
            name="2"
            label="Cold"
            control={<Checkbox />}
            onChange={(e) => handleChangeUsage(e)}
            labelPlacement="end"
          />
        </div>
        <div className="applyButton">
          <Button variant="outlined" size="small" onClick={submitSchema}>
            Apply
          </Button>
        </div>
      </div>

      {showTable && (
        <div>
          {viewSchemaDataLoading ? (
            <AppLoader showBackDrop={false} />
          ) : (
            <div>
              <div
                style={{
                  paddingBottom: "1.5rem",
                  fontSize: "1.25rem",
                  fontWeight: "400",
                }}
              >
                Select the tables to migrate and fill in the corresponding
                details
              </div>
              <CustomDataGrid
                rowCheck={rowChecked}
                schemaData={rows}
                columns={columns}
                selectedRows={getSelectedRows}
              />
              <div className="optionsTwo">
                <p>Select which migration to apply</p>
                <FormControlLabel
                  value="Schema Migration"
                  control={<Checkbox />}
                  label="Schema Migration"
                  checked={isSchemaChecked[0]}
                  name="0"
                  labelPlacement="end"
                  onChange={(e) => checkedCollection(e)}
                />
                <FormControlLabel
                  value="Data Migration"
                  control={<Checkbox />}
                  label="Data Migration"
                  checked={isSchemaChecked[1]}
                  name="1"
                  labelPlacement="end"
                  onChange={(e) => checkedCollection(e)}
                />
                <p className="warningText">
                  <sup>*</sup>For the first migration, Schema Migration is a
                  mandatory step. It can be migrated alone or along with Data
                  migration. Otherwise, selecting Data Migration for first time
                  migration will result in failure.
                </p>
              </div>{" "}
              {!isIdAvailable && (
                <p style={{ color: "red" }}>
                  Please add Source, Plateform and Target Datasources before
                  migrate
                </p>
              )}
              <div className="floatRight">
                <Button
                  onClick={handleMigrate}
                  variant="contained"
                  size="small"
                  disabled={handleDisabled || !checkedFlag}
                >
                  Migrate &nbsp;{" "}
                  <img src="/assets/images/right-arrow.png" alt="rightArrow" />
                </Button>
              </div>
            </div>
          )}
        </div>
      )}

      {migrationLoading && <AppLoader showBackDrop={true} />}
    </div>
  );
}

export default Migration;
