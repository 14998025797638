import {
  fetchSourceCodeListLoading,
  fetchSourceCodeListSuccess,
  fetchSourceCodeListFailure,
} from "../../slices/codediscovery/SourceCodeListSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getSourceCodeList =
  (sourceId: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchSourceCodeListLoading());
    try {
      const response = await AxiosInstance.get(
        `api/pulsesdm/code_discovery/${sourceId}`
      );
      const data = response?.data;
      dispatch(fetchSourceCodeListSuccess(data));
    } catch (error: any) {
      dispatch(fetchSourceCodeListFailure(error.message));
    }
  };
