import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SdmDataSourceDataType {
  id: string;
  name: string;
  isSelected?: boolean;
}

export interface SdmDataSourceState {
  data: SdmDataSourceDataType[];
  loading: boolean;
  error: string | null;
}

const initialState: SdmDataSourceState = {
  data: [],
  loading: false,
  error: null,
};

const SdmDataSoureSlice = createSlice({
  name: "sdmdatasourceslice",
  initialState,
  reducers: {
    fetchSdmDataSourceLoading: (state) => {
      state.loading = true;
    },
    fetchSdmDataSourceSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchSdmDataSourceError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
  },
});

export const {
  fetchSdmDataSourceLoading,
  fetchSdmDataSourceSuccess,
  fetchSdmDataSourceError,
} = SdmDataSoureSlice.actions;

export default SdmDataSoureSlice.reducer;
