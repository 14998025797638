import { Box, Button, Typography } from "@mui/material";
import { AppDataCards } from "../../Shared/Components/AppDataCards";
import { useEffect, useState } from "react";
import { Dispatch } from "redux";
import { getConvertTargetData } from "../../redux/thunks/codediscovery/ConvertTargetThunk";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";
import AppLoader from "../../Shared/Components/AppLoader";
import { DataTargetDataType } from "../../redux/slices/codediscovery/ConvertTargetSlice";
import { addTargetSelectedData } from "../../redux/slices/SelectedConvertTargetDataSlice";
import { Link as RouteLink } from "react-router-dom";
import "./ConvertTarget.css";

const conversionNote = {
  display: "flex",
  flexDirection: "row",
  marginLeft: "1rem",
  paddingTop: "2rem",
};
const conversionText = {
  fontSize: "1rem",
  paddingLeft: "0.5rem",
  fontWeight: "600",
};
const ConvertTarget: any = () => {
  const [showTargetSource, setShowTargetSource] = useState<boolean>(true);

  const [updatedTargetDataList, setUpdatedTargetDataList] = useState<
    DataTargetDataType[] | []
  >([]);
  const dispatch: Dispatch<any> = useDispatch();
  const { loading: loading, data: convertTargetData } = useSelector(
    (state: RootState) => state.CodeConvert.ConvertTargetDataList
  );
  const selectedConvertTargetReducer = useSelector(
    (state: RootState) => state.CodeConvert.selectedConvertTargetReducer
  );

  useEffect(() => {
    dispatch(getConvertTargetData());
  }, [dispatch]);

  useEffect(() => {
    const manipulatedList = convertTargetData?.map((item) => {
      return { ...item, isSelected: false };
    });
    setUpdatedTargetDataList(manipulatedList);
  }, [convertTargetData]);

  const updateSelectedConverTargetDatas = (ID: string) => {
    const selected = updatedTargetDataList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedTargetDataList(selected);
    dispatch(addTargetSelectedData(selected));
  };
  const handleContinueClick: () => void = () => {
    setShowTargetSource(!showTargetSource);
  };
  return (
    <>
      <Box className="dataSourcePageDatabaseContainer">
        <Box className="dataSourcePageDataSource">
          {loading && <AppLoader showBackDrop={false} />}
          {updatedTargetDataList?.map((item: any, index) => (
            <AppDataCards
              key={index}
              handleClick={updateSelectedConverTargetDatas}
              dataItem={item}
            />
          ))}
        </Box>
        {selectedConvertTargetReducer.data.length > 0 && (
          <Box sx={conversionNote}>
            <img src="/assets/images/etl.svg" alt="elt" />
            <Typography sx={conversionText}>
            Code conversion module will proceed with the conversion to [{selectedConvertTargetReducer.data[0].name}].
            </Typography>
          </Box>
        )}
        <Box className="dataSourcePageContainer">
          <Button
            size="large"
            variant="contained"
            disabled={selectedConvertTargetReducer.data.length === 0}
            component={RouteLink}
            to={"/home/convert/codeconversion/convertconversion"}
            onClick={handleContinueClick}
          >
            <span className="dataSourcePageBtnImage">Continue </span>
            <img src="/assets/images/right-arrow.png" alt="Add" />
          </Button>
        </Box>{" "}
      </Box>
    </>
  );
};
export default ConvertTarget;
