export interface IPageData {
  path: string;
  title: string;
  subTitle: string;
  activeStep: number;
  pageType: string;
  linkInBreadCrumb: boolean;
}

export const HeadingData: Array<IPageData> = [
  {
    path: "/home",
    title: "Data Migration Assets",
    subTitle: "",
    activeStep: 0,
    pageType: "",
    linkInBreadCrumb: true,
  },
  {
    path: "/home/discover",
    title: "Discover",
    subTitle: "",
    activeStep: 0,
    pageType: "",
    linkInBreadCrumb: true,
  },
  {
    path: "/home/migrate",
    title: "Migrate",
    subTitle: "",
    activeStep: 0,
    pageType: "",
    linkInBreadCrumb: true,
  },
  {
    path: "/home/convert",
    title: "Convert",
    subTitle: "",
    activeStep: 0,
    pageType: "",
    linkInBreadCrumb: true,
  },
  {
    path: "/home/validate",
    title: "Validate",
    subTitle: "",
    activeStep: 0,
    pageType: "",
    linkInBreadCrumb: true,
  },
  {
    path: "/home/discover/datadiscovery",
    title: " Data Discovery",
    subTitle: "",
    activeStep: 0,
    pageType: "",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/discover/codediscovery",
    title: " Code Discovery",
    subTitle: "",
    activeStep: 0,
    pageType: "",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/migrate/smartdatawarehousemover",
    title: "Smart DataWarehouse Mover",
    subTitle: "",
    activeStep: 0,
    pageType: "",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/convert/codeconversion",
    title: "Code Conversion",
    subTitle: "",
    activeStep: 0,
    pageType: "",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/validate/smartdatavalidator",
    title: "Smart Data Validator",
    subTitle: "",
    activeStep: 0,
    pageType: "",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/discover/datadiscovery/datasource",
    title: "Data source",
    subTitle: "Select your data source",
    activeStep: 0,
    pageType: "discover",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/discover/datadiscovery/metadataexecution",
    title: "Metadata execution",
    subTitle:
      "In this step we run Python/SQL based scripts to extract the required metadata from the source database environment",
    activeStep: 1,
    pageType: "discover",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/discover/datadiscovery/discoverysummary",
    title: "Discovery",
    subTitle: "Review insights and recommendations for migration planning",
    activeStep: 2,
    pageType: "discover",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/discover/datadiscovery/migrationwave",
    title: "Migration wave",
    subTitle: "",
    activeStep: 3,
    pageType: "discover",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/migrate/smartdatawarehousemover/migrationtype",
    title: "Migration type",
    subTitle: "Select your migration type",
    activeStep: 0,
    pageType: "migrate",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/migrate/smartdatawarehousemover/migrationdata",
    title: "Migration",
    subTitle: "Select your migration type",
    activeStep: 4,
    pageType: "migrate",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/migrate/smartdatawarehousemover/discoveryoverview",
    title: "Discovery overview",
    subTitle: "",
    activeStep: 2,
    pageType: "migrate",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/migrate/smartdatawarehousemover/results",
    title: "Results",
    subTitle: "Review your migration results",
    activeStep: 5,
    pageType: "migrate",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/migrate/smartdatawarehousemover/migrationhistory",
    title: "Migration history",
    subTitle: "Review your migration results",
    activeStep: 4,
    pageType: "migrate",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/migrate/smartdatawarehousemover/migration",
    title: "Migration",
    subTitle: "Select the schema to migrate",
    activeStep: 4,
    pageType: "migrate",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/migrate/smartdatawarehousemover/target",
    title: "Target platform",
    subTitle: " Select your cloud service platform",
    activeStep: 3,
    pageType: "migrate",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/migrate/smartdatawarehousemover/datasource",
    title: "Data source",
    subTitle: " Select your data source",
    activeStep: 1,
    pageType: "migrate",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/convert/codeconversion/converttarget",
    title: "Target",
    subTitle: " Select your target",
    activeStep: 0,
    pageType: "convert",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/convert/codeconversion/convertconversion",
    title: "Code Conversion",
    subTitle: "",
    activeStep: 1,
    pageType: "convert",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/convert/codeconversion/convertresults",
    title: "Results",
    subTitle: "",
    activeStep: 2,
    pageType: "convert",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/discover/codediscovery/sourcecode",
    title: "Source code",
    subTitle: " Select your code source",
    activeStep: 0,
    pageType: "codeDiscovery",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/discover/codediscovery/execution",
    title: "Execution",
    subTitle:
      " Source code overview you can click on view code or add another one.",
    activeStep: 1,
    pageType: "codeDiscovery",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/discover/codediscovery/coderesult",
    title: "Results",
    subTitle: " Select your code source",
    activeStep: 2,
    pageType: "codeDiscovery",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/validate/smartdatavalidator/sourcesystemtype",
    title: "Source system type",
    subTitle: " Select your system type",
    activeStep: 0,
    pageType: "validate",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/validate/smartdatavalidator/sourceandtarget",
    title: "Source and target",
    subTitle: " Make the configuration for the chosen data source type",
    activeStep: 1,
    pageType: "validate",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/validate/smartdatavalidator/configuration",
    title: "Configuration",
    subTitle:
      " In order to configurate your data, please fill all the tabs to move to the next step",
    activeStep: 2,
    pageType: "validate",
    linkInBreadCrumb: false,
  },
  {
    path: "/home/validate/smartdatavalidator/validation",
    title: "Validation",
    subTitle:
      " This last step shows the validation process after configuration, in the following table you can see the obtained results. ",
    activeStep: 3,
    pageType: "validate",
    linkInBreadCrumb: false,
  },
];
