import React, { useState, useEffect } from "react";
import { Box, Button, Dialog, IconButton, TextField } from "@mui/material";
import "../../../styles/SdvModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/Store";
import Encrypt from "../../../core/Encrypt";
interface GcpModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  isEdit?: boolean;
  handleSave?: (payload: string) => void;
  handleEditSave?: (dbid: string, secretid: string, payload: string) => void;
  dbId?: string;
  secretId?: string;
}

export interface GcpFormState {
  ConfigurationTitle: string;
  bucketname: string;
  fileformat: string;
}

const GcpModal: React.FC<GcpModalProps> = ({
  open,
  onClose,
  title,
  isEdit,
  handleSave,
  handleEditSave,
  dbId,
  secretId,
}) => {
  const initialFormValue: GcpFormState = {
    ConfigurationTitle: "",
    bucketname: "",
    fileformat: "",
  };
  const [formData, setFormData] = useState<GcpFormState>(initialFormValue);

  const hanldeFormInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };

  const allConfigurationListsdv = useSelector(
    (state: RootState) => state.Sdv.ConfigurationDetailsSlice
  );

  const selectedConfig = allConfigurationListsdv?.data?.filter(
    (item) => Object.keys(item)[0] === secretId
  )[0];

  useEffect(() => {
    if (isEdit && secretId && selectedConfig) {
      setFormData({
        ConfigurationTitle:
          selectedConfig[secretId]?.ConfigurationTitle?.toString(),
        bucketname: selectedConfig[secretId]?.bucketname?.toString(),
        fileformat: selectedConfig[secretId]?.fileformat?.toString(),
      });
    }
  }, [isEdit, secretId, selectedConfig]);

  const handleSaveConfigClick = () => {
    if (!isEdit) {
      handleSave && handleSave(Encrypt(formData));
    } else {
      if (dbId && secretId && formData) {
        handleEditSave && handleEditSave(dbId, secretId, Encrypt(formData));
      }
    }
    onClose();
  };

  const regex = /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/;
  const validationCheck = () => {
    if (!regex.test(formData?.ConfigurationTitle)) return true;
    return false;
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <div>
        <Box className="SdvModalTitleContainer">
          <div className="SdvModalTitle">{title}</div>
          <IconButton onClick={onClose}>
            <CloseIcon className="SdvModalTitleClose" />
          </IconButton>
        </Box>
        <div className="sdvModalDesc">
          Enter the parameter configuration data. Title is mandatory field.
        </div>
        <div className="GcpContainer" style={{}}>
          <div>
            <label>Configuration Title</label>
            <TextField
              required
              placeholder="Configuration Title"
              fullWidth
              name="ConfigurationTitle"
              value={formData.ConfigurationTitle}
              onChange={hanldeFormInputChange}
            />
          </div>
          <div>
            <label>Bucket Name</label>
            <TextField
              required
              placeholder="Bucket Name"
              fullWidth
              name="bucketname"
              value={formData.bucketname}
              onChange={hanldeFormInputChange}
            />
          </div>
          <div>
            <label>File Format</label>
            <TextField
              required
              placeholder="File Format"
              fullWidth
              name="fileformat"
              value={formData.fileformat}
              onChange={hanldeFormInputChange}
            />
          </div>
          <div
            className="gcpBtnContainer"
            style={{ marginTop: "2rem", marginBottom: "1rem" }}
          >
            <Button
              variant="contained"
              size="medium"
              className="saveConfigBtn"
              onClick={handleSaveConfigClick}
              disabled={validationCheck()}
            >
              Save Configuration
            </Button>
          </div>
        </div>
      </div>
    </Dialog>
  );
};

export default GcpModal;
