import {
  Paper,
  Table,
  TableBody,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import { StyledTableCell, StyledTableRow } from "../../Shared/Utils/HelperFunc";

import "../../styles/MigrationHistoryTable.css";
import "../../styles/Validation.css";

interface RowData {
  compute_size: string;
  job_status: string;
  validation_status: string;
  time_taken: string;
}

const ValidationTable = (props: {
  titleData: string[];
  rowData: RowData[];
}) => {
  const formattedTime = (time: string) => {
    return time.substring(0, time.indexOf("."));
  };
  return (
    <>
      <Typography variant="h4" sx={{ pb: 2, pl: 0 }}>
        Validation table
      </Typography>

      <TableContainer component={Paper} sx={{ maxHeight: 344 }}>
        <Table sx={{ minWidth: 700 }} stickyHeader>
          <TableHead sx={{ height: 56 }}>
            <TableRow>
              {props.titleData.map((headingRow: string, index: number) => {
                return (
                  <StyledTableCell key={index}>{headingRow}</StyledTableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.rowData.map((row: RowData) => (
              <StyledTableRow key={row?.compute_size}>
                <StyledTableCell
                  sx={{ fontWeight: "600" }}
                  component="th"
                  scope="row"
                >
                  {row.compute_size}
                </StyledTableCell>
                <StyledTableCell>
                  <div
                    className={
                      row["job_status"].toLocaleLowerCase() === "failure"
                        ? "failedData "
                        : row["job_status"].toLocaleLowerCase() === "success"
                        ? "successData"
                        : "inprogressData"
                    }
                  >
                    <p className="dataStyle ">● &nbsp; {row["job_status"]}</p>
                  </div>
                </StyledTableCell>
                <StyledTableCell sx={{ fontWeight: "600" }}>
                  <div className={"validationImageAlign"}>
                    {row?.validation_status.toLocaleLowerCase() === "pass" ? (
                      <img src="/assets/images/Done_icon.svg" alt="done" />
                    ) : row?.validation_status.toLocaleLowerCase() ===
                      "error" ? (
                      <img src="/assets/images/Error_icon.svg" alt="error" />
                    ) : (
                      ""
                    )}
                    <p className="validationDataStyle">
                      {row?.validation_status}
                    </p>
                  </div>
                </StyledTableCell>
                <StyledTableCell>
                  {formattedTime(row?.time_taken)}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
};

export default ValidationTable;
