import { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import {
  Toolbar,
  IconButton,
  Menu,
  Avatar,
  Tooltip,
  MenuItem,
  Typography,
  MenuList,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { InteractionType } from "@azure/msal-browser";
import { fetchProfileInfo } from "../Utils/graphAPIHelper";

import "../../styles/AppHeader.css";
import deepPurple from "@mui/material/colors/deepPurple";
/**
 * Implementation of App Header
 * @returns header of the app
 */
const AppHeader = () => {
  const [toggleUserMenu, setToggleUserMenu] = useState<null | HTMLElement>(
    null
  );
  const [username, setUserName] = useState<string>("");
  const [profileData, setProfileData] = useState<string>("");
  const { instance } = useMsal();

  const openUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setToggleUserMenu(event.currentTarget);
  };
  const closeUserMenu = () => {
    setToggleUserMenu(null);
  };

  const handleSignOut = () => {
    closeUserMenu();
    instance.logoutRedirect();
  };

  const stringAvatar = (name: string) => {
    return {
      sx: {
        bgcolor: deepPurple[500],
      },
      children: `${name.split(" ")[0][0]}${name.split(" ")[1][0]}`,
    };
  };

  useEffect(() => {
    let flag = false;

    instance
      .acquireTokenSilent({
        scopes: ["user.read"],
      })
      .then((response) => {
        fetchProfileInfo(response.accessToken).then((response: any) =>
          setProfileData(response?.displayName)
        );
      })
      .catch((error) => {});

    return () => {
      flag = true;
    };
  }, [profileData]);

  useEffect(() => {
    // To get the signed in account of the user
    const currentAccount = instance.getActiveAccount();
    if (currentAccount) {
      setUserName(currentAccount.username);
    }
  }, [instance]);

  return (
    <AppBar>
      <Toolbar>
        <Link className="AppHeaderLogo" to="/home">
          <img src={"/assets/images/logo.png"} alt="Logo" />
        </Link>
        <Box className="dividerContainer">
          <img
            className="AppHeaderDivider"
            src={"/assets/images/logo_divider.png"}
            alt="Logo Divider"
          />
        </Box>
        <div className="AppHeaderHeading">Data Migration Assets</div>
        <Box className="AppHeaderContainer">
          <IconButton className="AppHeaderIconBtn" onClick={openUserMenu}>
            {profileData ? <Avatar {...stringAvatar(profileData)} /> : null}
          </IconButton>
          <Menu
            className="AppHeaderMenuIcon"
            id="menu-appbar"
            anchorEl={toggleUserMenu}
            anchorOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            keepMounted
            transformOrigin={{
              vertical: "top",
              horizontal: "right",
            }}
            open={Boolean(toggleUserMenu)}
            onClose={closeUserMenu}
          >
            <MenuList>
              <MenuItem onClick={closeUserMenu}>{username}</MenuItem>
              <MenuItem onClick={handleSignOut}>Sign Out</MenuItem>
            </MenuList>
          </Menu>
        </Box>
      </Toolbar>
    </AppBar>
  );
};
export { AppHeader };
