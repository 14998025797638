import { Routes, Route, createBrowserRouter, RouterProvider } from "react-router-dom";
import { Dispatch, useEffect } from "react";
import {
    useMsal, useIsAuthenticated   
  } from "@azure/msal-react";
  import {
     InteractionRequiredAuthError,
  } from "@azure/msal-browser";
  import { useDispatch, useSelector } from "react-redux";
  import { RootState, store } from "../redux/store/Store";
  import { getToken } from "../redux/thunks/AuthUserThunk";
  import Intro from "../pages/Intro";
  import { ProtectedRoute } from "../core/auth/ProtectedRoute";
  import { PageNotFound } from "../Shared/PageNotFound";
  import MainWrapper from "./MainWrapper";
import Logout from "../core/auth/Logout";

/**
 * Implementation of wrapper
 * @returns Wrapper to handle the intro, home and protected routes 
 */

const RouterWrapper = () => {
 const { instance } = useMsal();
 const isAuthenticated = useIsAuthenticated();
 const dispatch: Dispatch<any> = useDispatch();
  const { loading, error, userToken } = useSelector(
    (state: RootState) => state.UserData
  ); 

   //MSAL event for silent SSO
  useEffect(()=>{
    if(!isAuthenticated){
         instance.ssoSilent({
            scopes: ["User.Read"],
            loginHint:"",
        }).then((response) =>{
            instance.setActiveAccount(response.account);
        }).catch((error) =>{
            //Error occurred in MSAL silent SSO
            if(error instanceof InteractionRequiredAuthError){
              instance.acquireTokenRedirect({ scopes: ["User.Read"]}).then(
                function (response) {
                    // Successfully Logged in 
                }).catch(function (error) {
                  // initialing log out 
                  instance.logoutRedirect();
                });
              }
          }); 
   }
   else if (isAuthenticated && userToken === null) {
    //getting the token if the user is authenticated by token is null
    dispatch(getToken());
  }
  },[isAuthenticated, instance, dispatch, userToken]);

  const router = createBrowserRouter([
    {
      path:"/",
      element: (
        <ProtectedRoute>
          <Intro />
        </ProtectedRoute>     
      ),
      errorElement: (<>Error Occurred</>),
      loader: async () => {
        return await new Promise((resolve) => {
          if(store.getState().UserData?.userToken != null)
          resolve(true);         
         });
      },
    },
    {
      path:"/home/*",
      element: (
        <ProtectedRoute>
          <MainWrapper />
        </ProtectedRoute>     
      ),
      errorElement: (<>Error Occurred</>),
      loader: async () => {
        return await new Promise((resolve) => {
          if(store.getState().UserData?.userToken != null)
          resolve(true);         
         });
      },
    },
    {
      path:"/logout",
      element: (
        <Logout />   
      ),
      errorElement: (<>Error Occurred</>),     
    },
    {
      path:"*",
      element: (
        <PageNotFound />     
      ),
      errorElement: (<>Error Occurred</>)  
    },
  ]);

   return (
    <RouterProvider router={router} />
  );
};
export default RouterWrapper;
