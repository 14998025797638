import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  IconButton,
  MenuItem,
  TextField,
} from "@mui/material";
import "../../../styles/SdvModal.css";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store/Store";
import Encrypt from "../../../core/Encrypt";

interface SdvModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  isEdit?: boolean;
  handleSave?: (payload: string) => void;
  handleEditSave?: (dbid: string, secretid: string, payload: string) => void;
  dbId?: string;
  secretId?: string;
}

export interface AzureFormatFormState {
  ConfigurationTitle: string;
  authenticationtype: string;
  accountname: string;
  azcontainer: string;
  accountkey: string;
  fileformat: string;
  IsHeaderPresent: string;
  Delimiter: string;
}

const AzureModal: React.FC<SdvModalProps> = ({
  open,
  onClose,
  title,
  isEdit,
  handleSave,
  handleEditSave,
  dbId,
  secretId,
}) => {
  const initialFormValue: AzureFormatFormState = {
    ConfigurationTitle: "",
    authenticationtype: "",
    accountname: "",
    azcontainer: "",
    accountkey: "",
    fileformat: "",
    IsHeaderPresent: "",
    Delimiter: "",
  };
  const [formData, setFormData] =
    useState<AzureFormatFormState>(initialFormValue);

  const hanldeFormInputChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormData({ ...formData, [event.target.name]: event.target.value });
  };
  const allConfigurationListsdv = useSelector(
    (state: RootState) => state.Sdv.ConfigurationDetailsSlice
  );

  const selectedConfig = allConfigurationListsdv?.data?.filter(
    (item) => Object.keys(item)[0] === secretId
  )[0];

  useEffect(() => {
    if (isEdit && secretId && selectedConfig) {
      setFormData({
        ConfigurationTitle:
          selectedConfig[secretId]?.ConfigurationTitle?.toString(),
        authenticationtype:
          selectedConfig[secretId]?.authenticationtype?.toString(),
        accountname: selectedConfig[secretId]?.accountname?.toString(),
        azcontainer: selectedConfig[secretId]?.azcontainer?.toString(),
        accountkey: selectedConfig[secretId]?.accountkey?.toString(),
        fileformat: selectedConfig[secretId]?.fileformat?.toString(),
        IsHeaderPresent: selectedConfig[secretId]?.IsHeaderPresent?.toString(),
        Delimiter: selectedConfig[secretId]?.Delimiter?.toString(),
      });
    }
  }, [isEdit, secretId, selectedConfig]);

  const handleSaveConfigClick = () => {
    if (!isEdit) {
      handleSave && handleSave(Encrypt(formData));
    } else {
      if (dbId && secretId && formData) {
        handleEditSave && handleEditSave(dbId, secretId, Encrypt(formData));
      }
    }
    onClose();
  };
  const DelimiterOptions = [",", "|", ";"];
  const regex = /^[a-zA-Z0-9][a-zA-Z0-9\s]*$/;
  const validationCheck = () => {
    if (!regex.test(formData?.ConfigurationTitle)) return true;
    return false;
  };
  return (
    <Dialog open={open} onClose={onClose}>
      <div>
        <Box className="SdvModalTitleContainer">
          <div className="SdvModalTitle">{title}</div>
          <IconButton onClick={onClose}>
            <CloseIcon className="SdvModalTitleClose" />
          </IconButton>
        </Box>
        <div className="sdvModalDesc">
          Enter the parameter configuration data. Title is mandatory field.
        </div>

        <div style={{ display: "grid", padding: "0px 32px" }}>
          <label>Configuration Title</label>
          <TextField
            required
            placeholder="Configuration Title"
            fullWidth
            name="ConfigurationTitle"
            value={formData.ConfigurationTitle}
            onChange={hanldeFormInputChange}
          />
        </div>
        <div className="AzureBlobAdlsContainer" style={{}}>
          <div>
            <label>Authentication Type</label>
            <TextField
              required
              placeholder="Authentication Type"
              fullWidth
              name="authenticationtype"
              value={formData.authenticationtype}
              onChange={hanldeFormInputChange}
            />
          </div>
          <div>
            <label>Account Name</label>
            <TextField
              required
              placeholder="Account Name"
              fullWidth
              name="accountname"
              value={formData.accountname}
              onChange={hanldeFormInputChange}
            />
          </div>
          <div>
            <label>AZ Container</label>
            <TextField
              required
              placeholder="AZ Container"
              fullWidth
              name="azcontainer"
              value={formData.azcontainer}
              onChange={hanldeFormInputChange}
            />
          </div>
          <div>
            <label>accountkey</label>
            <TextField
              required
              placeholder="accountkey"
              fullWidth
              name="accountkey"
              value={formData.accountkey}
              onChange={hanldeFormInputChange}
            />
          </div>
          <div>
            <label>Delimiter</label>
            <TextField
              InputLabelProps={{ shrink: false }}
              fullWidth
              select
              name="Delimiter"
              value={formData.Delimiter}
              onChange={hanldeFormInputChange}
            >
              {DelimiterOptions.map((option) => {
                return (
                  <MenuItem key={option} value={option}>
                    {option}
                  </MenuItem>
                );
              })}
            </TextField>
          </div>
          <div>
            <label>Is Header Present</label>
            <TextField
              required
              placeholder="Is Header Present"
              fullWidth
              name="IsHeaderPresent"
              value={formData.IsHeaderPresent}
              onChange={hanldeFormInputChange}
            />
          </div>
          <div>
            <label>File Format</label>
            <TextField
              required
              placeholder="File Format"
              fullWidth
              name="fileformat"
              value={formData.fileformat}
              onChange={hanldeFormInputChange}
            />
          </div>
          <DialogActions>
            <div className="actionContainer AzureMarginBottom">
              <Button
                variant="outlined"
                size="small"
                onClick={onClose}
                sx={{ fontSize: "1.125rem" }}
              >
                Cancel
              </Button>
              <Button
                variant="contained"
                size="medium"
                onClick={handleSaveConfigClick}
                disabled={validationCheck()}
              >
                Save Configuration
              </Button>
            </div>
          </DialogActions>
        </div>
      </div>
    </Dialog>
  );
};

export default AzureModal;
