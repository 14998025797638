import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { CircularProgress, Box } from "@mui/material";

export default function AppLoader(props: { showBackDrop: boolean }) {
  return (
    <div className="appLoaderContainer">
      {props.showBackDrop ? (
        <Backdrop component="div" open={true}>
          <CircularProgress />
        </Backdrop>
      ) : (
        <Box>
          <CircularProgress />
        </Box>
      )}
    </div>
  );
}
