import * as React from "react";
import { Tabs, Tab, useMediaQuery } from "@mui/material";
import AppTabPanel from "../../Shared/Components/AppTabPanel";
import FileSystem from "./FileSystem";
import Target from "./Target";
import SdvSource from "./SdvSource";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";

import "../../styles/TabStyles.css";

const SourceAndTarget = () => {
  const [value, setValue] = useState(0);
  const [length, setLength] = useState(0);
  const [tabs, setTabs] = useState<string[]>([]);
  const [selecteData, setSelectedData] = useState("");
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };

  const isMobile = useMediaQuery("(max-width: 1000px)");

  const { data: SourceSystemTypeDataType } = useSelector(
    (state: RootState) => state.Sdv.SelectedSourceSystemTypeReducer
  );
  useEffect(() => {
    let newTabs = [];
    const selectedValue = SourceSystemTypeDataType[0].value;
    if (selectedValue === "file_on_cloud") {
      newTabs = ["File system", "Target"];
    } else if (selectedValue === "on_cloud") {
      newTabs = ["Source", "File system (optional)", "Target"];
    } else {
      newTabs = [" Data Source", "File system (optional)", "Target"];
    }
    setSelectedData(selectedValue);
    setLength(newTabs.length);
    setTabs(newTabs);
  }, [SourceSystemTypeDataType]);

  const switchTab = (tabValue: number) => {
    setValue(tabValue);
  };

  return (
    <>
      <div className="tabsContainer">
        <Tabs
          className="tabs"
          variant={isMobile ? "fullWidth" : "fullWidth"}
          orientation={isMobile ? "horizontal" : "vertical"}
          value={value}
          onChange={handleChange}
          sx={{
            minWidth: 210, // set the width of the tabs here
            borderRight: 1,
            borderColor: "transparent",
          }}
        >
          <Tab className="tab" disableRipple={true} label={tabs[0]} />
          <Tab className="tab" disableRipple={true} label={tabs[1]} />
          {length === 3 && (
            <Tab className="tab" disableRipple={true} label={tabs[2]} />
          )}
        </Tabs>
        {selecteData !== "file_on_cloud" ? (
          <>
            <AppTabPanel value={value} index={0}>
              <SdvSource
                isSourceAvailable={selecteData === "on_cloud" ? true : false}
                switchTab={switchTab}
              />
              {/* <SdvSource switchTab={switchTab} isSourceAvailable={false} /> */}
            </AppTabPanel>
            <AppTabPanel value={value} index={1}>
              <FileSystem switchTab={switchTab} value={2} />
            </AppTabPanel>
            <AppTabPanel value={value} index={2}>
                <Target />
            </AppTabPanel>
          </>
        ) : (
          <>
            <AppTabPanel value={value} index={0}>
              <FileSystem switchTab={switchTab} value={1} />
            </AppTabPanel>
            <AppTabPanel value={value} index={1}>
              <Target />
            </AppTabPanel>
          </>
        )}
      </div>
    </>
  );
};

export default SourceAndTarget;
