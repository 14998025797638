import {
  fetchfileSystemListLoading,
  fetchfileSystemListSuccess,
  fetchfileSystemListError,
} from "../../slices/sdvslices/FileSystemListSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getFileSystemList =
  (systemType: string, cloudId: string) =>
  async (dispatch: AppThunkDispatch) => {
    dispatch(fetchfileSystemListLoading());
    try {
      const response = await AxiosInstance.get(
        `api/sdv/sdv_list_db/file_system/${systemType}?cloud_id=${cloudId}`
      );
      const data = response?.data?.document;
      dispatch(fetchfileSystemListSuccess(data));
    } catch (error: any) {
      dispatch(fetchfileSystemListError(error.message));
    }
  };
