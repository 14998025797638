import React from "react";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Typography } from "@mui/material";
import "../../../styles/MigrationHistoryTable.css";
import {
  StyledTableCell,
  StyledTableRow,
} from "../../../Shared/Utils/HelperFunc";
import "../../../styles/Results.css";
// for Pagination uncomment the following line and related code in lines 36-48 & 54-62 & 76
// import { TablePagination } from "@mui/material";

function ResultTable(props: { data: any; titleData: any }) {
  return (
    <>
      <Typography variant="h4" sx={{ pb: 2, pl: 0 }}>
        Migration table
      </Typography>

      <TableContainer component={Paper} sx={{ maxHeight: 344 }}>
        <Table sx={{ minWidth: 700 }} stickyHeader>
          <TableHead sx={{ height: 56 }}>
            <TableRow>
              {props.titleData.map((row: any, index: any) => {
                return <StyledTableCell key={index}>{row}</StyledTableCell>;
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.map((row: any) => (
              <StyledTableRow key={row?.object_name}>
                <StyledTableCell
                  sx={{ fontWeight: "600" }}
                  component="th"
                  scope="row"
                >
                  {row?.object_name}
                </StyledTableCell>
                <StyledTableCell>{row?.source_row_count}</StyledTableCell>
                <StyledTableCell>{row?.target_row_count}</StyledTableCell>
                <StyledTableCell>{row?.content_match}</StyledTableCell>
                <StyledTableCell>
                  <div
                    className={
                      row?.migration_status.toLocaleLowerCase() === "failure"
                        ? "failedData "
                        : row?.migration_status.toLocaleLowerCase() ===
                          "success"
                        ? "successData"
                        : "inprogressData"
                    }
                  >
                    <p className="dataStyle ">
                      ● &nbsp; {row?.migration_status}
                    </p>
                  </div>
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}

export default ResultTable;
