import {
  AnyAction,
  combineReducers,
  configureStore,
  ThunkDispatch,
} from "@reduxjs/toolkit";
import { createLogger } from "redux-logger";

import AuthReducer from "../slices/AuthSlice";
import PulseCombinedReducers from "../combinedreducers/PulseReducers";
import CommonReducer from "../combinedreducers/CommonReducer";
import DiscoverOverviewReducers from "../combinedreducers/DiscoveryOverviewReducer";
import SdmReducers from "../combinedreducers/SdmReducers";
import CodeDiscoveryReducer from "../combinedreducers/CodediscoveryReducer";
import CodeConvertReducer from "../combinedreducers/CodeConvertReducer";
import SdvReducers from "../combinedreducers/SdvReducers";

const loggerMiddleware = createLogger({
  predicate: (getState, action) =>
    process.env.REACT_APP_LOGGER === "development",
});

const rootReducer = {
  Pulse: combineReducers(PulseCombinedReducers),
  Common: combineReducers(CommonReducer),
  DiscoveryOverview: combineReducers(DiscoverOverviewReducers),
  Sdm: combineReducers(SdmReducers),
  CodeDiscovery: combineReducers(CodeDiscoveryReducer),
  CodeConvert: combineReducers(CodeConvertReducer),
  Sdv: combineReducers(SdvReducers),
  UserData: AuthReducer,
};

export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(loggerMiddleware),
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;

export type AppThunkDispatch = ThunkDispatch<RootState, unknown, AnyAction>;
