import { Checkbox, Typography } from "@mui/material";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store/Store";
import { getViewSourceCode } from "../../../redux/thunks/codediscovery/ViewSourceCodeThunk";
import AppLoader from "../../../Shared/Components/AppLoader";
import AppModal from "../../../Shared/Components/Modals/AppModal";
import "../../../styles/ConvertConversion.css";

interface SourceCodeListProps {
  selectedTargetId: string;
  checkedItems: Map<string, boolean>;
  setCheckedItems: React.Dispatch<React.SetStateAction<Map<string, boolean>>>;
  setIsExecuteButtonDisabled: React.Dispatch<React.SetStateAction<boolean>>;
}

const SourceCodeList = ({
  selectedTargetId,
  checkedItems,
  setCheckedItems,
  setIsExecuteButtonDisabled,
}: SourceCodeListProps) => {
  const [isSourceCodeModalOpen, setIsSourceCodeModalOpen] = useState(false);
  const [sourceCodeName, setSourceCodeName] = useState("");
  const dispatch: any = useDispatch();

  function hasTrueValue(map: Map<string, boolean>) {
    const tempValues = Array.from(map).map((item) => item[1]);
    const allFalse = tempValues.every((value) => {
      return value === false;
    });
    if (tempValues.includes(true)) {
      setIsExecuteButtonDisabled(false);
    }
    if (allFalse) setIsExecuteButtonDisabled(true);
  }

  const { data: SourceCodeListData, loading } = useSelector(
    (state: RootState) => state.CodeConvert.SourceCodeListReducer
  );

  const {
    data: sourceCodeItemData,
    loading: sourceCodeItemLoading,
    // error: sourceCodeItemError,
  } = useSelector(
    (state: RootState) => state.CodeConvert.ViewSourceCodeReducer
  );

  const SourceCodeModalBody = (
    <div>
      <Typography>{sourceCodeItemData}</Typography>
    </div>
  );

  const handleSourceCodeModalClose = () => {
    setIsSourceCodeModalOpen(false);
  };

  const handleViewIcon = (fileName: string) => {
    setSourceCodeName(fileName);
    dispatch(getViewSourceCode(selectedTargetId, fileName));
    setIsSourceCodeModalOpen(true);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const item = e.target.name;
    const isChecked = e.target.checked;
    setCheckedItems((prev) => {
      const newCheckedMap = new Map(prev.set(item, isChecked));
      hasTrueValue(newCheckedMap);
      return newCheckedMap;
    });
  };

  return (
    <>
      {loading ? (
        <AppLoader showBackDrop={false} />
      ) : (
        SourceCodeListData?.map((data: any, index: number) => {
          return (
            <div
              key={index}
              style={{ borderBottom: "2px #CCCBCE solid" }}
              className="sourceCodeListContainer"
            >
              <span className="sourceCodeListItem">
                <Checkbox
                  name={data}
                  checked={checkedItems.get(data.name)}
                  onChange={handleCheckboxChange}
                  className="SourceCodeCheckBox"
                />{" "}
                {data}
              </span>
              <span className="sourceCodeListImage">
                <img
                  onClick={() => handleViewIcon(data)}
                  src="/assets/images/view-eye.svg"
                  alt="view"
                />
              </span>
            </div>
          );
        })
      )}
      {sourceCodeItemLoading ? (
        <AppLoader showBackDrop={true} />
      ) : (
        <AppModal
          open={isSourceCodeModalOpen}
          onClose={handleSourceCodeModalClose}
          title={sourceCodeName}
          body={SourceCodeModalBody}
        />
      )}
    </>
  );
};

export default SourceCodeList;
