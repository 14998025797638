import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ComputeTypeListState {
  data: string[];
  loading: boolean;
  error: string | null;
}

const initialState: ComputeTypeListState = {
  data: [],
  loading: false,
  error: null,
};

const ComputeTypeListSlice = createSlice({
  name: "computetypelistslice",
  initialState,
  reducers: {
    fetchComputeTypeListLoading: (state) => {
      state.loading = true;
    },
    fetchComputeTypeListSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchComputeTypeListError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    clearComputeTypeList: (state) => {
      state.data = [];
    },
  },
});

export const {
  fetchComputeTypeListLoading,
  fetchComputeTypeListSuccess,
  fetchComputeTypeListError,
  clearComputeTypeList,
} = ComputeTypeListSlice.actions;

export default ComputeTypeListSlice.reducer;
