import "../../../styles/Overview.css";

function Overview(props: { data: any }) {
  return (
    <div className="overviewGrid">
      <p className="overviewTitle">{props.data.heading}</p>

      <table>
        <tbody>
          {props.data.data.map((titleAndDesc: any) => (
            <tr key={titleAndDesc.title}>
              <td className="tableHeads">{titleAndDesc.title}</td>
              <td className="tableDefs">{titleAndDesc.desc}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default Overview;
