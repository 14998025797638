import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MovementDetailsType } from "../../types/MovementDetailsTypes";

export interface MovementDetailsState {
  data: MovementDetailsType | undefined;
  loading: boolean;
  error: string | null;
}

const initialState: MovementDetailsState = {
  data: undefined,
  loading: false,
  error: null,
};

const MovementDetailsSlice = createSlice({
  name: "movementdetailsslice",
  initialState,
  reducers: {
    fetchMovementLoading: (state) => {
      state.loading = true;
    },
    fetchMovementSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchMovementError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
  },
});

export const {
  fetchMovementLoading,
  fetchMovementSuccess,
  fetchMovementError,
} = MovementDetailsSlice.actions;

export default MovementDetailsSlice.reducer;
