import { Box, Typography } from "@mui/material";
import { DataSourceDataType } from "../../redux/slices/DataSourceSlice";
import "../../styles/AppDataCards.css";
interface IAppDataCardsProps {
  dataItem: DataSourceDataType;
  handleClick?: (id: string) => void;
  icon?: string;
  target?: string;
}

const AppDataCards = ({
  dataItem,
  handleClick,
  icon,
  target,
}: IAppDataCardsProps) => {
  return (
    <div className="dataSourceCard">
      <Box
        className="img AppDataCardsBoxStyle"
        // sx={dataItem?.isSelected ? activeBoxStyle : boxStyle}
        sx={{
          background: dataItem?.isSelected ? "#7500C0" : "#FAFAFA",
          boxShadow: dataItem?.isSelected ? "" : "0px 4px 4px #cfd1d0",
        }}
        onClick={() => handleClick && handleClick(dataItem?.id)}
      >
        {icon === "target" ? (
          <img
            width="31px"
            height="34px"
            src={
              dataItem?.isSelected
                ? "/assets/images/target_icon_white.svg"
                : "/assets/images/target_icon_black.svg"
            }
            alt="search"
            title="search"
          />
        ) : (
          <img
            width="31px"
            height="34px"
            src={
              dataItem?.isSelected
                ? target
                  ? "/assets/images/target_icon_white.svg"
                  : "/assets/images/database-custom.png"
                : "/assets/images/datasource.png"
            }
            alt="search"
            title="search"
          />
        )}

        <Typography
          className="AppDataCardsTitle"
          sx={{ color: dataItem?.isSelected ? "#FFFFFF" : "#000000" }}
        >
          {dataItem.name}
        </Typography>
      </Box>
      {dataItem?.isSelected && (
        <Box className="AppDataCardsCheckedIcon">
          <img
            width="32px"
            height="32px"
            style={{ padding: "4px", border: "4px", borderRadius: "100%" }}
            src="/assets/images/checked-icon.png"
            alt="checkedIcon"
          />
        </Box>
      )}
    </div>
  );
};

export { AppDataCards };
