import { Box, Button } from "@mui/material";
import React, { Dispatch, useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import "../../styles/MigrationType.css";

import {
  addMigrationType,
  MigrationTypeDataType,
} from "../../redux/slices/SelectedMigrationTypeSlice";

import { AppDataCards } from "../../Shared/Components/AppDataCards";
import { clearMigrationData } from "../../redux/slices/sdmslices/MigrationSlice";
import { clearTargetDatabase } from "../../redux/slices/sdmslices/TargetDatabaseSlice";

function MigrationType() {
  const data = [
    {
      id: "1",
      icon: "/assets/images/icons/datasource.png",
      name: "On-Prem to Cloud",
      value: "On-Prem",
      isSelected: false,
    },
    {
      id: "2",
      icon: "/assets/images/icons/datasource.png",
      name: "Cloud to Cloud",
      value: "Cloud",
      isSelected: false,
    },
  ];
  const [isBoxSelected, setIsBoxSelected] = useState(false);
  const [updatedMigrationTypeList, setUpdatedMigrationTypeList] =
    React.useState<MigrationTypeDataType[]>(data);
  const dispatch: Dispatch<any> = useDispatch();
  const updateSelectedMigrationType = (ID: string) => {
    const selected = updatedMigrationTypeList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedMigrationTypeList(selected);
    dispatch(addMigrationType(selected));
    const isAtLeastOneSelected = selected?.some((item) => item.isSelected);
    setIsBoxSelected(isAtLeastOneSelected);
  };

  useEffect(() => {
    dispatch(clearMigrationData());
    dispatch(clearTargetDatabase());
  }, []);

  return (
    <Box className="migrationTypeContainer">
      <Box className="migrationTypeAppdataCards">
        {updatedMigrationTypeList.map((data, index) => {
          return (
            <AppDataCards
              key={index}
              dataItem={data}
              handleClick={updateSelectedMigrationType}
            />
          );
        })}
      </Box>
      <Button
        className="migrationTypeMigrationHistoryButton"
        variant="text"
        size="medium"
        component={Link}
        to={"/home/migrate/smartdatawarehousemover/migrationhistory"}
      >
        <img src="/assets/images/icon_visibility.svg" alt="" />
        View Migration History
      </Button>
      <Button
        className="migrationTypeMigrationButton"
        size="large"
        variant="contained"
        component={Link}
        to={"/home/migrate/smartdatawarehousemover/datasource"}
        disabled={!isBoxSelected}
      >
        Select data source
        <img
          className="migrationTypeBtnImage"
          src="/assets/images/right-arrow.png"
          alt="Add"
        />
      </Button>
    </Box>
  );
}

export default MigrationType;
