import { Navigate , useLocation } from 'react-router-dom';
import { useIsAuthenticated } from "@azure/msal-react";

export const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  let isUserAuthenticated:boolean = useIsAuthenticated();
 
  // TODO: To place the Role based authorization here 
  if (isUserAuthenticated === false) {
    return null;
  }
   return children;
};
