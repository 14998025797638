import { Button } from "@mui/material";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MigrationProgress from "../Results/MigrationProgress";
import Overview from "../Results/Overview";
import MigrationHistoryTable from "./MigrationHistoryTable";
import { useDispatch, useSelector } from "react-redux";
import { getMigrationHistory } from "../../../redux/thunks/sdmthunks/MigrationHistoryThunk";
import { RootState } from "../../../redux/store/Store";
import { MigrationHistoryType } from "../../../redux/slices/sdmslices/MigrationHistorySlice";
import "../../../styles/MigrationHistory.css";
import AppLoader from "../../../Shared/Components/AppLoader";

const schemasByUsage = {
  title: "Migration Progress",
  legendTypeOne: " Success ",
  legendTypeTwo: " In Progress ",
  legendTypeThree: " Failure ",
};

function MigrationHistory() {
  const [columnTitles, setColumTiltes] = useState<string[] | []>([]);
  const [properRowData, setProperRowData] = useState<{}[] | []>([]);
  const [migrationProgressData, setMigrationProgressData] = useState<
    number[] | []
  >([]);
  const [latestMigration, setLatestMigration] = useState<
    MigrationHistoryType | {}
  >({});
  const [jobOverviewData, setJobOverviewData] = useState<any>([]);
  const dispatch: any = useDispatch();
  const { data: migrationHistoryData, loading: migrationHistoryLoading } =
    useSelector((state: RootState) => state.Sdm.MigrationHistoryReducer);

  const navigate = useNavigate();
  const BackClick = () => {
    navigate(-1);
  };

  useEffect(() => {
    dispatch(getMigrationHistory());
  }, [dispatch]);
  useEffect(() => {
    if (migrationHistoryData) {
      if (migrationHistoryData.length > 0) {
        const tempData = Object.keys(migrationHistoryData[0]);
        const sortedData = migrationHistoryData.slice().sort(function (a, b) {
          return b.MovementID - a.MovementID;
        });
        setProperRowData(sortedData);
        setLatestMigration(sortedData[0]);
        setColumTiltes(tempData);
        const successCount = migrationHistoryData.filter(
          (item: MigrationHistoryType) =>
            item?.Migration_status.toLowerCase() === "success"
        ).length;
        const failureCount = migrationHistoryData.filter(
          (item: MigrationHistoryType) =>
            item?.Migration_status.toLowerCase() === "failure"
        ).length;
        const inProgressCount = migrationHistoryData.filter(
          (item: MigrationHistoryType) =>
            item?.Migration_status.toLowerCase() === "in-progress"
        ).length;
        setMigrationProgressData([successCount, inProgressCount, failureCount]);
      }
    }
  }, [migrationHistoryData]);

  useEffect(() => {
    let arr = [];
    const temp = latestMigration;
    for (const [k, v] of Object.entries(temp)) {
      if (k === "StartDate&time" || k === "EndDate&time") {
        const formattedTime = v.substring(0, v.indexOf("."));
        arr.push({ title: k, desc: formattedTime });
      } else {
        arr.push({ title: k, desc: v });
      }
    }
    setJobOverviewData(arr);
  }, [latestMigration]);

  const data = {
    datasets: [
      {
        label: "",
        data: migrationProgressData,
        borderWidth: 0.5,
        backgroundColor: ["#18AA18", "#F1984C", "#EB5757"],
      },
    ],
  };

  const titlesAndDesc = {
    heading: "Movement Status Summary",
    data: jobOverviewData,
  };

  return (
    <>
      {migrationHistoryLoading ? (
        <AppLoader showBackDrop={false} />
      ) : (
        <>
          <div className="migrationHistoryTopContainer">
            <div className="migrationHistoryContainer">
              <MigrationProgress data={schemasByUsage} optionData={data} />
            </div>

            <div className="migrationHistoryContainer">
              <Overview data={titlesAndDesc} />
            </div>
          </div>
          <div className="migrationHistoryTableContainer">
            <MigrationHistoryTable
              data={properRowData}
              titleData={columnTitles}
            />
          </div>
          <div className="migrationHistoryButtonContainer">
            <Button variant="outlined" size="medium">
              <span className="migrationHistoryBtnStyle" onClick={BackClick}>
                <img src="/assets/images/icon_back_arrow.svg" alt="back" />
                Back
              </span>
            </Button>
          </div>
        </>
      )}
    </>
  );
}

export default MigrationHistory;
