import { Box, Typography, Button, Link, RadioGroup } from "@mui/material";
import { Link as RouterLink, useLocation } from "react-router-dom";

import AppConfiguration from "../Shared/Components/AppConfiguration";
import { useEffect, useState } from "react";
import { AppDataCards } from "../Shared/Components/AppDataCards";
import Popups from "../Shared/Components/AppPopups";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store/Store";
import {
  addDbConfiguration,
  deleteDbDetail,
  editDbConfiguration,
  getDbAllDetails,
} from "../redux/thunks/DbAllDetailsThunk";
import AppLoader from "../Shared/Components/AppLoader";
import DialogForm from "../Shared/Components/DialogForm";
import "../styles/DataConfig.css";

interface DataConfigProps {
  showDataSource?: boolean;
  handleClick?: () => void;
  handleClickTarget?: () => void;
  target?: string;
  pathName?: string;
}
const DataConfigPage = ({
  showDataSource,
  handleClick,
  handleClickTarget,
  target,
  pathName,
}: DataConfigProps) => {
  const [toggleForm, setToggleForm] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [selection, setSelection] = useState("");
  const location = useLocation();

  const {
    data: dbAllDetailsList,
    loading,
    error,
  } = useSelector((state: RootState) => state.Pulse.DbAllDetailsReducer);
  const dispatch: any = useDispatch();

  const handleRadioSelection = (e: any) => {
    setSelection(e.target.value);
  };

  const selectedDataSource = useSelector(
    (state: RootState) => state.Common.SelectedDataSourceReducer
  );

  const selectedDataSourceForTarget = useSelector(
    (state: RootState) => state.Sdm.SelectedCloudReducer
  );
  const selectedTargetDataSource = useSelector(
    (state: RootState) => state.Sdm.TargetDatabaseReducer
  );
  const selectedTargetDB = useSelector(
    (state: RootState) => state.Sdm.SelectedTargetDbReducer
  );

  const selectedDataSourceId =
    selectedDataSource.data.length === 0
      ? selectedTargetDataSource.data[0].id
      : selectedDataSource.data[0].id;
  useEffect(() => {
    if (!showDataSource) {
      dispatch(getDbAllDetails(selectedDataSourceId));
    }
  }, [showDataSource, dispatch, selectedDataSourceId]);

  const handleAppConfigDeleteClick = (db_id: string, secret_Id: string) => {
    dispatch(deleteDbDetail(db_id, secret_Id));
  };

  const handleAddConfigClick = () => {
    setToggleForm(!toggleForm);
    setIsEditable(false);
    setButtonStatus("Connect");
  };
  const handleClickForConfirm = () => {
    setOpen(true);
  };
  const handleRetrieveBackClick = () => {
    handleClick && handleClick();
    setButtonStatus("Continue");
  };
  const handleRetrieveBackClickTarget = () => {
    handleClickTarget && handleClickTarget();
    setButtonStatus("Continue");
  };
  const [buttonStatus, setButtonStatus] = useState("Connect");
  const [open, setOpen] = useState(false);
  const ModalCloseFunc = (modalClose: any) => {
    setOpen(modalClose);
  };
  const data: any = [
    {
      title: "Successfully Connected",
      src: "/assets/images/icon_check.svg",
      desc: target
        ? "Target connectivity successfully established. Choose 'Continue' to proceed."
        : "Data source connectivity successfully established. Choose 'Continue' to proceed.",
      btns: [
        {
          btnTitle: "Continue",
          btnType: "contained",
          btnfunc: () => {
            setOpen(false);
          },
        },
      ],
      path: pathName,
    },
  ];
  const handleFormSaveClick = (payload: string) => {
    dispatch(addDbConfiguration(selectedDataSourceId, payload));
  };

  const handleEditSaveButtonClick = (
    dbID: string,
    secretID: string,
    payload: string
  ) => {
    dispatch(editDbConfiguration(dbID, secretID, payload));
  };

  return (
    <Box className="dataConfigContainer">
      <Box className="dataConfigDataSource">
        {target
          ? selectedDataSourceForTarget?.data?.map((item: any, index: any) => (
              <AppDataCards key={index} dataItem={item} target="target" />
            ))
          : selectedDataSource?.data?.map((item: any, index: any) => (
              <AppDataCards key={index} dataItem={item} />
            ))}
      </Box>
      {target && (
        <div>
          <div className="targetSelectedText">Selected target system</div>
          {selectedTargetDB?.data?.map((item: any, index: any) => (
            <AppDataCards key={index} dataItem={item} />
          ))}
        </div>
      )}
      <Typography className="dataConfigDataCardDesc" variant="subtitle2">
        {target ? (
          <>Select the configuration to connect to the target</>
        ) : (
          <>Select the configuration to connect to the data</>
        )}
      </Typography>
      {loading && <AppLoader showBackDrop={false} />}{" "}
      <RadioGroup
        name="RadioGroup"
        value={selection}
        onChange={handleRadioSelection}
      >
        {!showDataSource &&
          dbAllDetailsList &&
          dbAllDetailsList.length > 0 &&
          dbAllDetailsList.map((item, index) => (
            <AppConfiguration
              key={index}
              indexKey={index}
              radioProps={{ value: index, label: index }}
              dbConfigDetails={item}
              dbId={selectedDataSourceId}
              handleDeleteClick={handleAppConfigDeleteClick}
              handleEdit={handleEditSaveButtonClick}
              isEditable={isEditable}
              setIsEditable={setIsEditable}
              title={
                target === "target"
                  ? "Target Configuration"
                  : "Data Source Configuration"
              }
              isSdv={false}
            />
          ))}
      </RadioGroup>
      <Box className="dataConfigAddNewConfigBtn">
        <div>
          <Link
            component="button"
            variant="inherit"
            underline="none"
            onClick={handleAddConfigClick}
            className="dataConfigAddNewConfigBtnLink"
          >
            <img
              className="dataConfigAddNewConfigBtnImg"
              src="/assets/images/add.png"
              alt="Add"
            />
            Add new configuration
          </Link>
        </div>
      </Box>
      <Box className="dataConfigSwitchContainer">
        <Button
          className="dataConfigSwitchBtn"
          size="large"
          variant="outlined"
          color="inherit"
          onClick={
            target ? handleRetrieveBackClickTarget : handleRetrieveBackClick
          }
          component={RouterLink}
          to={location.pathname}
        >
          <img
            className="dataConfigSwitchBtnImg"
            src="/assets/images/icon_back_arrow.svg"
            alt="Add"
          />
          {target ? "Switch cloud platform " : "Switch data source"}
        </Button>
        <Button
          className="dataConfigSwitchBtn2"
          size="large"
          variant="contained"
          onClick={handleClickForConfirm}
          disabled={!selection}
        >
          {buttonStatus}
          <img
            className="dataConfigSwitchBtn2Img"
            src="/assets/images/right-arrow.png"
            alt="Add"
          />
        </Button>
        <Popups
          fullData={data}
          btnOpen={open}
          btnClose={!open}
          modalClose={ModalCloseFunc}
        />
      </Box>
      {toggleForm ? (
        <DialogForm
          title={
            target === "target"
              ? "Target Configuration"
              : "Data Source Configuration"
          }
          setToggleForm={setToggleForm}
          isEdit={isEditable}
          handleSave={handleFormSaveClick}
          isSdv={false}
        />
      ) : null}
    </Box>
  );
};

export default DataConfigPage;
