import { Button } from "@mui/material";
import React, { useEffect, useState } from "react";
import Popups from "../../../Shared/Components/AppPopups";
import "../../../styles/ConvertConversion.css";
import SourceCodeList from "./SourceCodeList";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store/Store";
import { Dispatch } from "redux";
import { getSourceCodeList } from "../../../redux/thunks/codediscovery/SourceCodeListThunk";

function ConvertConversion() {
  const dispatch: Dispatch<any> = useDispatch();
  const [open, setOpen] = useState(false);
  const [checkedItems, setCheckedItems] = useState(new Map());
  const [isExecuteButtonDisabled, setIsExecuteButtonDisabled] = useState(true);
  const ModalCloseFunc = (modalClose: any) => {
    setOpen(modalClose);
  };
  let navigate = useNavigate();
  const modalData: any = [
    {
      title: "Successfully Completed",
      src: "/assets/images/icon_check.svg",
      desc: "",
      btns: [
        {
          btnTitle: "View Results",
          btnType: "contained",
          btnfunc: () => {
            setOpen(false);
            setTimeout(() => {
              navigate("/home/convert/codeconversion/convertresults");
            }, 100);
          },
        },
      ],
    },
  ];

  const selectedTarget = useSelector(
    (state: RootState) => state.CodeConvert.selectedConvertTargetReducer
  );

  const selectedTargetId =
    selectedTarget.data.length === 0 ? "" : selectedTarget.data[0].id;

  useEffect(() => {
    dispatch(getSourceCodeList(selectedTargetId));
  }, [dispatch, selectedTargetId]);

  return (
    <div className="convertConversionContainer">
      <Button className="codeConfigBtn" variant="text" size="medium">
        <span className="codeConversionViewConfig">
          <img src="/assets/images/view-eye.svg" alt="view icon" />
          <span className="codeConversionCommonStyle codeConversionViewConfigText">
            View Configuration files
          </span>
        </span>
      </Button>
      <div className="sourceCodeList">
        <SourceCodeList
          selectedTargetId={selectedTargetId}
          checkedItems={checkedItems}
          setCheckedItems={setCheckedItems}
          setIsExecuteButtonDisabled={setIsExecuteButtonDisabled}
        />
      </div>
      <div className="uploadFileContainer disabledContainer">
        <span className="codeConversionCommonStyle">
          Upload license (optional)
        </span>{" "}
        <br />
        <span className="codeConversionCommonStyle codeConversionPurpleText">
          <input type="file" id="upload" hidden />
          <label className="convertCodeLabel" htmlFor="upload">
            <img
              className=""
              src="/assets/images/icon_upload.svg"
              alt="upload icon"
            />
            Upload Text file
          </label>
        </span>
      </div>
      <Popups
        fullData={modalData}
        btnOpen={open}
        btnClose={!open}
        modalClose={ModalCloseFunc}
      />
      <div className="codeConversionBtnContainer">
        <Button
          variant="contained"
          size="medium"
          onClick={() => {
            setOpen(true);
          }}
          disabled={isExecuteButtonDisabled}
        >
          Execute
          <img src="/assets/images/right-arrow.png" alt="right arrow" />
        </Button>
      </div>
    </div>
  );
}

export default ConvertConversion;
