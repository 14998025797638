import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { MigrationType } from "../../types/MigrationTypes";

export interface MigrationState {
  data: MigrationType | undefined;
  loading: boolean;
  error: string | null;
}

const initialState: MigrationState = {
  data: undefined,
  loading: false,
  error: null,
};

const MigrationSlice = createSlice({
  name: "migrationslice",
  initialState,
  reducers: {
    postMigrationLoading: (state) => {
      state.loading = true;
    },
    postMigrationSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    postMigrationError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = undefined;
    },
    clearMigrationData: (state) => {
      state.data = undefined;
    },
  },
});

export const {
  postMigrationLoading,
  postMigrationSuccess,
  postMigrationError,
  clearMigrationData,
} = MigrationSlice.actions;

export default MigrationSlice.reducer;
