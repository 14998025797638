import {
  fetchDataSourceLoading,
  fetchDataSourceSuccess,
  fetchDataSourceError,
} from "../slices/DataSourceSlice";
import { AppThunkDispatch } from "../store/Store";
import AxiosInstance from "../../core/AxiosInterceptor";

export const getDatasource = () => async (dispatch: AppThunkDispatch) => {
  dispatch(fetchDataSourceLoading());
  try {
    const response = await AxiosInstance.get(
      "api/pulsesdm/Pulse_list_db/source_pulse"
    );
    const data = response?.data?.document;
    dispatch(fetchDataSourceSuccess(data));
  } catch (error: any) {
    dispatch(fetchDataSourceError(error.message));
  }
};
