import { Box, Button } from "@mui/material";
import { AppDataCards } from "../Shared/Components/AppDataCards";

import { Dispatch } from "@reduxjs/toolkit";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store/Store";
import { getDatasource } from "../redux/thunks/DataSourceThunk";
import { DataSourceDataType } from "../redux/slices/DataSourceSlice";
import {
  addDataSource,
  removeDataSource,
} from "../redux/slices/SelectedDataSourceSlice";
import AppLoader from "../Shared/Components/AppLoader";
import DataConfig from "./DataConfig";
import "../styles/DataSourcePage.css";

const DataSourcePage = (props: any) => {
  const [showDataSource, setShowDataSource] = useState<boolean>(true);
  const dispatch: Dispatch<any> = useDispatch();
  const {
    data: DataSourceList,
    loading,
    error,
  } = useSelector((state: RootState) => state.Pulse.DataSourceReducer);

  const selectedDataSourceList = useSelector(
    (state: RootState) => state.Common.SelectedDataSourceReducer
  );

  const [updatedDataSourceList, setUpdatedDataSourceList] = React.useState<
    DataSourceDataType[] | []
  >([]);

  useEffect(() => {
    dispatch(getDatasource());
    dispatch(removeDataSource());
  }, [dispatch]);

  useEffect(() => {
    const manipulatedList = DataSourceList?.map((item) => {
      return { ...item, isSelected: false };
    });
    setUpdatedDataSourceList(manipulatedList);
  }, [DataSourceList]);

  const updateSelectedDatasoruce = (ID: string) => {
    const selected = updatedDataSourceList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedDataSourceList(selected);
    dispatch(addDataSource(selected));
  };

  const handleContinueClick: () => void = () => {
    setShowDataSource(!showDataSource);
  };

  return (
    <>
      {showDataSource ? (
        <Box className="dataSourcePageDatabaseContainer">
          <Box className="dataSourcePageDataSource">
            {loading && <AppLoader showBackDrop={false} />}
            {updatedDataSourceList?.map((item: DataSourceDataType, index) => (
              <AppDataCards
                key={index}
                dataItem={item}
                handleClick={updateSelectedDatasoruce}
              />
            ))}
          </Box>

          <Box className="dataSourcePageContainer">
            <Button
              size="large"
              variant="contained"
              disabled={selectedDataSourceList.data.length === 0}
              onClick={handleContinueClick}
            >
              <span className="dataSourcePageBtnImage">Continue</span>
              <img src="/assets/images/right-arrow.png" alt="Add" />
            </Button>
          </Box>
        </Box>
      ) : (
        <DataConfig
          showDataSource={showDataSource}
          handleClick={handleContinueClick}
          pathName="/home/discover/datadiscovery/metadataexecution"
        />
      )}
    </>
  );
};

export default DataSourcePage;
