import Axios, { AxiosError, InternalAxiosRequestConfig } from "axios";
import { store } from "../redux/store/Store";


const REACT_APP_BASEURL = process.env.REACT_APP_BASEURL;

const AxiosInstance = Axios.create({
  baseURL: REACT_APP_BASEURL,
});

 AxiosInstance.interceptors.request.use(
  async function (config:InternalAxiosRequestConfig): Promise<InternalAxiosRequestConfig<any>> {
    const token = await store.getState().UserData?.userToken;
    if(token){
     Object.assign(config, {
      headers: {
        Authentication:
          token,
      },
    });
  }
    return config;
  },  
  (error: AxiosError) => {
    return Promise.reject(error);
  }
); 
AxiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    return Promise.reject(error);
  }
);
export default AxiosInstance; 


