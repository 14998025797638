import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface SelectedMigrationWaveState {
  data: string;
}
const initialState: SelectedMigrationWaveState = {
  data: "",
};

const SelectedMigrationWaveSlice = createSlice({
  name: "selectedmigrationwaveslice",
  initialState,
  reducers: {
    addMigrationWave: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
    },
    clearMigrationWave: (state) => {
      state.data = "";
    },
  },
});

export const { addMigrationWave, clearMigrationWave } =
  SelectedMigrationWaveSlice.actions;
export default SelectedMigrationWaveSlice.reducer;
