import { createSlice, PayloadAction } from "@reduxjs/toolkit";

export interface ComputeConfigurationState {
  data: any;
}

const initialState: ComputeConfigurationState = {
  data: [],
};

const ComputeConfiguration = createSlice({
  name: "migrationschemalistslice",
  initialState,
  reducers: {
    fetchComputeConfigurationSuccess: (state, action: PayloadAction<any[]>) => {
      state.data = action.payload;
    },
    removedComputeConfiguration: (state) => {
      state.data = [];
    },
  },
});

export const { fetchComputeConfigurationSuccess, removedComputeConfiguration } =
  ComputeConfiguration.actions;

export default ComputeConfiguration.reducer;
