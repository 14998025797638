import React, { useEffect, useState } from "react";
import Graph, { GraphData, GraphEvents } from "react-graph-vis";
export interface INetworkData {
  nodes: {
    id?: number | string;
    label: string;
    type?: string;
    shape?: string;
    group?: string;
  }[];
  edges: {
    from: number | string;
    to: number | string;
    id?: number | string;
  }[];
}
export interface INetworkProps {
  width?: number;
  height?: number;
  data: INetworkData;
}
export const NetworkGraph: React.FC<INetworkProps> = ({
  width,
  height,
  data: dataProp,
  ...rest
}) => {
  const options = {
    layout: {
      randomSeed: 1,
    },
    nodes: {
      borderWidth: 1,
      size: 20,
      font: { color: "#6c00c2" },
    },
    edges: {
      width: 1,
      font: { align: "top", strokeWidth: 3, strokeColor: "#ffffff" },
      color: {
        color: "#6c00c2",
        highlight: "#6c00c2",
        hover: "#6c00c2",
        inherit: "both",
        opacity: 100,
      },
      arrows: {
        to: {
          enabled: true,
        },
        from: {
          enabled: false,
        },
      },
      arrowStrikethrough: true,
      scaling: {
        label: true,
      },
    },
    groups: {
      schema_dependency: {
        color: "#F5C9FF",
        borderWidth: 0,
        shape: "circle",
        font: { color: "#000", weight: 600 },
        margin: { top: 15, right: 20, bottom: 15, left: 20 },
        widthConstraint: 100,
      },
    },
  };
  // This data can be passed into the network graph from the parent component for testing purpose
  // const dummyData = {
  //   nodes: [
  //     { id: 1, label: "Node 1", group: "organization" },
  //     { id: 2, label: "Node 2", group: "organization" },
  //     { id: 3, label: "Node 3", group: "organization" },
  //     { id: 4, label: "Node 4", group: "organization" },
  //     { id: 5, label: "Node 5", group: "organization" },
  //   ],
  //   edges: [
  //     { from: 1, to: 3 },
  //     { from: 1, to: 2 },
  //     { from: 2, to: 4 },
  //     { from: 2, to: 5 },
  //   ],
  // };

  const [data, setData] = useState<GraphData>(dataProp);
  useEffect(() => {
    setData(dataProp);
  }, [dataProp]);
  return (
    <div>
      {" "}
      <Graph
        graph={data as GraphData}
        options={options}
        style={{ width: width, height: height }}
        getNetwork={(network) => {
          setTimeout(() => network.fit(), 1500);
        }}
        {...rest}
      />{" "}
    </div>
  );
};
