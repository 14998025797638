import {
  fetchCloudListLoading,
  fetchCloudListSuccess,
  fetchCloudListError,
} from "../../slices/sdvslices/SdvCloudListSlice";
import { AppThunkDispatch } from "../../store/Store";
import AxiosInstance from "../../../core/AxiosInterceptor";

export const getCloudList =
  (systemType: string) => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchCloudListLoading());
    try {
      const response = await AxiosInstance.get(
        `api/sdv/sdv_list_db/cloud_platform/${systemType}`
      );
      const data = response?.data?.document;
      dispatch(fetchCloudListSuccess(data));
    } catch (error: any) {
      dispatch(fetchCloudListError(error.message));
    }
  };
