import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { DbAllDetailsType } from "../DbAllDetailsSlice";

export interface ConfigurationDetailsState {
  data: DbAllDetailsType[];
  loading: boolean;
  error: string | null;
}

const initialState: ConfigurationDetailsState = {
  data: [],
  loading: false,
  error: null,
};

const ConfigurationDetailsSlice = createSlice({
  name: "configurationdetailsslice",
  initialState,
  reducers: {
    fetchSdvConfigLoading: (state) => {
      state.loading = true;
    },
    fetchSdvConfigSuccess: (state, action: PayloadAction<any>) => {
      state.data = action.payload;
      state.loading = false;
      state.error = null;
    },
    fetchSdvConfigError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
      state.data = [];
    },
    deleteSdvConfigLoading: (state) => {
      state.loading = true;
    },
    deleteSdvConfigSuccess: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = null;
      state.data = state.data.filter(
        (item) => Object.keys(item)[0] !== action.payload
      );
    },
    deleteSdvConfigError: (state, action: PayloadAction<any>) => {
      state.loading = false;
      state.error = action.payload;
    },
    addSdvConfigLoading: (state) => {
      state.loading = true;
    },
    addSdvConfigSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    addSdvConfigError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    editSdvConfigLoading: (state) => {
      state.loading = true;
    },
    editSdvConfigSuccess: (state, action) => {
      state.loading = false;
      state.error = null;
    },
    editSdvConfigError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    clearConfigurationDetails: (state) => {
      state.data = [];
    },
  },
});

export const {
  fetchSdvConfigLoading,
  fetchSdvConfigSuccess,
  fetchSdvConfigError,
  deleteSdvConfigLoading,
  deleteSdvConfigSuccess,
  deleteSdvConfigError,
  addSdvConfigLoading,
  addSdvConfigSuccess,
  addSdvConfigError,
  editSdvConfigLoading,
  editSdvConfigSuccess,
  editSdvConfigError,
  clearConfigurationDetails,
} = ConfigurationDetailsSlice.actions;

export default ConfigurationDetailsSlice.reducer;
