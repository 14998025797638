//imports
import * as React from "react";
import { Box, DialogActions, Dialog, Button } from "@mui/material";
import { Link } from "react-router-dom";
import "../../styles/AppPopups.css";

// Styles and styled componentes

export default function Popups(props: {
  fullData?: any;
  index?: any;
  btnOpen: any;
  btnClose: any;
  modalClose: any;
}) {
  const linkElement = React.useRef<null | HTMLAnchorElement>(null);
  const link =
    "https://s3.console.aws.amazon.com/s3/buckets/data-migration-assets?region=us-east-1&prefix=discover/pulse-for-data/metadata-execution-reports/&showversions=false";
  const handleClose = () => {
    props.modalClose(false);
  };
  return (
    <Box>
      <Dialog onClose={handleClose} open={props.btnOpen}>
        {props.fullData.map((data: any, index: any) => {
          return (
            <Box className="dialogContainer" key={index + "a"}>
              {data.src && (
                <img
                  src={data.src}
                  className="AppPopupImageWidth"
                  alt="altImage"
                />
              )}
              {data.heading && (
                <div className="AppPopupsTitle">{props.index}</div>
              )}
              {data.title && (
                <div className="AppPopupsTitle AppPopupsTitleWidth">
                  {data.title}
                </div>
              )}

              {data.srcExe && (
                <img
                  src={data.srcExe}
                  className="AppPopupSrcExe"
                  alt="altImage"
                  onClick={() => props.modalClose(false)}
                />
              )}
              {data.titleExe && (
                <div className="AppPopupsTitleExe">{data.titleExe}</div>
              )}
              <div className="AppPopupsDesc">{data.desc}</div>
              {data.dataLink1 && (
                <Box>
                  <div className="AppPopupsDataLinkStyle">{data.dataLink1}</div>
                  <div className="AppPopupsDataLinkStyle">{data.dataLink2}</div>
                  <div className="AppPopupsDataLinkStyle">{data.datalink3}</div>
                </Box>
              )}
              <Box>
                {data.descExe && (
                  <div className="AppPopupsDescExe">{data.descExe}</div>
                )}

                <div className="AppPopupContainer">
                  {data.descData &&
                    data.descData.map((descDatas: any, index: number) => {
                      return <p key={index}>{descDatas}</p>;
                    })}
                </div>
              </Box>
              <DialogActions className="AppPopupsDialogActions">
                {data.btns.map((btn: any, index: any) => {
                  if (btn) {
                    return (
                      <div key={index}>
                        {btn["btnTitle"] === "View Metadata Extracts" ? (
                          <Button>
                            <a
                              ref={linkElement}
                              href={link}
                              target="_blank"
                              rel="noreferrer noopener"
                              className="AppPopupsAnchor"
                            >
                              <img src="/assets/images/view-eye.svg" alt="" />
                              {btn["btnTitle"]}
                            </a>
                          </Button>
                        ) : (
                          <Button
                            className="AppPopupsAnchor"
                            key={index + "b"}
                            variant={
                              btn["btnType"] === "contained"
                                ? "contained"
                                : "text"
                            }
                            size="large"
                            onClick={btn["btnfunc"]}
                            component={Link}
                            to={data.path && data.path}
                          >
                            <img src={btn["btnSvg"]} alt={btn["btnSvg"]} />
                            {btn["btnTitle"]}
                          </Button>
                        )}
                      </div>
                    );
                  } else return null;
                })}
              </DialogActions>
            </Box>
          );
        })}
      </Dialog>
    </Box>
  );
}
