import {
    fetchSdmDataSourceLoading,
    fetchSdmDataSourceSuccess,
    fetchSdmDataSourceError,
  } from "../slices/SdmDataSourceSlice";
  import { AppThunkDispatch } from "../store/Store";
  import AxiosInstance from "../../core/AxiosInterceptor";
  
  export const getSdmDatasource = (migration_type:String) => async (dispatch: AppThunkDispatch) => {
    dispatch(fetchSdmDataSourceLoading());

    try {
      const response = await AxiosInstance.get(
        "api/pulsesdm/Pulse_list_db/source_sdm?migration_type="+migration_type
      );
      const data = response?.data?.document;
      dispatch(fetchSdmDataSourceSuccess(data));
    } catch (error: any) {
      dispatch(fetchSdmDataSourceError(error.message));
    }
  };
  