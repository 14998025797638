import * as React from "react";
import { styled } from "@mui/material/styles";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import { StepIconProps } from "@mui/material/StepIcon";
import { Check } from "@mui/icons-material";


interface IAppStepper {
  activeStep: number;
  steps:Array<string>;
}


const Connector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: "12px",
   // left: "calc(-45% + 16px)",
   // right: "calc(55% + 16px)",
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#E5E5E5",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      borderColor: "#E5E5E5",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    borderColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderTopWidth: 3,
    borderRadius: 1,
  },
}));

const StepIconRoot = styled("div")<{
  ownerState: { active?: boolean; completed?: boolean; disabled?: boolean };
}>(({ theme, ownerState }) => ({
  color:
    theme.palette.mode === "dark"
      ? theme.palette.grey[600]
      : ownerState.active
      ? "white"
      : "#eaeaf0",
  display: "flex",
  height: 20,
  alignItems: "center",
  ...(ownerState.active && {
    color: "white",
  }),
  "& .StepIcon-completedIcon": {
    color: "black",
    zIndex: 1,
    textAlign: "center",
    fontWeight: "lighter",
  },
  "& .StepIcon-circle": {
    width: "2rem",
    height: "2rem",
    borderRadius: "240px",
    backgroundColor: ownerState.active ? "black" : "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: ownerState.disabled ? "2px solid red" : "2px solid black",
    color: ownerState.active ? "white" : "#CCCBCE",
  },
  "& .StepIcon-circle-disabled": {
    width: "2rem",
    height: "2rem",
    borderRadius: "240px",
    backgroundColor: ownerState.active ? "black" : "transparent",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    border: "2px solid #CCCBCE",
    color:"#CCCBCE",
  },
}));

function StepIcon(props: StepIconProps) {
  const { active, completed, className, icon } = props;
  return (
    <StepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <div className="StepIcon-circle">
          <Check className="StepIcon-completedIcon" fontSize="small" />
        </div>
      ) : active ? (
        <div className="StepIcon-circle">
          <span style={{ color: active ? "white" : "#CCCBCE", fontSize: "21px" , fontWeight: "600"}}>
            {icon}
          </span>
        </div>
      ) : (
        <div className="StepIcon-circle-disabled">
          <span style={{ color: "#CCCBCE", fontSize: "21px",  fontWeight: "600" }}>{icon}</span>
        </div>
      )}
    </StepIconRoot>
  );
}

/**
 * Implementation of App Stepper
 * @returns stepper of the app
 */
const AppStepper = ({ activeStep, steps }:IAppStepper) => {
   
  return (
    <>
      <Stepper       
        alternativeLabel
        activeStep={activeStep}
        connector={<Connector />}
        sx={{
          position: "relative",
          margin: "0 auto",
          padding: "40px 0px 40px",
          width:{xs:"100%",md: "60rem"}        
      
        }}
      >
        {steps.map((label:string) => (
          <Step key={label}>
            <StepLabel StepIconComponent={StepIcon}>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </>
  );
}
export default AppStepper;