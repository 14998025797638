import { Box, Typography } from "@mui/material";
import { AppDataCards } from "../../Shared/Components/AppDataCards";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store/Store";
import { useState, useEffect } from "react";
import { TargetCloudListType } from "../../redux/slices/sdvslices/TargetCloudListSlice";
import { getTargetSystemList } from "../../redux/thunks/sdv/TargetSystemListThunk";
import {
  TargetSystemListType,
  clearTargetSystemList,
} from "../../redux/slices/sdvslices/TargetSystemListSlice";
import {
  addTargetSystem,
  removeTargetSystem,
} from "../../redux/slices/sdvslices/SelectedTargetSystemSlice";
import AppLoader from "../../Shared/Components/AppLoader";
import { getTargetCloudList } from "../../redux/thunks/sdv/TargetCloudListThunk";
import { addTargetCloudPlatform } from "../../redux/slices/sdvslices/SelectedTargetCloudPlatformSlice";

import "../../styles/SdvTarget.css";

const SdvTarget = () => {
  const dispatch: any = useDispatch();
  const [updatedCloudList, setUpdatedCloudList] = useState<
    TargetCloudListType[] | []
  >([]);
  const [updatedTargetSystemList, setUpdatedTargetSystemList] = useState<
    TargetSystemListType[]
  >([]);
  const selectedSystemType = useSelector(
    (state: RootState) =>
      state.Sdv.SelectedSourceSystemTypeReducer.data[0]?.value
  );

  const { data: cloudTargetPlatformDataList, loading: cloudLoading } =
    useSelector((state: RootState) => state.Sdv.TargetCloudListReducer);

  const { data: targetSystemDataList, loading: targetSystemLoading } =
    useSelector((state: RootState) => state.Sdv.TargetSystemReducer);

  const selectedTargetCloudId = useSelector(
    (state: RootState) =>
      state.Sdv.SelectedTargetCloudPlatformReducer?.data[0]?.id
  );
  const selectedTargetSystemId = useSelector(
    (state: RootState) => state.Sdv.SelectedTargetSystemReducer?.data[0]?.id
  );

  useEffect(() => {
    if (selectedTargetCloudId) {
      const manipulatedList = cloudTargetPlatformDataList?.map((item) => {
        if (item.id === selectedTargetCloudId)
          return { ...item, isSelected: true };
        else {
          return { ...item, isSelected: false };
        }
      });
      setUpdatedCloudList(manipulatedList);
    } else {
      const manipulatedList = cloudTargetPlatformDataList?.map((item) => {
        return { ...item, isSelected: false };
      });
      setUpdatedCloudList(manipulatedList);
    }
  }, [cloudTargetPlatformDataList, selectedTargetCloudId]);
  useEffect(() => {
    if (selectedSystemType) dispatch(getTargetCloudList(selectedSystemType));
  }, [dispatch, selectedSystemType]);
  useEffect(() => {
    if (selectedTargetCloudId && selectedSystemType)
      dispatch(getTargetSystemList(selectedSystemType, selectedTargetCloudId));
    else dispatch(clearTargetSystemList());
  }, [dispatch, selectedTargetCloudId, selectedSystemType]);

  const updateSelectedCloud = (ID: string) => {
    const selected = updatedCloudList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedCloudList(selected);
    dispatch(removeTargetSystem());
    dispatch(addTargetCloudPlatform(selected));
  };
  useEffect(() => {
    if (selectedTargetSystemId) {
      const manipulatedList = targetSystemDataList?.map((item) => {
        if (item.id === selectedTargetSystemId)
          return { ...item, isSelected: true };
        else {
          return { ...item, isSelected: false };
        }
      });
      setUpdatedTargetSystemList(manipulatedList);
    } else {
      const manipulatedList = targetSystemDataList?.map((item) => {
        return { ...item, isSelected: false };
      });
      setUpdatedTargetSystemList(manipulatedList);
    }
  }, [targetSystemDataList, selectedTargetSystemId]);

  const updateSelectedTargetSystem = (ID: string) => {
    const selected = updatedTargetSystemList?.map((item) => {
      if (ID === item.id) {
        return { ...item, isSelected: !item.isSelected };
      }
      return { ...item, isSelected: false };
    });
    setUpdatedTargetSystemList(selected);
    dispatch(addTargetSystem(selected));
  };

  return (
    <div>
      <Box className="sdvTargetAppdataCards">
        {cloudLoading ? (
          <AppLoader showBackDrop={false} />
        ) : (
          updatedCloudList.map((data, index) => {
            return (
              <AppDataCards
                key={index}
                dataItem={data}
                icon="target"
                handleClick={updateSelectedCloud}
              />
            );
          })
        )}
      </Box>
      {updatedTargetSystemList.length !== 0 && (
        <Typography className="sdvTargetTextDesc" variant="subtitle2">
          Select Target system
        </Typography>
      )}

      <Box className="sdvTargetAppdataCards">
        {targetSystemLoading ? (
          <AppLoader showBackDrop={false} />
        ) : (
          updatedTargetSystemList.map((data, index) => {
            return (
              <AppDataCards
                key={index}
                dataItem={data}
                handleClick={updateSelectedTargetSystem}
              />
            );
          })
        )}
      </Box>
    </div>
  );
};
export default SdvTarget;
